/** @format */

import DocumentType from "./DocumentType";
import SAButton from "../dashboard/overall/SA_Button";
import styled from "styled-components";
import SAIcon from "../dashboard/overall/Icon";
import moment from "moment";

const Card = styled.div`
  font-family: Nunito;
  font-style: normal;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const User = styled.p`
  color: #000000;
  margin-top: 20px;
`;
const Information = styled.p`
  color: #000000;
`;
const AlertInformation = styled.p`
  color: #ff9075;
`;
const ButtonDiv = styled.div`
  width: 100%;
  text-align: center;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  gap: 10px;
  margin: 10px 0;
`;

const MainDiv = styled.div`
  border-radius: 20px;
  box-shadow: 0px 3.95785px 11.8736px -2.37471px #ccdcff;
  align-content: center;
  justify-content: center;
  padding: 10px 30px;
  display: flex;
  background: #ffffff;
  box-sizing: border-box;
  flex-grow: 1;
  max-width: 400px;

  @media (max-width: 1250px) {
    width: 100%;
    max-width: none;
  }
`;
function DocumentShowCard({ isAlert, replaceBtn, ...props }) {
  let documentInfo = props.documentInfo;
  return (
    <MainDiv>
      <Card>
        <div style={{ marginBottom: "10px" }}>
          <DocumentType
            title={true}
            hasName={true}
            isAlert={isAlert}
            doc_type_id={documentInfo.document_type_id}
            doc_type_name={documentInfo.document_type_name}
          />
          <User>
            <strong>Utilizador:</strong> {documentInfo.user_name}
          </User>
          <Information>
            <strong>Data de criação:</strong> {moment(documentInfo.created_at).format("YYYY-MM-DD")}
          </Information>
          <Information>
            <strong>Hora de publicação:</strong> {moment(documentInfo.created_at).format("HH:mm")}
          </Information>
          {isAlert === true && (
            <AlertInformation>
              Caso o documento não seja substituído, o mesmo será eliminado da plataforma após o
              tempo estabelecido nos termos e condições de inserção de dados.
            </AlertInformation>
          )}
        </div>

        <ButtonDiv>
          {replaceBtn}
          {/* btn */}
          <SAButton
            value="Descarregar Documento"
            size="fullwidth"
            textcolor="#FFFFFF"
            onclick={() => {
              window.open(documentInfo.file_url);
            }}
            endIcon={<SAIcon icon={"documents-medic"} size={30} color="white" />}
          />
          {/* <SAButton
              value='Partilhar Documento'
              size='fullwidth'
              textcolor='#FFFFFF'
              endIcon={<SAIcon icon={'share'} size={30} color='white' />}
            /> */}
        </ButtonDiv>
      </Card>
    </MainDiv>
  );
}

export default DocumentShowCard;
