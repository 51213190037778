import * as React from "react";
import { ReactComponent as EdFisica } from "../../../icons/lessons_icons/educacao_fisica.svg";
import { ReactComponent as Geografia } from "../../../icons/lessons_icons/geografia.svg";
import { ReactComponent as Portugues } from "../../../icons/lessons_icons/portugues.svg";
import { ReactComponent as Quimica } from "../../../icons/lessons_icons/fisico-quimica.svg";
import { ReactComponent as Ciencias } from "../../../icons/lessons_icons/ciencias.svg";
import { ReactComponent as Historia } from "../../../icons/lessons_icons/historia.svg";
import { ReactComponent as Matematica } from "../../../icons/lessons_icons/matematica.svg";
import { ReactComponent as Frances } from "../../../icons/lessons_icons/frances.svg";
import { ReactComponent as Ingles } from "../../../icons/lessons_icons/ingles.svg";

function SubjectIcons({ subjectId, iconWidth, iconOpacity }) {
  switch (subjectId) {
    case 1:
      return (
        <Ciencias
          width={iconWidth}
          style={{ paddingLeft: "10px" }}
          opacity={iconOpacity}
        />
      );

    case 2:
      return (
        <EdFisica
          width={iconWidth}
          style={{ paddingLeft: "10px" }}
          opacity={iconOpacity}
        />
      );

    case 3:
      return (
        <Frances
          width={iconWidth}
          style={{ fill: "#5F80F7", paddingLeft: "10px", paddingTop: "5px" }}
          opacity={iconOpacity}
        />
      );

    case 4:
      return (
        <Quimica
          width={iconWidth}
          style={{ fill: "#5F80F7", paddingLeft: "10px", paddingTop: "5px" }}
          opacity={iconOpacity}
        />
      );

    case 5:
      return (
        <Geografia
          width={iconWidth}
          style={{ fill: "#5F80F7", paddingLeft: "10px", paddingTop: "5px" }}
          opacity={iconOpacity}
        />
      );

    case 6:
      return (
        <Historia
          width={iconWidth}
          style={{ fill: "#5F80F7", paddingLeft: "10px", paddingTop: "5px" }}
          opacity={iconOpacity}
        />
      );

    case 7:
      return (
        <Ingles
          width={iconWidth}
          style={{ fill: "#5F80F7", paddingLeft: "10px" }}
          opacity={iconOpacity}
        />
      );

    case 8:
      return (
        <Matematica
          width={iconWidth}
          style={{ fill: "#5F80F7", paddingLeft: "10px" }}
          opacity={iconOpacity}
        />
      );

    case 9:
      return (
        <Portugues
          width={iconWidth}
          style={{ fill: "#5F80F7", paddingLeft: "10px" }}
          opacity={iconOpacity}
        />
      );

    default:
      return <></>;
  }
}

export default SubjectIcons;
