/** @format */

import { useEffect, useState } from "react";
import DocumentPopUp from "./DocumentPopUp";
import DocumentShowCard from "./DocumentShowCard";
import DocumentViewer from "./DocumentViewer";
import axiosConfig from "../../providers/axiosConfig";
import styled from "styled-components";
import SAButton from "../dashboard/overall/SA_Button";
import SABackButton from "../dashboard/overall/SA_BackButton";
import { useNavigate } from "react-router-dom";
import { Alert, Snackbar } from "@mui/material";

const MainDiv = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
  align-content: center;
  margin: 20px auto 0 auto;
  max-width: 1500px;

  @media (max-width: 1250px) {
    flex-direction: column;
  }
`;

function DocumentShow({ userData }) {
  const [docInfo, setDocInfo] = useState();
  const navigate = useNavigate();
  let docId = window.location.pathname.replace("/document_show/", "");
  const [axiosError, setAxiosError] = useState({});

  useEffect(() => {
    axiosConfig
      .get(`/me/document/${docId}`, {
        params: {
          studentId: localStorage.getItem("student"),
        },
      })
      .then((res) => {
        setDocInfo(res.data);
      });
  }, [docId, userData]);

  async function deleteDocument() {
    // Delete previous document
    await axiosConfig
      .delete(`/documents/${docInfo.id}`)
      .then((res) => {
        //Se tiver problemas no documento
        if (docInfo.document_reports.length !== []) {
          //Set 'dismiss' false to true in alert table
          axiosConfig
            .put(`/alerts/dismiss/document/${docId}`, {
              params: {
                document_info: docInfo.document_reports,
              },
            })
            .then((res) => {
              navigate("/dashboard");
            });
        }
        setAxiosError({ message: "Documento removido com sucesso!", severity: "success" });
        navigate("/documents");
      })
      .catch((err) => {
        setAxiosError({ message: err?.message, severity: "error" });
      });
  }

  return (
    <>
      <Snackbar
        open={axiosError.message}
        onClose={() => {
          setAxiosError({});
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert severity={axiosError.severity}>{axiosError.message}</Alert>
      </Snackbar>
      {docInfo && (
        <>
          <SABackButton />
          <MainDiv>
            {/* Info Card */}
            <DocumentShowCard
              documentInfo={docInfo}
              replaceBtn={
                userData.isParent && (
                  <SAButton
                    value="Remover Documento"
                    size="fullwidth"
                    textcolor="#FFFFFF"
                    style={["btn-alternative"]}
                    onclick={() => {
                      deleteDocument();
                    }}
                  />
                )
              }
            />

            {/* DOCUMENT VIEWER */}
            <DocumentViewer file_url={docInfo.file_url || ""} />
          </MainDiv>
          <div id="window" style={{ display: "none", width: "100%" }}>
            <DocumentPopUp file_url={docInfo.file_url} />
          </div>
        </>
      )}
    </>
  );
}

export default DocumentShow;
