import React, { useState } from "react";
import Calendar from "react-calendar";
import { ReactComponent as PrevCalendarArrow } from "../../img/calender_arrow_prev.svg";
import { ReactComponent as NextCalendarArrow } from "../../img/calender_arrow_next.svg";
import {
  CalendarContainer,
  CalendarHeader,
} from "../Schedules/styles/Calendar.styles";

function getMonthText(month) {
  var monthNames = [
    "janeiro",
    "fevereiro",
    "março",
    "abril",
    "maio",
    "junho",
    "julho",
    "agosto",
    "setembro",
    "outubro",
    "novembro",
    "dezembro",
  ];

  return monthNames[month];
}

function getWeekText(week) {
  var weekNames = [
    "Domingo",
    "Segunda-feira",
    "Terça-feira",
    "Quarta-feira",
    "Quinta-feira",
    "Sexta-feira",
    "Sábado",
  ];

  return weekNames[week];
}

function CalendarCard(props) {
  const [value, onChange] = useState(new Date());

  if (props.setDateFilter) {
    props.setDateFilter(value);
    return (
      <CalendarContainer>
        <CalendarHeader>
          <div>
            <span className="selectedDayMonth">
              {value.getDate()} {getMonthText(value.getMonth())}
            </span>
            <span className="selectedYear">{value.getFullYear()}</span>
            <br />
            <span className="selectedWeekDay">
              {getWeekText(value.getDay())}
            </span>
          </div>
        </CalendarHeader>

        <Calendar
          onChange={onChange}
          value={value}
          nextLabel={<NextCalendarArrow />}
          prevLabel={<PrevCalendarArrow />}
        />
      </CalendarContainer>
    );
  }
}
export default CalendarCard;
