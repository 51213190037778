import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axiosConfig from "../../providers/axiosConfig";
import SAPageTopBar from "../dashboard/overall/SA_Pagetopbar";
import CalendarCard from "./CalendarCard";
import EntracesHistoryCard from "./EntrancesHistoryCard";
import ClassesEntrancesCard from "./ClassesEntrancesCard";
import Tutorial from "../Tutorial/tutorial";
import { ReactComponent as Entry } from "../../img/registaded_entry.svg";
import ListComponent from "./ListComponent";
import { ReactComponent as Math } from "../../icons/lessons_icons/matematica.svg";
import { CircularProgress } from "@mui/material";

const MainDiv = styled.div`
  display: grid;
  height: 65vh;
  @media screen and (max-width: 800px) {
    height: unset;
  }

  @media screen and (min-width: 1000px) {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
`;

const EntrancesMainCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(37, 49, 151, 0.05);
  width: 100%;
  height: 25vh;
  margin-bottom: 30px;
  border-radius: 20px;
  overflow-y: scroll;
  overflow-x: none;
  padding-bottom: 10px;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #b0c2ea;
    border-radius: 34.3668px;
    transform: rotate(-90deg);
  }
  &::-webkit-scrollbar-track {
    border: 2.05654px solid #b0c2ea;
    border-radius: 34.3668px;
    transform: rotate(-90deg);
    margin-top: 15px;
  }
  @media screen and (min-width: 800px) {
    width: 90vw;
    height: 25vh;
  }
  @media screen and (min-width: 1000px) {
    height: 100%;
    width: 98%;
  }
`;

const CardTitle = styled.p`
  font-weight: 700;
  font-size: 25px;
  margin-left: 30px;
`;

const CalenderMainCard = styled.div`
  background: #ffffff;
  padding-bottom: 20px;
  box-shadow: 0px 4px 10px rgba(37, 49, 151, 0.05);
  width: 100%;

  border-radius: 20px;
  @media screen and (min-width: 800px) {
    width: 90vw;
    height: 45vh;
  }

  @media screen and (min-width: 1000px) {
    height: 100%;
    width: 40%;
  }
`;

function Entrances({ userData }) {
  const user = userData.user;
  const studentId = localStorage.getItem("student");
  const [entrancesData, setEntrancesData] = useState([]);
  const [dateFilter, setDateFilter] = useState();
  const [tutorial, setTutorial] = useState(false);

  sessionStorage.setItem("route", "entrances");

  useEffect(() => {
    let link = "/me/entrances";
    axiosConfig
      .get(link, {
        params: { studentId: studentId, date: dateFilter },
      })
      .then((response) => {
        setEntrancesData(response.data);
      });
  }, [user.id, userData, studentId, dateFilter]);

  let title = entrancesData.isParent ? "Entradas do educando" : "Entradas";


  // if (entrancesData && entrancesData.studentLessons) {
  return (
    <>
      {userData.user.created_at === userData.user.updated_at ? (
        <div>
          <Tutorial
            userData={userData}
            setTutorial={setTutorial}
            continuos={true}
          />
        </div>
      ) : (
        <div>
          <Tutorial
            userData={userData}
            setTutorial={setTutorial}
            continuos={false}
            oneTime={true}
          />
        </div>
      )}

      <SAPageTopBar type={"entrada"} backbutton={false} title={title} />
      <MainDiv id="entrance">
        <EntrancesMainCard>
          <CardTitle>Histórico de Entradas</CardTitle>
          {entrancesData && entrancesData.studentLessons ? (
            <>
              {tutorial && (
                <>
                  <ListComponent
                    endIcon={<Entry />}
                    style={["entrys"]}
                    hours={"09"}
                    minutes={"31"}
                    title={"Entrada Registada"}
                    subtitle={"Portão da Escola 3 "}
                  />
                  <ListComponent
                    endIcon={<Math />}
                    style={["classes"]}
                    textStyle={"entry"}
                    hours={"09"}
                    minutes={"30"}
                    title={"Aula de Matemática"}
                    subtitle={""}
                  />
                </>
              )}
              
              {!tutorial && (
                <EntracesHistoryCard
                  entrances={entrancesData.entrances}
                  studentLessons={entrancesData.studentLessons}
                  dateFilter={dateFilter}

                />
              )}
              {!tutorial && (
                <ClassesEntrancesCard
                  dateFilter={dateFilter}
                  studentLessons={entrancesData.studentLessons}
                  lessons={entrancesData.lessons}
                  subjects={entrancesData.subjects}
                />
              )}
            </>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress style={{ marginTop: "10%" }} size={80} />
            </div>
          )}
        </EntrancesMainCard>

        <CalenderMainCard>
          <CalendarCard setDateFilter={setDateFilter} />
        </CalenderMainCard>
      </MainDiv>
    </>
  );
}
// }
export default Entrances;
