import { useState } from "react";
import SAPageTopBar from "../dashboard/overall/SA_Pagetopbar";
import ManualLessonCalendar from "./componets/ManualLessonCalendar";
import { useMediaQuery } from "react-responsive";
import SchedulesModule from "./componets/SchedulesModule";
import MobileLessonCalendar from "./componets/MobileLessonCalendar";
import moment from "moment";
import {
  CardWrapper,
  Row,
  Column,
  PillButton,
  WeekPill,
} from "./styles/Schedules.styles";
import Tutorial from "../Tutorial/tutorial";

//Schedules Page

function Schedules({ userData, ...props }) {
  const [date, setDate] = useState(moment().locale('en').format("ddd"));
  const [tab, setTab] = useState(0); // 0 - horarios 1 - Agendamentos
  // eslint-disable-next-line
  const [tutorial, setTutorial] = useState(false);
  const [generalDate, setGeneralDate] = useState();

  sessionStorage.setItem("route", "schedules");
  const isMobile = useMediaQuery({
    query: "(max-width: 800px)",
  });

  return (
    <>
      <div style={{ marginTop: "25px" }}>
        {userData.user.created_at === userData.user.updated_at ? (
          <div>
            <Tutorial
              userData={userData}
              setTutorial={setTutorial}
              continuos={true}
            />
          </div>
        ) : (
          <div>
            <Tutorial
              userData={userData}
              setTutorial={setTutorial}
              continuos={false}
              oneTime={true}
            />
          </div>
        )}

        <SAPageTopBar type={"horario"} backbutton={false} title={"Horário"} />
        {isMobile ? (
          <div id="horario-agendamentos">
            <div
              style={{
                display: "flex",
                gap: "35px",
                justifyContent: "center",
                marginBottom: "30px",
              }}
            >
              <PillButton
                onClick={() => {
                  setTab(0);
                }}
                className={tab === 0 ? "bt-active" : ""}
              >
                Horário Escolar
              </PillButton>
              <PillButton
                onClick={() => {
                  setTab(1);
                }}
                className={tab === 1 ? "bt-active" : ""}
              >
                Agendamentos
              </PillButton>
            </div>
            <Column>
              {tab === 0 ? (
                <CardWrapper>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      gap: "30px",
                      marginTop: "20px",
                    }}
                  >
                    <WeekPill
                      onClick={() => {
                        setDate("Mon");
                      }}
                      className={date === "Mon" ? "bt-active" : ""}
                    >
                      Seg
                    </WeekPill>
                    <WeekPill
                      onClick={() => {
                        setDate("Tue");
                      }}
                      className={date === "Tue" ? "bt-active" : ""}
                    >
                      Ter
                    </WeekPill>
                    <WeekPill
                      onClick={() => {
                        setDate("Wed");
                      }}
                      className={date === "Wed" ? "bt-active" : ""}
                    >
                      Qua
                    </WeekPill>
                    <WeekPill
                      onClick={() => {
                        setDate("Thu");
                      }}
                      className={date === "Thu" ? "bt-active" : ""}
                    >
                      Qui
                    </WeekPill>
                    <WeekPill
                      onClick={() => {
                        setDate("Fri");
                      }}
                      className={date === "Fri" ? "bt-active" : ""}
                    >
                      Sex
                    </WeekPill>
                  </div>
                  <MobileLessonCalendar date={date} userData={userData} />
                </CardWrapper>
              ) : (
                <SchedulesModule userData={userData} setGeneralDate={setGeneralDate} />
              )}
            </Column>
          </div>
        ) : (
          <Row id="horario-agendamentos">
            <ManualLessonCalendar
              userData={userData}
              generalDate={generalDate}
            />
            <SchedulesModule
              userData={userData}
              setGeneralDate={setGeneralDate}
            />
          </Row>
        )}
      </div>
    </>
  );
}

export default Schedules;
