import React from 'react';

function SchoolCard(props) {

    return (
        <div className='school-card'>
            <img className='image' src={props.image} alt=""/>
            <h3 className='title'>{props.title}</h3>
            <p className='description'>{props.description}</p>
        </div>
    );
}

export default SchoolCard;
