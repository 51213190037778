import React from "react";
import ListComponent from "./ListComponent";
import { ReactComponent as Science } from "../../icons/lessons_icons/ciencias.svg";
import { ReactComponent as Fisical } from "../../icons/lessons_icons/educacao_fisica.svg";
import { ReactComponent as Chemistry } from "../../icons/lessons_icons/fisico-quimica.svg";
import { ReactComponent as French } from "../../icons/lessons_icons/frances.svg";
import { ReactComponent as Geography } from "../../icons/lessons_icons/geografia.svg";
import { ReactComponent as History } from "../../icons/lessons_icons/historia.svg";
import { ReactComponent as English } from "../../icons/lessons_icons/ingles.svg";
import { ReactComponent as Math } from "../../icons/lessons_icons/matematica.svg";
import { ReactComponent as Portuguese } from "../../icons/lessons_icons/portugues.svg";

function LessonComponent(props) {
  const subjects = props.subjects;
  let horas;
  let minutos;
  let style;
  let textStyle;
  if (props.present === true) {
    horas = props.enteredAt.getUTCHours();
    minutos = props.enteredAt.getMinutes();
    style = "classes";
    textStyle = "entry";
  } else {
    horas = "Faltou";
    style = "classes";
    textStyle = "miss_classes";
  }
  return (
    <>
      {subjects.map((subject, key) => {
        if (subject.id === props.subject_id) {
          switch (subject.name) {
            case "Matemática":
              return (
                <ListComponent
                  endIcon={<Math />}
                  style={style}
                  textStyle={textStyle}
                  hours={horas}
                  minutes={minutos}
                  title={"Aula de Matemática"}
                  subtitle={""}
                />
              );
            case "Português":
              return (
                <ListComponent
                  endIcon={<Portuguese />}
                  style={style}
                  textStyle={textStyle}
                  hours={horas}
                  minutes={minutos}
                  title={"Aula de Português"}
                  subtitle={""}
                />
              );
            case "Francês":
              return (
                <ListComponent
                  endIcon={<French />}
                  style={style}
                  textStyle={textStyle}
                  hours={horas}
                  minutes={minutos}
                  title={"Aula de Francês"}
                  subtitle={""}
                />
              );
            case "Físico-química":
              return (
                <ListComponent
                  endIcon={<Chemistry />}
                  style={style}
                  textStyle={textStyle}
                  hours={horas}
                  minutes={minutos}
                  title={"Aula de Físico Química"}
                  subtitle={""}
                />
              );
            case "Educação Física":
              return (
                <ListComponent
                  endIcon={<Fisical />}
                  style={style}
                  textStyle={textStyle}
                  hours={horas}
                  minutes={minutos}
                  title={"Aula de Educação Física"}
                  subtitle={""}
                />
              );
            case "Ciências":
              return (
                <ListComponent
                  endIcon={<Science />}
                  style={style}
                  textStyle={textStyle}
                  hours={horas}
                  minutes={minutos}
                  title={"Aula de Ciências"}
                  subtitle={""}
                />
              );
            case "Geografia":
              return (
                <ListComponent
                  endIcon={<Geography />}
                  style={style}
                  textStyle={textStyle}
                  hours={horas}
                  minutes={minutos}
                  title={"Aula de Geografia"}
                  subtitle={""}
                />
              );
            case "Inglês":
              return (
                <ListComponent
                  endIcon={<English />}
                  style={style}
                  textStyle={textStyle}
                  hours={horas}
                  minutes={minutos}
                  title={"Aula de Inglês"}
                  subtitle={""}
                />
              );
            case "História":
              return (
                <ListComponent
                  endIcon={<History />}
                  style={style}
                  textStyle={textStyle}
                  hours={horas}
                  minutes={minutos}
                  title={"Aula de História"}
                  subtitle={""}
                />
              );
            default:
              return <></>;
          }
        }
        return null;
      })}
    </>
  );
}
export default LessonComponent;
