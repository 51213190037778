import React from "react";
import { useTranslation } from "react-i18next";
import circleCross from "../img/circleCross.svg";
import gps from "../img/gps.svg";
import phone from "../img/phone.svg";
import email from "../img/email.svg";

function Contacts() {
  const { t } = useTranslation();

  return (
    <section id="contacts">
      <div className="row section-header">
        <div
          className="column"
          style={{ maxWidth: "100%", flexGrow: 1, textAlign: "center" }}
        >
          <img src={circleCross} alt="" />
          <h2 className="row-title-center">{t("navbar6")}</h2>
        </div>
      </div>
      <div
        className="row"
        style={{ textAlign: "center", alignItems: "flex-start" }}
      >
        <div className="column flex">
          <a
            href="https://goo.gl/maps/7PExx9i4FY15Aukj6"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src={gps} alt="" />
          </a>
          <h3>{t("morada")}</h3>
          <a
            href="https://goo.gl/maps/7PExx9i4FY15Aukj6"
            target="_blank"
            rel="noreferrer noopener"
            style={{ textDecoration: "none", color: "#4566af" }}
          >
            Rua Dr. Fernão de Ornelas nº56, 4º AC 9050-021 Funchal, Portugal
          </a>
        </div>
        <div className="column flex">
          <a href="mailto:info@seeapp.pt">
            <img src={email} alt="" />
          </a>
          <h3>{t("email")}</h3>
          <a
            href="mailto:info@seeapp.pt"
            style={{ textDecoration: "none", color: "#4566af" }}
          >
            info@seeapp.pt
          </a>
        </div>
        <div className="column flex">
          <a href="tel:(+351)291103685">
            <img src={phone} alt="" />
          </a>
          <h3>{t("telefone")}</h3>
          <a
            href="tel:(+351)291103685"
            style={{ textDecoration: "none", color: "#4566af" }}
          >
            (+351) 291 103 685
          </a>
        </div>
      </div>
    </section>
  );
}

export default Contacts;
