/** @format */

import React from 'react';
import styled from 'styled-components';
import { ReactComponent as InquiryIcon } from '../../../icons/inquiry_card.svg';
import SAButton from './SA_Button';

const CardWrap = styled.div.attrs((props) => ({
    className: props.className,
}))`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 0.625rem;
    border-radius: 30px;
    box-shadow: 0px 4px 10px rgba(37, 49, 151, 0.05);
    background-color: #fff;
    width: calc((100% - 3.8rem) / 3);
    box-sizing: border-box;
    padding: 1.25rem;
    font-weight: 600;
    text-align: center;
    /* height: 400px; */
    & p {
        font-size: 1rem;
        font-weight: 500;
    }

    @media (max-width: 1250px) {
        width: 100%;
        height: auto;
    }
`;
const ImageWrap = styled.div`
    width: 80px;
    height: 80px;

    & svg {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
`;
const Row = styled.div`
    display: flex;
    align-items: flex-start;
    width: 100%;
    gap: 2.5rem;
    & div {
        text-align: left;
    }
`;
const SpacedRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    & .info {
        color: #b0c2ea;
        font-weight: 400;
    }
`;
function SA_InquiryCard({ onclick, header, text, info }) {
    return (
        <CardWrap>
            <Row>
                <ImageWrap>
                    <InquiryIcon style={{ width: '60px' }} />
                </ImageWrap>
                <div>
                    <h3>{header}</h3>
                    <p>{text}</p>
                </div>
            </Row>
            <SpacedRow>
                <span className='info'>{info}</span>
                <SAButton
                    size={'adaptive'}
                    style={['btn-alternative']}
                    onclick={onclick}
                    value={'Responder Inquérito'}
                    textcolor={'#fff'}
                />
            </SpacedRow>
        </CardWrap>
    );
}

export default SA_InquiryCard;
