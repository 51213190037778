/** @format */

import React, { useState } from 'react';
import styled from 'styled-components';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import moment from 'moment';

const Column = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    color: #4566af;
    font-weight: 500;
    flex: 1;
`;
const InputWrap = styled.div`
    display: flex;
    gap: 0.625rem;
    max-height: 38px;
    min-height: 36px;
    min-width: 64px;
    font-size: 1.125rem;
    color: #fff;
    box-sizing: border-box;
    padding: 0.5rem .325rem;
    border: 1px solid #4566af80;
    border-radius: 8px;
    width: 100%;
    & input {
        outline: 0;
        border: 0;
        color: #b0c2ea;
        font-size: 1rem;
        width: 100%;

        &::placeholder {
            color: #b0c2ea;
        }
    }
`;
const Toggle = styled.div`
    color: #4566af80;
    display: flex;
    align-items: center;
    cursor: pointer;
`;

function SA_Input({
    value,
    placeholder,
    defaultvalue,
    visibilityIcon,
    type,
    label,
    id,
    onchange,
    onsubmit,
    required,
}) {
    const [visible, SetVisible] = useState(false);
    function ToggleVisible() {
        SetVisible(!visible);
    }

    return (
        <Column>
            <label>{label}</label>
            <InputWrap
                style={
                    visibilityIcon
                        ? { justifyContent: 'space-between', alignItems: 'center' }
                        : { justifyContent: 'unset', alignItems: 'unset' }
                }
            >
                <input
                    max={type === 'date' ? moment().endOf('year').format('YYYY-MM-DD') : null}
                    min={
                        type === 'date'
                            ? moment().subtract(100, 'years').startOf('year').format('YYYY-MM-DD')
                            : null
                    }
                    value={value}
                    placeholder={placeholder}
                    id={id}
                    defaultValue={defaultvalue}
                    type={visible ? 'text' : type}
                    onChange={onchange}
                    onSubmit={onsubmit}
                    required={required}
                />
                <Toggle
                    style={visibilityIcon ? { display: 'flex' } : { display: 'none' }}
                    onClick={ToggleVisible}
                >
                    {visible ? <VisibilityIcon /> : <VisibilityOutlinedIcon />}
                </Toggle>
            </InputWrap>
        </Column>
    );
}

export default SA_Input;
