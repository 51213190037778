import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";

const MainDiv = styled.div`
  background: #fff;
  box-shadow: 0px 4px 10px rgba(37, 49, 151, 0.05);
  border-radius: 20px;
  margin-top: 20px;
  margin-bottom:10px;
  padding-top: 2px;
  padding-bottom: 15px;
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 30px;
  color: #4566af;
`;

const TextArea = styled.textarea`
  width: 83%;
  height:30vh;
  margin-left: 30px;
  border: 2.17976px solid #b0c2ea;
  border-radius: 20px;
  padding-left: 10px;
  padding-top: 10px;
  font-family: "Nunito";
  font-weight: 500;
  font-size: 15px;
  resize: none;
  color: rgba(69, 102, 175, 0.5);
  &::placeholder {
    color: rgba(69, 102, 175, 0.5);
  }
  &:focus-visible {
    outline: none;
  }
`;

function InquiryQuestionCheckBox(props) {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 899px)" });
  useEffect(() => {
    if (isTabletOrMobile) {
    }
  }, [isTabletOrMobile]);

  let label = props.choices[0]
    ? props.choices[0].choice
    : "Escreve aqui as tuas ideias";

  return (
    <MainDiv>
      <Title>
        {props.number + 1}-{props.title}
      </Title>
      <TextArea  id={props.id} name={props.title} placeholder={label}></TextArea>
      <br />
    </MainDiv>
  );
}
export default InquiryQuestionCheckBox;
