/** @format */

import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import logoCor from "../../img/logoColor.svg";
import burgerColor from "../../img/burgerColor.svg";
import cross from "../../img/cross.svg";
import navbarIcon from "../../img/navbar-icon.svg";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import UserMenu from "./UserMenu";
import axiosConfig from "../../providers/axiosConfig";
import SAIcon from "./overall/Icon";
import moment from "moment";
import { Menu } from "@mui/material";
import AlertCovidPopUp from "../Alerts/AlertCovidPopUp";

const NavBar = styled.nav`
  display: flex;
  justify-content: space-between;
`;

const NotificationCenter = styled.div`
  & .button {
    position: relative;
    user-select: none;

    & span {
      position: absolute;
      background-color: #e15a48;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      top: -5px;
      right: -5px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 14px;
    }
  }

  & .container {
    background-color: #fff;
    border-radius: 15px;
    position: absolute;
    top: 50px;
    width: 300px;
    gap: 20px;
    z-index: 10;
  }
`;

const NotificationRow = styled.div`
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #000;
  border: 2px solid;
  gap: 10px;
  padding: 10px;
  margin: 5px;
  cursor: pointer;

  & .title,
  & .description {
    display: block;
    color: #000;
  }

  & .title {
    font-size: 18px;
    font-weight: 600;
  }
`;

function DashboardNavBar({ userData }) {
  // const initialLang = window.localStorage.getItem("i18nextLng");
  // const { i18n } = useTranslation();
  // const [activeLang, setActiveLang] = useState(
  //   initialLang === "en" || initialLang === "en-US" ? "en" : "pt"
  // );
  const [menuOpen, setMenuOpen] = useState(false);
  const [notifications, setNotifications] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(null);
  const [showCovidAlert, setShowCovidAlert] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1250px)" });

  const navigate = useNavigate();

  function toggleMenu() {
    if (isTabletOrMobile) setMenuOpen(!menuOpen);
  }

  // const changeLanguage = (lng) => {
  //   setActiveLang(lng);
  //   i18n.changeLanguage(lng);
  //   toggleMenu();
  // };

  const navItemClick = (e, target) => {
    if (window.location.pathname !== "/event") {
      e.preventDefault();

      navigate(target);
      toggleMenu();
    }
  };
  function AccountLogout(e) {
    e.preventDefault();
    axiosConfig.post("logout").then((res) => {
      localStorage.clear("auth");
      window.location.reload();
    });
  }
  useEffect(() => {
    if (menuOpen) {
      document.getElementById("root").setAttribute("style", "overflow: hidden");
    } else {
      document.getElementById("root").setAttribute("style", "");
    }
  }, [menuOpen]);

  useEffect(() => {
    axiosConfig.get("user_notifications").then(({ data }) => {// eslint-disable-next-line
      let temp = data.notifications.filter(notify => notify.notification_type_id != 7);
      //console.log('data.notifications', temp)
      setNotifications(temp);
    });
    // eslint-disable-next-line
  }, [window.location.href]);

  return (
    <header className={"scroll"}>
      <NavBar>
        <a href="/dashboard">
          <img style={{ width: "100px" }} src={logoCor} alt="" />
        </a>

        <div className={menuOpen ? "open dashboard-link-container" : "dashboard-link-container"}>
          <a onClick={(e) => navItemClick(e, "/dashboard")} href="/#">
            Home
            <img
              className="icon"
              src={navbarIcon}
              style={{ opacity: window.location.pathname === "/dashboard" && 1 }}
              alt=""
            />
          </a>
          <a onClick={(e) => navItemClick(e, "/alerts")} href="/#">
            Alertas
            <img
              className="icon"
              src={navbarIcon}
              style={{ opacity: window.location.pathname === "/alerts" && 1 }}
              alt=""
            />
          </a>
          <a onClick={(e) => navItemClick(e, "/schedule")} href="/#">
            Horários
            <img
              className="icon"
              src={navbarIcon}
              style={{ opacity: window.location.pathname === "/schedule" && 1 }}
              alt=""
            />
          </a>
          <a onClick={(e) => navItemClick(e, "/entrances")} href="/entrances">
            Entradas
            <img
              className="icon"
              src={navbarIcon}
              style={{ opacity: window.location.pathname === "/entrances" && 1 }}
              alt=""
            />
          </a>
          <a onClick={(e) => navItemClick(e, "/documents")} href="/#">
            Documentos
            <img
              className="icon"
              src={navbarIcon}
              style={{ opacity: window.location.pathname === "/documents" && 1 }}
              alt=""
            />
          </a>
          {/* <a onClick={(e) => navItemClick(e, '/chat')} href='/#'>
                        Chat
                        <img
                            className='icon'
                            src={navbarIcon}
                            style={ { opacity: window.location.pathname === '/chat' && 1 }}
                            alt=''
                        />
                    </a> */}
          <a onClick={(e) => navItemClick(e, "/inquiries")} href="/#">
            Inquéritos
            <img
              className="icon"
              src={navbarIcon}
              style={{ opacity: window.location.pathname === "/inquiries" && 1 }}
              alt=""
            />
          </a>

          <a onClick={(e) => navItemClick(e, "/news")} href="/#">
            Notícias
            <img
              className="icon"
              src={navbarIcon}
              style={{ opacity: window.location.pathname === "/news" && 1 }}
              alt=""
            />
          </a>

          {isTabletOrMobile && (
            <>
              <a href="/my_account" alt="" className="menuitem">
                Definições de conta
              </a>
              <div onClick={AccountLogout} className="menuitem" style={{ cursor: "pointer" }}>
                Terminar Sessão
              </div>
            </>
          )}
        </div>
        <NotificationCenter>
          <div
            className="button"
            style={notifications.length > 0 ? { cursor: "pointer" } : { cursor: "default" }}
            onClick={(e) => {
              if (notifications.length > 0) setOpenNotifications(e.currentTarget);
            }}
          >
            <SAIcon icon="alerts" color={"#4566AF"} size={30} />
            {notifications?.length > 0 && (
              <span>{notifications.length < 10 ? notifications.length : "  "}</span>
            )}
          </div>
          <Menu
            className="container"
            anchorEl={openNotifications}
            id="account-menu"
            open={openNotifications !== null}
            onClose={() => setOpenNotifications(null)}
            onClick={() => setOpenNotifications(null)}
            PaperProps={{
              elevation: 0,
              sx: {
                overflowX: "auto",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                borderRadius: "15px",
                height: "400px",
                "&::-webkit-scrollbar": {
                  width: "5px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "rgb(255, 144, 117)",
                  borderRadius: "34.3668px",
                  transform: "rotate(-90deg)",
                },
                "&::-webkit-scrollbar-track": {
                  border: "2.05654px solid rgb(255, 144, 117)",
                  borderRadius: "34.3668px",
                  transform: "rotate(-90deg)",
                  margin: "10px 0",
                },
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {notifications &&
              notifications
                .sort((a, b) => {
                  if (moment(a.created_at).isBefore(moment(b.created_at))) {
                    return 1;
                  }
                  return -1;
                })
                .map((elem, index) => {
                  let icon, title, description, color, onClick;
                  switch (elem.notification_type_id) {
                    case 1:
                      icon = "covid";
                      title = "Teste Positivo";
                      description = <span>{elem.value}</span>;
                      color = "#FF4A4A";
                      onClick = () => {
                        axiosConfig.post("/notifications/read", {
                          notification: elem,
                        });
                        navigate(`alerts`);
                        if (userData.isParent && elem.referent_user_id)
                          localStorage.setItem("student", elem.referent_user_id);
                      };
                      break;
                    case 2:
                      icon = "fever";
                      title = "Temperatura Alta";
                      description = <span>{elem.value}</span>;
                      color = "#FF9075";
                      onClick = async () => {
                        axiosConfig.post("/notifications/read", {
                          notification: elem,
                        });
                        navigate(`alerts`);
                        if (userData.isParent && elem.referent_user_id)
                          localStorage.setItem("student", elem.referent_user_id);
                      };
                      break;
                    case 3:
                      icon = "injure";
                      title = "Acidente escolar";
                      description = <span>{elem.value}</span>;
                      color = "#FF9075";
                      onClick = async () => {
                        axiosConfig.post("/notifications/read", {
                          notification: elem,
                        });
                        navigate(`alerts`);
                        if (userData.isParent && elem.referent_user_id)
                          localStorage.setItem("student", elem.referent_user_id);
                      };
                      break;
                    case 4:
                      icon = "documents";
                      title = "Documento inválido";
                      description = <span>{elem.value}</span>;
                      color = "#FF9075";
                      onClick = async () => {
                        axiosConfig.post("/notifications/read", {
                          notification: elem,
                        });
                        navigate(`alerts`);
                        if (userData.isParent && elem.referent_user_id)
                          localStorage.setItem("student", elem.referent_user_id);
                      };
                      break;
                    case 5:
                      icon = "questionaire";
                      title = "Inquérito novo";
                      description = <span>{elem.value}</span>;
                      color = "#EDB84F";
                      onClick = async () => {
                        axiosConfig.post("/notifications/read", {
                          notification: elem,
                        });
                        navigate(`inquiries`);
                        if (userData.isParent && elem.referent_user_id)
                          localStorage.setItem("student", elem.referent_user_id);
                      };
                      break;
                    case 6:
                      icon = "schedule";
                      title = "Agendamento novo";
                      description = <span>{elem.value}</span>;
                      color = "#4566AF";
                      onClick = async () => {
                        axiosConfig.post("/notifications/read", {
                          notification: elem,
                        });
                        navigate(`schedule`);
                        if (userData.isParent && elem.referent_user_id)
                          localStorage.setItem("student", elem.referent_user_id);
                      };
                      break;
                    default:
                      return null;
                  }

                  return (
                    <NotificationRow
                      key={index}
                      style={{ borderColor: color }}
                      onClick={() => {
                        notifications.splice(notifications.indexOf(elem), 1);
                        onClick();
                      }}
                    >
                      <SAIcon icon={icon} color={color} size={50} />
                      <div>
                        <span className="title">{title}</span>
                        <span className="description">{description}</span>
                      </div>
                    </NotificationRow>
                  );
                })}
          </Menu>
        </NotificationCenter>
        <img
          className="mobile-menu-button dashboard-mobile-menu-button"
          src={menuOpen ? cross : burgerColor}
          onClick={toggleMenu}
          alt=""
        />
        {!isTabletOrMobile && <UserMenu userData={userData} />}
      </NavBar>
      {showCovidAlert && <AlertCovidPopUp hide={setShowCovidAlert} notification={showCovidAlert} />}
    </header>
  );
}

export default DashboardNavBar;
