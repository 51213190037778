/** @format */

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import SAIcon from "../overall/Icon";
import Moment from "react-moment";

const ItemSchedule = styled.div.attrs(({ theme }) => ({
  className: theme.className,
  color: theme.bg,
}))`
  margin-right: 15px;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  border: 2px solid ${({ theme }) => theme.bg};
  color: ${({ theme }) => theme.bg};
  padding: 1rem 1.25rem;
  border-radius: 100px;
  max-height: 78px;
  height: 78px;
  svg {
    width: 50px;
    height: 50px;
    margin-left: auto;
  }
  h4,
  p,
  span {
    margin: 0;
    color: ${({ theme }) => theme.bg};
  }
`;

const Divider = styled.div.attrs(({ theme }) => ({
  background: theme.bg,
}))`
  width: 3px;
  border-radius: 15px;
  height: 100%;
  background-color: ${({ theme }) => theme.bg};
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

function SAScheduleItem({ subject, professor, local, start_time, end_time }) {
  const [icon, SetIcon] = useState("");
  const [color, SetColor] = useState("");
  const theme = {
    bg: color,
  };

  function GetIcon(subject) {
    switch (subject.name) {
      default:
    }

    switch (subject) {
      case "Ciências da Natureza":
      case "Ciências Naturais":
      case "MS.CN":
      case "CNA":
        SetIcon("sciences");
        break;
      case "Educação Física":
      case "EDF":
        SetIcon("sports");
        break;
      case "Francês":
        SetIcon("languages");
        break;
      case "Físico-Química":
      case "FSQ":
        SetIcon("chemistry");
        break;
      case "Geografia":
        SetIcon("geography");
        break;
      case "História":
      case "História e Geografia de Portugal":
      case "HST8":
        SetIcon("sciences");
        break;
      case "Inglês":
      case "MS.Ing":
      case "MS.LdING":
        SetIcon("languages");
        break;
      case "Matemática":
      case "MAT.REF":
        SetIcon("maths");
        break;
      case "Português":
      case "MS.Por":
      case "Português Língua Não Materna":
      case "PT":
        SetIcon("languages");
        break;
      case "Almoço":
        SetIcon("meals");
        break;

      default:
        SetIcon("subjects");
        break;
    }
  }

  function GetStatus(start_time, end_time) {
    if (moment(moment()).isAfter(moment(end_time))) {
      SetColor("#B0C2EA80");
    } else if (
      moment(moment()).isSameOrAfter(moment(start_time)) &&
      moment(moment()).isSameOrBefore(moment(end_time))
    ) {
      SetColor("#0DC399");
    } else if (moment(moment()).isBefore(moment(start_time))) {
      SetColor("#4566AF");
    }
  }

  useEffect(() => {
    GetIcon(subject);
    GetStatus(start_time, end_time);
  }, [start_time, end_time, subject]);

  return (
    <ItemSchedule theme={theme}>
      <Column>
        <p>
          <Moment date={start_time} format={"HH:mm"} />
        </p>
        <p>
          <Moment date={end_time} format={"HH:mm"} />
        </p>
      </Column>
      <Divider theme={theme} />
      <div>
        <h4>{subject.length > 14 ? `${subject.substring(0, 14)}...` : subject}</h4>
        {professor && (
          <p>Prof. {professor.length > 14 ? `${professor.substring(0, 14)}...` : professor}</p>
        )}

        <span>{local}</span>
      </div>
      <SAIcon icon={icon} color="inherit" />
    </ItemSchedule>
  );
}

export default SAScheduleItem;
