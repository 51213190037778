/** @format */

import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Line } from 'react-chartjs-2';
import { Chart, Filler, registerables } from 'chart.js';
import { useEffect } from 'react';
import axiosConfig from '../../providers/axiosConfig';
import { useState } from 'react';
import AlertTypes from './AlertTypes';
import SABackButton from '../../components/dashboard/overall/SA_BackButton';
import Tutorial from '../Tutorial/tutorial';
import moment from 'moment';

Chart.register(Filler);
Chart.register(...registerables);

const MainDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;

  @media (max-width: 1250px) {
    & div {
      min-width: 0;
      width: 100%;
    }
  }
`;
const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  margin-top: 10px;
  border-radius: 20px;
`;
const ContentTextDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #ffffff;
  color: #ff9075;
  padding: 10px;
  margin-top: 10px;
  border-radius: 20px;
`;
const ChartWrapper = styled.div`
  max-width: 900px;
  max-height: 490px;
  min-width: 500px;
  background: #ffffff;
  border-radius: 20px;
  margin-left: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 400px; */
  &.chart {
    width: 100% !important;
    height: 100% !important;
  }

  @media (max-width: 1250px) {
    min-width: 0;
    width: 100%;
  }
`;
const Logo = styled.span`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 5px;
  color: #ff9075;
`;

function AlertHighTemp(props) {
  const location = useLocation();
  const data = location.state.info;
  const studentId = localStorage.getItem('student');
  const userInfo = props.userData;
  const [userTemperature, setUserTemperature] = useState([]);
  const [tempraturesLabels, setTempraturesLabels] = useState([]);
  const [tutorial, setTutorial] = useState(true);
  sessionStorage.setItem('route', 'alertHighTempPage');

  useEffect(() => {
    let arrKeys = [];
    let arrValues = [];

    axiosConfig
      .get(`/users/avgTemps/${data.user_id}/${data.created_at}`, {
        params: {
          studentId: studentId,
        },
      })
      .then((res) => {
        Object.keys(res.data).forEach((key) => {
          Object.values(Object.keys(res.data[key])).forEach((value) => {
            arrKeys.push(moment(value).format('D MMM'));
          });
          Object.values(res.data[key]).forEach((value) => {
            arrValues.push(value);
          });
        });
        let count = 0;
        while (arrValues.indexOf(0) || arrValues.indexOf(null)) {
          let pos = arrValues.indexOf(null);
          if (pos === -1) {
            pos = arrValues.indexOf(0);
          }
          if (pos === -1) {
            break;
          }
          if (pos === 0) {
            arrValues[pos] = arrValues[pos + 1];
          } else if (pos === arrValues.length - 1) {
            arrValues[pos] = arrValues[pos - 1];
          } else {
            arrValues[pos] = arrValues[pos - 1];
          }
          count++;
          if (count > 10) {
            break;
          }
        }

        setUserTemperature(arrValues);
        setTempraturesLabels(arrKeys);
      });
  }, [data, userInfo, studentId]);

  return (
    <div>
      {props.userData.user.created_at === props.userData.user.updated_at && (
        <div>
          <Tutorial setTutorial={setTutorial} continuos={true} userData={userInfo} />
        </div>
      )}
      <div>
        <Tutorial setTutorial={setTutorial} userData={userInfo} continuos={false} oneTime={true} />
      </div>
      <div style={{}}>
        <SABackButton path={'/alerts'} />
      </div>
      <MainDiv>
        {tutorial && (
          <div id='left-panel-high-temperature'>
            <ContentDiv>
              <Logo style={data.temperature > 37.5 ? { color: '#FF4A4A' } : { color: '#FF9075' }}>
                <AlertTypes color={data.temperature > 37.5 ? '#FF4A4A' : '#FF9075'} data={2} />
                {data.temperature}ºC
              </Logo>
              {/* <AlertHighTemp /> */}
              <div>{/* {data.} */}</div>
              <p>Temperatura Alta Registada</p>
              <p>Dia: {moment(data.created_at).format('YYYY-MM-DD')}</p>
              <p>Hora: {moment(data.created_at).format('HH:mm')}</p>
            </ContentDiv>
            <ContentTextDiv>
              Deverá ficar isolado até ser confirmado esta temperatura!
            </ContentTextDiv>
          </div>
        )}
        {!tutorial && (
          <div>
            <ContentDiv>
              <Logo style={data.temperature > 37.5 ? { color: '#FF4A4A' } : { color: '#FF9075' }}>
                <AlertTypes color={data.temperature > 37.5 ? '#FF4A4A' : '#FF9075'} data={2} />
                {data.temperature}ºC
              </Logo>
              {/* <AlertHighTemp /> */}
              <div>{/* {data.} */}</div>
              <p>Temperatura Alta Registada</p>
              <p>Dia: {moment(data.created_at).format('YYYY-MM-DD')}</p>
              <p>Hora: {moment(data.created_at).format('HH:mm')}</p>
            </ContentDiv>
            <ContentTextDiv>
              Deverá ficar isolado até ser confirmado esta temperatura!
            </ContentTextDiv>
          </div>
        )}

        {/* Graph */}
         <ChartWrapper>
          {userTemperature && (
            !userTemperature.every(el => el === null) ? <Line
              className='chart'
              options={{
                scales: {
                  y: {
                    grid: {
                      display: false,
                    },
                    ticks: {
                      setpSize: 1,
                    },
                  },
                  x: {
                    grid: {
                      display: false,
                    },
                  },
                },
                plugins: {
                  filler: {
                    propagate: true,
                  },
                  legend: {
                    display: false,
                  },
                },
              }}
              data={{
                labels: tempraturesLabels,
                datasets: [
                  {
                    fill: true,
                    data: userTemperature,
                    backgroundColor: (context: ScriptableContext<'line'>) => {
                      const ctx = context.chart.ctx;
                      const gradient = ctx.createLinearGradient(0, 0, 0, 400);
                      gradient.addColorStop(0, 'rgba(255,0,0,0.7)');
                      gradient.addColorStop(1, 'rgba(255,255,255,0)');
                      return gradient;
                    },
                    borderColor: '#EC673D',
                    tension: 0.4, //0.2 maybe?
                  },
                ],
              }}
            /> : <span>N/A</span>
          )}
        </ChartWrapper>
      </MainDiv>
    </div>
  );
}
export default AlertHighTemp;
