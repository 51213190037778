/** @format */

import React, { useState } from "react";
import axiosConfig from "../../providers/axiosConfig";
import { Alert, Card, Snackbar } from "@mui/material";
import styled from "styled-components";
import SAInput from "./overall/SA_Input";
import SAButton from "./overall/SA_Button";

const PasswordForm = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  gap: 1.25rem;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: column;
  margin-bottom: 1rem;
`;

function ChangePassword({ userData }) {
  const [axiosError, setAxiosError] = useState({});

  async function updatePassword(event) {
    event.preventDefault();
    let current_password = document.getElementById("current_password").value;
    let new_password = document.getElementById("new_password").value;
    let new_password_confirmation = document.getElementById("new_password_confirmation").value;

    if (new_password !== new_password_confirmation) {
      setAxiosError({ message: "As palavras passes não coincidem", severity: "success" });
      return;
    } else {
      await axiosConfig
        .post("/change_password", {
          current_password: current_password,
          new_password: new_password,
          new_password_confirmation: new_password_confirmation,
        })
        .then((response) => {
          setAxiosError({ message: "Palavra Passe Atualizada com sucesso.", severity: "success" });
          sessionStorage.setItem("route", "finalStep");
          window.location.replace("/");
        })
        .catch((error) => {
          setAxiosError({ message: error.message, severity: "error" });
        });
    }
  }

  return (
    <>
      <Snackbar
        open={axiosError.message}
        onClose={() => {
          setAxiosError({});
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert severity={axiosError.severity}>{axiosError.message}</Alert>
      </Snackbar>
      <Card>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            boxSizing: "border-box",
            margin: "1rem",
          }}
        >
          <Alert
            // color="warning"
            icon={false}
            variant={"standard"}
            severity={"error"}
          >
            <span>A nova palavra-passe deve conter a seguinte informação:</span>
            <ul>
              <li>Ter pelo menos 1 letra maiúscula;</li>
              <li>Ter pelo menos 1 letra minúscula;</li>
              <li>Ter pelo menos 1 caractér especial (@$!%*?&);</li>
              <li>Ter pelo menos 1 número;</li>
              <li>Ter pelo menos 10 caractéres;</li>
            </ul>
          </Alert>
        </div>

        <form onSubmit={updatePassword}>
          <PasswordForm>
            <SAInput
              type="password"
              id="current_password"
              label={"Palavra-Passe Antiga"}
              visibilityIcon={true}
            />
            <SAInput
              type="password"
              id="new_password"
              label={"Nova Palavra-Passe"}
              visibilityIcon={true}
            />
            <SAInput
              type="password"
              id="new_password_confirmation"
              label={"Repetir Nova Palavra-Passe"}
              visibilityIcon={true}
            />
            <SAButton
              size={"adaptive"}
              align={"right"}
              value={"Alterar Palavra-Passe"}
              textcolor="#fff"
              type={"submit"}
            />
          </PasswordForm>
        </form>
      </Card>
    </>
  );
}

export default ChangePassword;
