import SAButton from "../dashboard/overall/SA_Button";
import SABackButton from "../dashboard/overall/SA_BackButton";
import { useState } from "react";
import axiosConfig from "../../providers/axiosConfig";
import styled from "styled-components";
import SAIcon from "../dashboard/overall/Icon";
import PopUp from "./PopUp";
import DocumentType from "./DocumentType";
import Tutorial from "../Tutorial/tutorial";

const HeaderDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  max-height: 30px;
  height: 30px;
  gap: 40px;
  @media (max-width: 1250px) {
    flex-direction: column;
  }
`;
const HeaderTitle = styled.p`
  color: #4566af;
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
`;
const ContentDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 40px;

  @media (max-width: 1250px) {
    flex-direction: column;
  }
`;
const DocumentTypesDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const DocumentTypesTitle = styled.p`
  color: #5f80f7;
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  margin-bottom: 0;
`;
const DocumentTypesPair = styled.div`
  display: flex;
  align-items: center;
  gap: 20px; //GAP BETWEEN TYPES (HORIZONTAL)
`;
const DocumentTypeDiv = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  width: 200px;
  height: 200px;
  max-width: 200px;
  height: 125px;
  max-height: 125px;
  background: #ffffff;
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 0px 4px 10px rgba(37, 49, 151, 0.05);
  @media (max-width: 1250px) {
    text-align: center;
  }
`;
const FileInputDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  width: 560px;
`;
const FileInputTitle = styled.p`
  color: #5f80f7;
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
`;
const FormUpperDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;
const FormInputDiv = styled.div`
  background: white;
  border-radius: 20px;
  height: 200px;
  width: 500px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  display: flex;
  box-shadow: 0px 4px 10px rgba(37, 49, 151, 0.05);
  @media (max-width: 1250px) {
    width: 100%;
  }
`;
const FormBtntDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  @media (max-width: 1250px) {
    justify-content: center;
  }
`;

function DocumentCreate(props) {
  const [optionTypeDoc, setOptionTypeDoc] = useState("");
  const [openPopUp, setOpenPop] = useState(false);
  const [infoToApi, setInfoToApi] = useState({});
  const [tutorial, setTutorial] = useState(false);
  sessionStorage.setItem("route", "documentsForm");

  async function HandleForm(event) {
    event.preventDefault();
    let url = window.location.pathname;
    let userId = url.replace("/document_create/", "");

    let formData = new FormData();
    formData.append("file", document.getElementById("myfile").files[0]);
    formData.append(
      "file_name",
      document.getElementById("myfile").files[0].name
    );
    formData.append("user_id", parseInt(userId));
    formData.append("document_type_id", infoToApi.doc_type_id);

    await axiosConfig.post("/me/documents", formData).then((response) => {
      setOpenPop(true);
    });
  }

  return (
    <>
      <SABackButton />
      {/* Header */}
      {props.userData.user.created_at === props.userData.user.updated_at ? (
        <div>
          <Tutorial userData={props.userData} setTutorial={setTutorial} continuos={true} />
        </div>
      ) : (
        <div>
          <Tutorial userData={props.userData} setTutorial={setTutorial} continuos={true} oneTime={true} />
        </div>
      )}

      <div>
        {openPopUp === true && tutorial === false && (
          <PopUp
            logo={
              <DocumentType
                doc_type_id={infoToApi.doc_type_id}
                hasName={false}
                logoSize={"100px"}
                isAlert={false}
              />
            }
            titleColor={"#4566AF"}
            title={`O seu documento foi submetido.`}
            path={"/documents"}
            backgroundColor={"rgba(0, 0, 0, 0.3)"}
            setState={setOpenPop}
          />
        )}
        <ContentDiv>
          {/* DOCUMENT TYPES */}
          <DocumentTypesDiv id="document-types-form">
            <HeaderDiv>
              <HeaderTitle>Adicionar Documento</HeaderTitle>
            </HeaderDiv>
            <DocumentTypesTitle>
              1. Selecione o tipo de documento
            </DocumentTypesTitle>

            {/* PAIR OF DOC TYPES */}
            <DocumentTypesPair>
              <DocumentTypeDiv
                onClick={() => {
                  setOptionTypeDoc("opt1");
                  setInfoToApi({
                    ...infoToApi,
                    doc_type_id: 1,
                  });
                }}
              >
                <SAIcon
                  size="40px"
                  icon="vaccine"
                  color={
                    optionTypeDoc === "opt1" ? "blue" : "rgba(194, 195, 199, 1)"
                  }
                />

                <span
                  style={{
                    paddingBottom: "10px",
                    paddingTop: "10px",
                    color:
                      optionTypeDoc === "opt1"
                        ? "blue"
                        : "rgba(194, 195, 199, 1)",
                  }}
                >
                  Certificado de Vacinação
                </span>
              </DocumentTypeDiv>
              <DocumentTypeDiv
                onClick={() => {
                  setOptionTypeDoc("opt2");
                  setInfoToApi({
                    ...infoToApi,
                    doc_type_id: 2,
                  });
                }}
              >
                <SAIcon
                  size="45px"
                  icon="test"
                  color={
                    optionTypeDoc === "opt2" ? "blue" : "rgba(194, 195, 199, 1)"
                  }
                />

                <span
                  style={{
                    paddingBottom: "10px",
                    paddingTop: "10px",
                    color:
                      optionTypeDoc === "opt2"
                        ? "blue"
                        : "rgba(194, 195, 199, 1)",
                  }}
                >
                  Certificado de Teste
                </span>
              </DocumentTypeDiv>
            </DocumentTypesPair>

            {/* PAIR OF DOC TYPES */}
            <DocumentTypesPair>
              <DocumentTypeDiv
                onClick={() => {
                  setOptionTypeDoc("opt3");
                  setInfoToApi({
                    ...infoToApi,
                    doc_type_id: 3,
                  });
                }}
              >
                <SAIcon
                  size="45px"
                  icon="documents-medic"
                  color={
                    optionTypeDoc === "opt3" ? "blue" : "rgba(194, 195, 199, 1)"
                  }
                />

                <span
                  style={{
                    paddingBottom: "10px",
                    paddingTop: "10px",
                    color:
                      optionTypeDoc === "opt3"
                        ? "blue"
                        : "rgba(194, 195, 199, 1)",
                  }}
                >
                  Atestado médico
                </span>
              </DocumentTypeDiv>
              <DocumentTypeDiv
                onClick={() => {
                  setOptionTypeDoc("opt4");
                  setInfoToApi({
                    ...infoToApi,
                    doc_type_id: 4,
                  });
                }}
              >
                <SAIcon
                  size="45px"
                  icon="documents-recovery"
                  color={
                    optionTypeDoc === "opt4" ? "blue" : "rgba(194, 195, 199, 1)"
                  }
                />

                <span
                  style={{
                    paddingBottom: "10px",
                    paddingTop: "10px",
                    color:
                      optionTypeDoc === "opt4"
                        ? "blue"
                        : "rgba(194, 195, 199, 1)",
                  }}
                >
                  Certificado de recuperação
                </span>
              </DocumentTypeDiv>
            </DocumentTypesPair>
          </DocumentTypesDiv>

          {/* FILE INPUT */}
          <FileInputDiv>
            <HeaderDiv></HeaderDiv>
            <FileInputTitle>
              2. Arraste ou carregue aqui o seu documento
            </FileInputTitle>
            <form onSubmit={HandleForm}>
              <FormUpperDiv>
                <FormInputDiv>
                  <input
                    type="file"
                    id="myfile"
                    name="myfile"
                    class="documentFileInput"
                  ></input>
                  <label style={{}}>
                    Arraste ou Carregue para aqui o seu documento
                  </label>
                </FormInputDiv>
                <FormBtntDiv>
                  <SAButton
                    value="Submeter Documento"
                    size="large"
                    type={"submit"}
                    textcolor="#FFFFFF"
                  />
                </FormBtntDiv>
              </FormUpperDiv>
            </form>
          </FileInputDiv>
        </ContentDiv>
      </div>
    </>
  );
}

export default DocumentCreate;
