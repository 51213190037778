/** @format */

import React, { useState, useEffect } from "react";
import InquiryCard from "./InquiryCard";
import styled from "styled-components";
import axiosConfig from "../../providers/axiosConfig";
import SAPageTopBar from "../dashboard/overall/SA_Pagetopbar";
import Tutorial from "../Tutorial/tutorial";
import { CircularProgress } from "@mui/material";

const DivListing = styled.div`
  padding-bottom: 10px;

  @media screen and (min-width: 800px) {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
  }
`;

function Inquiries({ userData }) {
  const [inquiriesData, setInquiriesData] = useState([]);
  const [tutorial, setTutorial] = useState(false);
  const studentId = localStorage.getItem("student");
  sessionStorage.setItem("route", "inquiryList");
  useEffect(() => {
    let link = "/me/inquiries";
    axiosConfig
      .get(link, {
        params: { studentId: studentId },
      })
      .then((response) => {
        setInquiriesData(response.data);
      });
  }, [userData, studentId]);

  return (
    <>
      {userData.user.created_at === userData.user.updated_at ? (
        <div>
          <Tutorial userData={userData} setTutorial={setTutorial} continuos={true} />
        </div>
      ) : (
        <div>
          <Tutorial
            userData={userData}
            setTutorial={setTutorial}
            continuos={false}
            oneTime={true}
          />
        </div>
      )}

      <SAPageTopBar type={"inquerito"} backbutton={false} title={"Inquéritos"} />
      {!tutorial && inquiriesData && inquiriesData.active ? (
        <DivListing>
          {inquiriesData.active.length > 0 ? (
            inquiriesData.active.map((inquiry) => {
              return (
                <InquiryCard
                  id={inquiry.id}
                  title={inquiry.title}
                  description={inquiry.description}
                  num_questions={inquiry.inquiry_questions.length}
                />
              );
            })
          ) : (
            <>
              <p>Sem inquéritos de momento</p>
            </>
          )}
        </DivListing>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={80} />
        </div>
      )}
      {tutorial && (
        <DivListing>
          <InquiryCard
            id={1}
            title={"Inquérito de teste"}
            description={"Descrição do inquérito"}
            num_questions={"12"}
          />
          <InquiryCard
            id={1}
            title={"Inquérito de teste"}
            description={"Descrição do inquérito"}
            num_questions={"12"}
          />
          <InquiryCard
            id={1}
            title={"Inquérito de teste"}
            description={"Descrição do inquérito"}
            num_questions={"12"}
          />
        </DivListing>
      )}
    </>
  );
}
export default Inquiries;
