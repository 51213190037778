/** @format */

// import { ReactComponent as Covid } from "../../img/covid.svg";
import SAButton from '../dashboard/overall/SA_Button';
import styled from 'styled-components';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const MiddleDiv = styled.div`
  position: absolute;
  top: 30%;
  left: 35%;
  margin-top: -50px;
  margin-left: -50px;
  width: 700px;
  height: 400px;
  background: white;
  z-index: 3;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */

  @media (max-width: 1250px) {
    top: 25%;
    left: 0;
    margin: 0;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    padding: 1.25rem;
  }
`;

const ContentDiv = styled.div`
  margin-top: 20px;
  text-align: center;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
`;

const Description = styled.div`
  text-align: center;
  padding-left: 160px;
  padding-right: 160px;
  @media (max-width: 1250px) {
    padding: 0%;
  }
`;
const BtnDiv = styled.div`
  justify-content: center;
  display: flex;
`;

const UpperDiv = styled.div`
  position: absolute;
  top: 0%;
  right: 0%;
  width: 100%;
  height: 100%;
  z-index: 4;
  justify-content: center;

  @media (max-width: 1250px) {
    left: 0;
  }
`;

const Title = styled.p`
  font-size: 25px;
  font-weight: 500px;
  height: 80px;
`;

function PopUp({ logo, title, titleColor, text, path, backgroundColor, setState, ...props }) {
  useEffect(() => {
    let RootDiv = document.getElementById('root');
    RootDiv.style = 'overflow:hidden';
  }, []);

  const navigate = useNavigate();
  return (
    <UpperDiv id='window' style={{ backgroundColor: backgroundColor }}>
      <MiddleDiv id='window'>
        <ContentDiv>
          {/* SVG */}
          <div style={{ height: '150px' }}>{logo}</div>

          {/* TÍTULO */}
          <Title style={{ color: titleColor }}>{title}</Title>

          {/* DESCRIÇÃO */}
          <Description>{text}</Description>

          <BtnDiv>
            <SAButton
              textcolor='#FFFFFF'
              value='Continuar'
              onclick={() => {
                let popUp = document.getElementById('window');
                popUp.style = 'display:none';
                let RootDiv = document.getElementById('root');
                RootDiv.style = '';
                setState(false);
                if (path === -1) {
                  navigate(-1);
                } else navigate(path);
              }}
            />
          </BtnDiv>
        </ContentDiv>
      </MiddleDiv>
    </UpperDiv>
  );
}

export default PopUp;
