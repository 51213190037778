import React from "react";
import "../main.css";
import { useTranslation } from "react-i18next";
import event1 from "../img/event1.png";
import event1img1 from "../img/event1_img1.png";
import event1img2 from "../img/event1_img2.jpg";
import event1img3 from "../img/event1_img3.jpg";

import event2 from "../img/event2.png";
import event3 from "../img/event3.jpg";

import event4 from "../img/event4.jpg";
// import event4img1 from "../img/event4_img1.jpg";
import event4img2 from "../img/event4_img2.jpg";
import NavBar from "./NavBar";

function EventPage() {
  const eventImages = [
    { main: event1, images: [event1img1, event1, event1img2, event1img3] },
    { main: event2, images: [event2] },
    { main: event3, images: [event3] },
    { main: event4, images: [event4, event4img2] },
  ];
  const { t } = useTranslation();
  const eventNum = window.location.search.substring(4);
  const eventPath = "events.event" + eventNum;

  const images = eventImages[eventNum - 1].images.map((image, index) => {
    return <img className="image" src={image} key={index} alt="" />;
  });


  return (
    <>
      <NavBar white />
      <div className="event-page">
        <div className="event">
          <div className="card">
            <img
              className="main-image"
              src={eventImages[eventNum - 1].main}
              alt=""
            />
            <h3 className="title">{t(eventPath + ".title")}</h3>
            <h1 className="date">{t(eventPath + ".date")}</h1>
          </div>

          <p className="description">{t(eventPath + ".descript")}</p>
          {images}
        </div>
      </div>
    </>
  );
}

export default EventPage;
