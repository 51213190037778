import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import waveWeb from '../img/wave_web.svg';
import waveMobile from '../img/wave_mobile.svg';

function LandingPage() {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 800px)' });
    const { t } = useTranslation();

    return (
        <section className='landing'>
            <h1>{t('hero')}</h1>
            <img src={isTabletOrMobile ? waveMobile : waveWeb} alt=""/>
        </section>
    );
}

export default LandingPage;
