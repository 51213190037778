/** @format */

import { useState, useEffect } from "react";
import axiosConfig from "../../../providers/axiosConfig";
import SchedulingsCalendar from "./Calendar";
import {
  CardWrapper,
  SchedulePillWrapper,
  PillTitle,
  PillLocation,
  Separator,
} from "../styles/SchedulesModule.styles";
import { Alert, Snackbar } from "@mui/material";

function SchedulesModule({ userData, setGeneralDate }) {
  const [schedules, setSchedules] = useState([]);
  const [dateFilter, setDateFilter] = useState();
  const [axiosError, setAxiosError] = useState(null);

  const studentId = localStorage.getItem("student");
  let hasSchedulings = false;

  //Axios - Request user Shcedule
  useEffect(() => {
    axiosConfig
      .get(`/webaluno/schedule`, {
        params: { studentId: studentId },
      })
      .then((response) => {
        setSchedules(response.data.schedules);
      })
      .catch(function (err) {
        setAxiosError(err.response.data.message);
      });
  }, [userData, studentId]);

  return (
    <>
      <Snackbar
        open={axiosError}
        onClose={() => {
          setAxiosError(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert severity="error">{axiosError}</Alert>
      </Snackbar>
      <div>
        <h3>Agendamentos</h3>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ marginBottom: "20px" }}>
            <CardWrapper>
              <SchedulingsCalendar
                setDateFilter={setDateFilter}
                setGeneralDate={setGeneralDate}
              />
              <Separator />
              <div style={{ padding: "20px" }}>
                {schedules !== undefined &&
                  schedules.map((schedule, index) => {
                    let start_time = new Date(schedule.start_time);
                    if (
                      schedule.canceled === false &&
                      start_time.getDate() === dateFilter.getDate() &&
                      start_time.getMonth() === dateFilter.getMonth() &&
                      start_time.getFullYear() === dateFilter.getFullYear()
                    ) {
                      hasSchedulings = true;
                      return (
                        <SchedulePillWrapper>
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "start",
                              alignItems: "center",
                              gap: "10px",
                              paddingTop: "5px",
                              paddingLeft: "20px",
                            }}
                          >
                            <div style={{ width: "50px" }}>
                              <PillTitle>
                                {start_time.getUTCHours()}:
                                {start_time.getMinutes() > 9
                                  ? start_time.getMinutes()
                                  : "0" + start_time.getMinutes()}
                              </PillTitle>
                            </div>
                            <div
                              style={{
                                height: "40px",
                                width: "2px",
                                background: "#C14AEB",
                                borderRadius: "30px",
                              }}
                            ></div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "start",
                                paddingRight: "15px",
                              }}
                            >
                              <PillTitle> {schedule.description}</PillTitle>
                              <PillLocation>{schedule.location}</PillLocation>
                            </div>
                          </div>
                        </SchedulePillWrapper>
                      );
                    }
                    return null;
                  })}
                {!hasSchedulings && (
                  <div>
                    <h4>Não existem agendamentos para o respetivo dia</h4>
                  </div>
                )}
              </div>
            </CardWrapper>
          </div>
        </div>
      </div>
    </>
  );
}
export default SchedulesModule;
