/** @format */

import React, { useEffect } from "react";
import SAPageTopBar from "./overall/SA_Pagetopbar";
import SAButton from "./overall/SA_Button";
import { useState } from "react";
import axiosConfig from "../../providers/axiosConfig";
import { useNavigate } from "react-router-dom";
import ProfileList from "./ProfileList";
import { CircularProgress } from "@mui/material";

function Profile({ userData }) {
  const { user } = userData;
  const studentId = localStorage.getItem("student");
  const [info, setInfo] = useState([]);
  const navigate = useNavigate();

  if (user.created_at === user.updated_at) {
    navigate("/changePassword");
  }
  useEffect(() => {
    axiosConfig
      .get("/me/info", {
        params: {
          studentId: studentId,
          studentProfile: false,
        },
      })
      .then((res) => {
        setInfo(res.data);
      });
  }, [user, userData, studentId]);

  return (
    <>
      {info && info.user ? (
        <>
          <SAPageTopBar
            type={"default"}
            backbutton={false}
            title={"Dados Pessoais"}
            button={
              userData.isParent ? (
                <>
                  <SAButton
                    style={["btn-secondary"]}
                    value={"Ver dados pessoais do educando"}
                    size={"adaptive"}
                    textcolor={"#4566AF"}
                    onclick={() => navigate("/student_acount")}
                  />
                  <SAButton
                    style={["btn-secondary"]}
                    value={"Ver ficha médica do educando"}
                    size={"adaptive"}
                    textcolor={"#4566AF"}
                    onclick={() => navigate("/medical_profile")}
                  />
                </>
              ) : (
                <SAButton
                  style={["btn-secondary"]}
                  value={"Ficha Médica"}
                  size={"adaptive"}
                  textcolor={"#4566AF"}
                  onclick={() => navigate("/medical_profile")}
                />
              )
            }
          />
          <ProfileList info={info} userData={userData} />
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress style={{ marginTop: "20%" }} size={80} />
        </div>
      )}
    </>
  );
}

export default Profile;
