/** @format */

import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axiosConfig from "../../providers/axiosConfig";
import SANewsCard from "../dashboard/news/SA_NewsCard";
import SAPageTopBar from "../dashboard/overall/SA_Pagetopbar";
import Tutorial from "../Tutorial/tutorial";

const WrapperGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 0.3fr 1fr;
  align-items: flex-start;
  gap: 30px;
  @media (max-width: 1250px) {
    grid-template-columns: unset;
    display: flex;
    flex-direction: column;
  }
`;
const WrapperNews = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;

  & div:first-child {
    width: calc((100% / 1.5) - 20px);
    @media (max-width: 1250px) {
      width: 100%;
    }
  }
`;
const Categories = styled.div`
  background-color: #fff;
  border-radius: 1.25rem;
  box-sizing: border-box;
  padding: 1.25rem;

  @media (max-width: 1250px) {
    width: 100%;
  }

  & .row {
    display: flex;
    width: 100%;
    justify-content: space-between;

    & span {
      color: #b0c2ea;
    }
  }
`;

const Filters = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 0.625rem;

  & .filter {
    background-color: #b0c2ea;
    padding: 6px 12px;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;

    &.active {
      background-color: #4566af;
    }
  }
`;
function News({ userData }) {
  const [news, SetNews] = useState(null);
  const [filter, setFilter] = useState(null);
  const [tutorial, setTutorial] = useState(false);
  const studentId = localStorage.getItem("student");
  sessionStorage.setItem("route", "news");

  useEffect(() => {
    axiosConfig
      .get("/me/news", {
        params: { studentId: studentId },
      })
      .then((res) => {
        SetNews(res.data);
      });
  }, [userData, studentId]);
  return (
    <>
      {userData.user.created_at === userData.user.updated_at ? (
        <div>
          <Tutorial userData={userData} setTutorial={setTutorial} continuos={true} />
        </div>
      ) : (
        <div>
          <Tutorial
            userData={userData}
            setTutorial={setTutorial}
            continuos={false}
            oneTime={true}
          />
        </div>
      )}

      <SAPageTopBar type={"noticia"} title={"Notícias"} />

      {news && !tutorial ? (
        <WrapperGrid>
          <Categories id="news-filters">
            <div className="row">
              <h3>Categorias</h3>
              <span>
                {filter === null ? news.length : news.filter((elem) => elem.type === filter).length}{" "}
                resultados
              </span>
            </div>
            <Filters>
              <div
                onClick={() => setFilter(null)}
                className={filter === null ? "filter active" : "filter"}
              >
                Todos
              </div>
              {news.filter((elem) => elem.type === "saude").length > 0 && (
                <div
                  onClick={() => setFilter("saude")}
                  className={filter === "saude" ? "filter active" : "filter"}
                >
                  Saúde
                </div>
              )}
              {news.filter((elem) => elem.type === "escolar").length > 0 && (
                <div
                  onClick={() => setFilter("escolar")}
                  className={filter === "escolar" ? "filter active" : "filter"}
                >
                  Escola
                </div>
              )}
              {news.filter((elem) => elem.type === "cultura").length > 0 && (
                <div
                  onClick={() => setFilter("cultura")}
                  className={filter === "cultura" ? "filter active" : "filter"}
                >
                  Cultura
                </div>
              )}
            </Filters>
          </Categories>
          <WrapperNews>
            {news
              .filter((elem) => filter === null || elem.type === filter)
              .map((news) => (
                <SANewsCard
                  id={news.id}
                  type={news.type}
                  header={news.title}
                  text={news.subtitle}
                  image={news.image}
                  key={news.id}
                />
              ))}
          </WrapperNews>
        </WrapperGrid>
      ) : !tutorial && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <CircularProgress size={80} />
        </div>
      )}
      {tutorial && (
        <WrapperGrid>
          <Categories id="news-filters">
            <div className="row">
              <h3>Categorias</h3>
              <span>2 resultados</span>
            </div>
            <Filters>
              <div className="filter active">Todos</div>
              <div className="filter">Saúde</div>

              <div className="filter">Escola</div>

              <div className="filter">Cultura</div>
            </Filters>
          </Categories>
          <WrapperNews id="news">
            <SANewsCard
              type={'Cultura'}
              header={"Título da notícia"}
              text={"Texto da Notícia"}
              image={
                "https://images.ctfassets.net/hrltx12pl8hq/4f6DfV5DbqaQUSw0uo0mWi/6fbcf889bdef65c5b92ffee86b13fc44/shutterstock_376532611.jpg?fit=fill&w=800&h=300"
              }
            />
            <SANewsCard
              type={'Saúde'}
              header={"Título da notícia"}
              text={"Texto da Notícia"}
              image={
                "https://thumbs.dreamstime.com/b/opini%C3%A3o-a%C3%A9rea-lago-antorno-dolomites-paisagem-da-montanha-do-103752677.jpg"
              }
            />
          </WrapperNews>
        </WrapperGrid>
      )}
    </>
  );
}

export default News;
