import moment from "moment";
import React from "react";
import { ReactComponent as Entry } from "../../img/registaded_entry.svg";
import ListComponent from "./ListComponent";

function EntracesHistoryCard(props) {
  const entrances = props.entrances;
  const studentLessons = props.studentLessons.filter(studentLessons => studentLessons.attendance !== "waiting").length;
  const dailyEntrances = entrances.filter(
    (entrance) =>
      moment(entrance.created_at).format("YYYY-MM-DD") ===
      moment(props.dateFilter).format("YYYY-MM-DD")
  );
  if (dailyEntrances.length > 0) {
    return (
      <>
        {dailyEntrances.map((entrance) => {
          let entranceDate = moment(entrance.created_at);

          return (
            <ListComponent
              endIcon={<Entry />}
              style={["entrys"]}
              hours={moment(entranceDate).format("HH")}
              minutes={moment(entranceDate).format("mm")}
              title={"Entrada Registada"}
              subtitle={"Portão da Escola " + entrance.school_id}
            />
          );
        })}
      </>
    );
  } else {
    if (studentLessons === 0){
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            fontWeight: "700",
            fontSize: "1.25rem",
            marginTop: "1.5rem",
          }}
        > 
          <p style={{ color: "#35E1BA" }}>Sem Entradas registadas</p>
        </div>
      );
    }
    
  }
}
export default EntracesHistoryCard;
