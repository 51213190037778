/** @format */

import React from "react";
import styled from "styled-components";
import SAInput from "./overall/SA_Input";
import SAButton from "./overall/SA_Button";
import { useState } from "react";
import axiosConfig from "../../providers/axiosConfig";
import { Alert, Avatar, Snackbar } from "@mui/material";
import SAEditButton from "./overall/SA_EditButton";
import moment from "moment";
import { useLocation } from "react-router-dom";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const SpecialRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 1rem;
  gap: 1rem;
  flex-wrap: wrap;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-grow: 1;
`;
const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #fff;
  border-radius: 8px;
  height: 100%;
  box-sizing: border-box;
  padding: 1.25rem;
`;

const Line = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  border-bottom: 0.5px solid #bcc8e7;
  margin-top: 1rem;
`;

const FadedLine = styled.p`
  font-style: normal;
  font-size: 16px;
  color: #b0c2ea;
`;

const PasswordForm = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
    & button {
      margin: 0 auto;
    }
  }
`;

const Select = styled.select`
  width: 100%;
  border: 1px solid #4566af80;
  background-color: #fff;
  padding: 0.5rem 0.325rem;
  border-radius: 8px;
  color: #b0c2ea;
  font-size: 1rem;
`;

const Row = styled.div`
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: nowrap;
  display: flex;
  gap: 10px;
`;

function ProfileList({ info, userData, studentProfile, ...props }) {
  const [oldPassword, setOldPassword] = useState("");
  const [axiosError, setAxiosError] = useState(null);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordRepeat, setNewPasswordRepeat] = useState("");
  const [editBio, setEditBio] = useState(false);
  const [editContacts, setEditContacts] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [editDoc, setEdicDoc] = useState(false);
  const [preview, setPreview] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(true);
  const route = useLocation();

  function ResetPassword(e) {
    e.preventDefault();
    const data = {
      current_password: oldPassword,
      new_password: newPassword,
      new_password_confirmation: newPasswordRepeat,
    };

    if (!oldPassword) {
      return setAxiosError("Falta inserir a palavra-passe antiga");
    }

    axiosConfig
      .post("change_password", data)
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        setAxiosError(err.message);
      });
  }

  // HANDLE FUNCTIONS
  function sendToApi(dataToApi) {
    if (Object.keys(dataToApi).length !== 0) {
      axiosConfig
        .put(
          `me/update_user/${
            studentProfile ? info.user.id + "?studentId=" + info.user.id : userData.user.id
          }`,
          dataToApi
        )
        .then(() => {
          window.location.reload();
        })
        .catch(() => {
          setAxiosError("Ocorreu um erro ao atualizar os dados pessoais.");
        });
    }
  }

  function changePicture(file) {
    if (file.size > 600000) {
      return setAxiosError("A imagem inserida é demasiado grande");
    }

    let formData = new FormData();

    formData.append("photo", file);
    formData.append("id", studentProfile ? info.user.id : userData.user.id);

    axiosConfig.post(`profile/change_picture`, formData).then(() => {
      if (!userData.isParent) {
        window.location.reload();
      }
    });
  }

  function handleBioInfo() {
    const inputIds = ["name", "birth_date", "gender", "nacionality", "civil_state"];

    let data = {};
    inputIds.map((results) => {
      let currentValue = moment(info.user[results]).isValid()
        ? moment(info.user[results]).format("YYYY-MM-DD")
        : info.user[results];
      if (
        document.getElementById(results).value !== "" &&
        document.getElementById(results).value !== null &&
        document.getElementById(results).value !== currentValue
      ) {
        data = {
          [results]: document.getElementById(results).value,
          ...data,
        };
      }
      return data;
    });

    if (document.getElementById("photo_url").files.length > 0) {
      changePicture(document.getElementById("photo_url").files[0]);
    }

    sendToApi(data);
  }
  function handleContact() {
    const inputIds = ["mobile_number", "email"];

    let data = {};
    inputIds.map((results) => {
      if (
        document.getElementById(results).value !== "" &&
        document.getElementById(results).value !== null
      ) {
        data = {
          [results]: document.getElementById(results).value,
          ...data,
        };
      }
      return data;
    });
    sendToApi(data);
  }
  function handleAddress() {
    const inputIds = ["street"];
    let data = {};
    inputIds.forEach((results) => {
      if (
        document.getElementById(results).value !== "" &&
        document.getElementById(results).value !== null
      ) {
        data = {
          [results]: document.getElementById(results).value,
          ...data,
        };
      }
      return data;
    });
    sendToApi(data);
  }
  function handleIdDocuments() {
    const inputIds = ["nif", "cc", "health_number"];
    let data = {};
    inputIds.map((results) => {
      if (
        document.getElementById(results).value !== "" &&
        document.getElementById(results).value !== null
      ) {
        data = {
          [results]: document.getElementById(results).value,
          ...data,
        };
      }
      return data;
    });
    sendToApi(data);
  }

  return (
    <Main>
      <Snackbar
        open={axiosError}
        onClose={() => {
          setAxiosError(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert severity="error">{axiosError}</Alert>
      </Snackbar>
      <SpecialRow>
        <Card style={{ flexGrow: 1 }}>
          <Row style={{ justifyContent: "flex-end" }}>
            {!editBio ? (
              <SAEditButton
                isRelative={true}
                label={editBio ? "Guardar Alterações" : "Alterar"}
                onclick={() => {
                  setEditBio(true);
                }}
              />
            ) : (
              <Row>
                <SAButton
                  type={"reset"}
                  setInvalidPassword
                  textcolor={"#fff"}
                  bgColor={"#9A9AA399"}
                  size={"small"}
                  onclick={() => setEditBio(false)}
                  value={"Cancelar"}
                />
                <SAButton
                  textcolor={"#fff"}
                  size={"small"}
                  value={"Guardar Alterações"}
                  onclick={() => {
                    if (!userData.isParent) {
                      if (document.getElementById("photo_url").files.length > 0) {
                        changePicture(document.getElementById("photo_url").files[0]);
                      }
                    } else handleBioInfo();
                  }}
                />
              </Row>
            )}
          </Row>
          <Row style={{ gap: "1.25rem" }}>
            <div
              style={{
                alignSelf: "flex-start",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Avatar
                variant={"circular"}
                src={preview ? preview : info.user.photo_url ? info.user.photo_url : ""}
                sx={{ width: 130, height: 130, margin: "auto" }}
              />

              <FadedLine style={{ textAlign: "center" }}>
                {studentProfile ? "Informação do aluno" : "Encarregado de Educação"}
              </FadedLine>
              {editBio && (
                <input
                  type="file"
                  id="photo_url"
                  src={info.user.photo_url ?? ""}
                  accept="image/png, image/jpeg"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    let file = e.target.files[0];
                    var reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = function () {
                      setPreview(reader.result);
                    };
                  }}
                />
              )}
            </div>

            {!editBio || !userData.isParent ? (
              <div>
                <h3 style={{ marginTop: 0 }}>Informação Biográfica</h3>
                <Line>Nome Completo: {info.user.name}</Line>
                <Line>
                  Data de Nascimento:{" "}
                  {moment(info.user.birth_date).isValid()
                    ? moment(info.user.birth_date).format("YYYY-MM-DD")
                    : "N/A"}
                </Line>
                <Line>
                  Sexo:{" "}
                  {info.user.gender && info.user.gender === "F"
                    ? "Feminino"
                    : info.user.gender && info.user.gender === "M"
                    ? "Masculino"
                    : "Outro"}
                </Line>
                <Line>Estado Civil: {info.user.civil_state ? info.user.civil_state : "N/A"}</Line>
                <Line>Nacionalidade: {info.user.nacionality ? info.user.nacionality : "N/A"}</Line>
              </div>
            ) : (
              <div>
                <h3>Informação Biográfica</h3>
                <Line style={{ borderBottomWidth: 0 }}>
                  Nome Completo:
                  <SAInput type="text" id={"name"} defaultvalue={info.user.name} />
                </Line>
                <Line style={{ borderBottomWidth: 0 }}>
                  Data de Nascimento:
                  <SAInput
                    type="date"
                    id={"birth_date"}
                    defaultvalue={
                      moment(info.user.birth_date).isValid() &&
                      moment(info.user.birth_date).format("YYYY-MM-DD")
                    }
                  />
                </Line>
                <Line style={{ borderBottomWidth: 0 }}>
                  Sexo:
                  <Select id="gender" defaultValue={info.user.gender}>
                    <option value={""}>Selecione uma opção</option>
                    <option value="M">Masculino</option>
                    <option value="F">Feminino</option>
                    <option value="O">Outro</option>
                  </Select>
                </Line>
                <Line style={{ borderBottomWidth: 0 }}>
                  Estado Civil:{" "}
                  <Select id="civil_state" defaultValue={info.user.civil_state}>
                    <option value={""}>Selecione uma opção</option>
                    <option value="Casado">Casado</option>
                    <option value="Divorciado">Divorciado</option>
                    <option value="Solteiro">Solteiro</option>
                    <option value="Viúvo">Viúvo</option>
                    <option value="Outro">Outro</option>
                  </Select>
                </Line>
                <Line style={{ borderBottomWidth: 0 }}>
                  Nacionalidade:
                  <Select id="nacionality" defaultValue={info.user.nacionality}>
                    <option value={""}>Selecione uma opção</option>
                    <option value="Alemã">Alemã</option>
                    <option value="Espanhola">Espanhola</option>
                    <option value="Francesa">Francesa</option>
                    <option value="Inglesa">Inglesa</option>
                    <option value="Portuguesa">Portuguesa</option>
                    <option value="Venezuelana">Venezuelana</option>
                    <option value="Outra">Outra</option>
                  </Select>
                </Line>
              </div>
            )}
          </Row>
        </Card>
        <Column>
          <Card>
            <Row style={{ justifyContent: "space-between" }}>
              <h3 style={{ marginTop: 0 }}>Contatos</h3>
              {userData.isParent &&
                (!editContacts ? (
                  <SAEditButton
                    isRelative={true}
                    label={editContacts ? "Guardar Alterações" : "Alterar"}
                    onclick={() => {
                      setEditContacts(true);
                    }}
                  />
                ) : (
                  <Row>
                    <SAButton
                      type={"reset"}
                      textcolor={"#fff"}
                      bgColor={"#9A9AA399"}
                      size={"small"}
                      onclick={() => setEditContacts(false)}
                      value={"Cancelar"}
                    />
                    <SAButton
                      textcolor={"#fff"}
                      size={"small"}
                      value={"Guardar Alterações"}
                      onclick={() => handleContact()}
                    />
                  </Row>
                ))}
            </Row>

            {!editContacts ? (
              <div>
                <Line>Telemóvel: {info.user.mobile_number ? info.user.mobile_number : "N/A"}</Line>

                <Line>Email Pessoal: {info.user.email ? info.user.email : "N/A"}</Line>
              </div>
            ) : (
              <div>
                <Line style={{ borderBottomWidth: 0 }}>
                  Telemóvel:{" "}
                  <SAInput
                    type="text"
                    id={"mobile_number"}
                    defaultvalue={info.user.mobile_number}
                  />
                </Line>

                <Line style={{ borderBottomWidth: 0 }}>
                  Email Pessoal:{" "}
                  <SAInput type="email" id={"email"} defaultvalue={info.user.email} />
                </Line>
              </div>
            )}
          </Card>
          <Card>
            <Row style={{ justifyContent: "space-between" }}>
              <h3 style={{ marginTop: 0 }}>Morada</h3>
              {userData.isParent &&
                (!editAddress ? (
                  <SAEditButton
                    isRelative={true}
                    label={editAddress ? "Guardar Alterações" : "Alterar"}
                    onclick={() => {
                      setEditAddress(true);
                    }}
                  />
                ) : (
                  <Row>
                    <SAButton
                      type={"reset"}
                      textcolor={"#fff"}
                      bgColor={"#9A9AA399"}
                      size={"small"}
                      onclick={() => setEditAddress(false)}
                      value={"Cancelar"}
                    />
                    <SAButton
                      textcolor={"#fff"}
                      size={"small"}
                      value={"Guardar Alterações"}
                      onclick={() => handleAddress()}
                    />
                  </Row>
                ))}
            </Row>

            {!editAddress ? (
              <div>
                {info.user.street && <FadedLine>{info.user.street}</FadedLine>}
                {!info.user.county && !info.user.postal_code && !info.user.street && (
                  <FadedLine>Sem informação da morada do utilizador</FadedLine>
                )}
              </div>
            ) : (
              <div>
                <SAInput type="textarea" id={"street"} defaultvalue={info.user.street} />
              </div>
            )}
          </Card>
        </Column>
      </SpecialRow>
      <Column>
        <Card>
          <Row style={{ justifyContent: "space-between" }}>
            <h3 style={{ marginTop: 0 }}>Documentos de Identificação</h3>
            {userData.isParent &&
              (!editDoc ? (
                <SAEditButton
                  isRelative={true}
                  label={editDoc ? "Guardar Alterações" : "Alterar"}
                  onclick={() => {
                    setEdicDoc(true);
                  }}
                />
              ) : (
                <Row>
                  <SAButton
                    type={"reset"}
                    textcolor={"#fff"}
                    bgColor={"#9A9AA399"}
                    size={"small"}
                    onclick={() => setEdicDoc(false)}
                    value={"Cancelar"}
                  />
                  <SAButton
                    textcolor={"#fff"}
                    size={"small"}
                    value={"Guardar Alterações"}
                    onclick={() => handleIdDocuments()}
                  />
                </Row>
              ))}
          </Row>

          {!editDoc ? (
            <div>
              <Line>Número de Identificação Fiscal: {info.user.nif ? info.user.nif : "N/A"}</Line>
              <Line>Número de Identificação Civil: {info.user.cc ? info.user.cc : "N/A"}</Line>
              <Line>
                Cartão de Utente: {info.user.health_number ? info.user.health_number : "N/A"}
              </Line>
            </div>
          ) : (
            <div>
              <Line style={{ borderBottomWidth: 0 }}>
                Número de Identificação Fiscal:{" "}
                <SAInput type="text" id={"nif"} defaultvalue={info.user.nif} />
              </Line>
              <Line style={{ borderBottomWidth: 0 }}>
                Número de Identificação Civil:{" "}
                <SAInput type="text" id={"cc"} defaultvalue={info.user.cc} />
              </Line>
              <Line style={{ borderBottomWidth: 0 }}>
                Cartão de Utente:{" "}
                <SAInput type="text" id={"health_number"} defaultvalue={info.user.health_number} />
              </Line>
            </div>
          )}
        </Card>
        {route.pathname !== "/student_acount" && (
          <Card>
            <div style={{ display: "flex", boxSizing: "border-box", gap: "1rem" }}>
              <h3 style={{ marginTop: 0 }}>Palavra-Passe</h3>
              {/* <Tooltip
              arrow
              title="A nova palavra-passe deve conter pelo menos: 1 Letra Maiúscula, 1 Letra Minúscula, 1 Número, 1 Caractér Especial(Entre os seguintes: @$!%*?&) e ter um total de caractéres de pelo menos 10;"
              placement="top-end"
              enterTouchDelay={0}
            >
              <span>
                <SAIcon icon={"help"} color={"#B0C2EA"} size={20} />
              </span>
            </Tooltip> */}
            </div>

            <Snackbar
              open={axiosError}
              onClose={() => {
                setAxiosError(null);
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
            >
              <Alert severity="error">{axiosError}</Alert>
            </Snackbar>

            <form onSubmit={(event) => ResetPassword(event)}>
              <PasswordForm>
                <SAInput
                  type="password"
                  label={"Palavra-Passe Antiga"}
                  onchange={(e) => {
                    setOldPassword(e.target.value);
                  }}
                  visibilityIcon={true}
                />
                <SAInput
                  type="password"
                  label={"Nova Palavra-Passe"}
                  visibilityIcon={true}
                  onchange={(e) => {
                    setInvalidPassword(
                      // eslint-disable-next-line
                      !/^.*(?=.{10,})(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[\d\x])(?=.*[!$&.:,;\'#%]).*$/.test(
                        e.target.value
                      )
                    );
                    setNewPassword(e.target.value);
                  }}
                />
                <SAInput
                  type="password"
                  label={"Repetir Nova Palavra-Passe"}
                  visibilityIcon={true}
                  onchange={(e) => setNewPasswordRepeat(e.target.value)}
                />
                <SAButton
                  size={"adaptive"}
                  align={"right"}
                  value={"Alterar Palavra-Passe"}
                  textcolor="#fff"
                  type={"submit"}
                />
              </PasswordForm>
            </form>
            {invalidPassword && (
              <span style={{ color: "red" }}>
                A palavra-passe deverá ter pelo menos 10 caratéres e conter pelo menos uma letra
                maiúscula, uma letra minúscula, um número e um caractere especial
              </span>
            )}
          </Card>
        )}
        {/* <Card>
                    <h3>Preferências</h3>

                    <h4>Idioma</h4>
                    <div>
                        <p>
                            <input type={'radio'} value='pt-PT' name='language' /> Português
                        </p>
                        <p>
                            <input type={'radio'} value='en-EN' name='language' /> Inglês
                        </p>
                        <p>
                            <input type={'radio'} value='es-ES' name='language' /> Espanhol
                        </p>
                    </div>

                    <h4>Autenticação de dois passos</h4>
          <p>
            A Autenticação de dois passos tem como objetivo tornar mais seguro o
            acesso à sua conta. Sempre que tentar aceder à sua conta, um código
            será enviado para o seu email pessoal para depois conseguir usá-lo
            para entrar. Deseja ativar esta função?
          </p>
          <div>
            <p>
              <input type={"radio"} value={true} name="2FA" /> Sim
            </p>
            <p>
              <input type={"radio"} value={false} name="2FA" /> Não
            </p>
          </div>

          <h4>Notificações de conta</h4>

          <p>
            Deseja receber notificações no seu email pessoal, caso a sua conta
            seja utilizada em um novo dispositivo?
          </p>

          <div>
            <p>
              <input type={"radio"} value={true} name="newDevice" /> Sim
            </p>
            <p>
              <input type={"radio"} value={false} name="newDevice" /> Não
            </p>
          </div>

          <p>
            Deseja receber notificações no seu email pessoal, caso exista uma
            tentativa falhada de aceder à sua conta?
          </p>

          <div>
            <p>
              <input type={"radio"} value={true} name="missingAttempt" /> Sim
            </p>
            <p>
              <input type={"radio"} value={false} name="missingAttempt" /> Não
            </p>
          </div>
                </Card> */}
      </Column>
    </Main>
  );
}
export default ProfileList;
