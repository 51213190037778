import React from "react";
import LessonComponent from "./LessonComponent";

function ClassesEntrancesCard(props) {
  const lessons = props.lessons;
  const studentLessons = props.studentLessons;

  if (lessons && studentLessons) {
    return (
      <>
        {studentLessons.map((studentLesson) => {
          let entranceDate = new Date(studentLesson.created_at);
          if (
            entranceDate.getDate() === props.dateFilter.getDate() &&
            entranceDate.getMonth() === props.dateFilter.getMonth() &&
            entranceDate.getFullYear() === props.dateFilter.getFullYear()
          ) {
            if (
              studentLesson.attendance === "present" ||
              studentLesson.attendance === "late"
            ) {
              let enteredAt = new Date(studentLesson.entered_at);
              return (
                <>
                  {lessons.map((lesson, key) => {
                    if (lesson.id === studentLesson.lesson_id) {
                      return (
                        <LessonComponent
                          key={lesson.id}
                          subjects={props.subjects}
                          present={true}
                          enteredAt={enteredAt}
                          subject_id={lesson.subject_id}
                        />
                      );
                    }
                    return null;
                  })}
                </>
              );
            } else {
              if (studentLesson.attendance === "absent") {
                let enteredAt = new Date(studentLesson.entered_at);
                return (
                  <>
                    {lessons.map((lesson, key) => {
                      if (lesson.id === studentLesson.lesson_id) {
                        return (
                          <LessonComponent
                            subjects={props.subjects}
                            present={false}
                            enteredAt={enteredAt}
                            subject_id={lesson.subject_id}
                          />
                        );
                      }
                      return null;
                    })}
                  </>
                );
              }
            }
          }
          return null;
        })}
      </>
    );
  }
}
export default ClassesEntrancesCard;
