/** @format */

import * as React from "react";
// import "intro.js/introjs.css";

//Pages
import Inquiries from "./components/Inquiries/Inquiries";
import InquiryAnswer from "./components/Inquiries/InquiryAnswer";
import Entrances from "./components/Entrances/Entrances";
import AlertPage from "./components/Alerts/Alerts";
import DocumentsPage from "./components/Documents/Documents";
import DocumentShowPage from "./components/Documents/DocumentShow";
import DocumentCreate from "./components/Documents/DocumentCreate";
import Schedules from "./components/Schedules/Schedules";
import EventPage from "./components/EventPage";
import Dashboard from "./components/dashboard/Dashboard";
import DashboardLandingPage from "./components/dashboard/DashboardLanding";
import SelectChild from "./components/SelectChildPage/SelectChild";
import StudentProfile from "./components/dashboard/StudentProfile";
// import Chat from "./components/Chat/Chat"

//Sections
import HomePage from "./HomePage";
import Footer from "./components/Footer";
import SplashScreen from "./components/SplashScreen";

//React Default Components
import { Route, Routes, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

//Icons
import { ReactComponent as ScrollTop } from "./img/scrollTop.svg";
import useScrollPosition from "@react-hook/window-scroll";
import axiosConfig from "./providers/axiosConfig";
import AlertInvalidDoc from "./components/Alerts/AlertInvalidDoc";
import AlertDocumentForm from "./components/Alerts/AlertDocumentForm";
import News from "./components/News/News";
import NewsShow from "./components/News/NewsShow";
import Profile from "./components/dashboard/Profile";
import ChangePassword from "./components/dashboard/ChangePassword";
import AlertHighTemp from "./components/Alerts/AlertHighTemp";

// DocumentCreateDemo.js
import DocumentCreateFormDemo from "./components/Documents/DocumentCreateDemo";
import InquiryAnswerDemo from "./components/Inquiries/InquiryAnswerDemo";
import AlertHighTempDemo from "./components/Alerts/AlertHighTempDemo";

// import Chat from "./components/Chat/Chat";
import DeleteInfoPage from "./components/dashboard/DeleteInfoPage";
import MedicalProfile from "./components/dashboard/MedicalProfile";
import Policy from "./components/Policy";
import "moment/locale/pt";
import RecoverPassword from "./components/RecoverPassword";
import queryString from "query-string";
import { CircularProgress } from "@mui/material";

function App({ location }) {
  const scrollY = useScrollPosition(60);
  const [animated, setAnimated] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [startRendering, setStartRendering] = useState(false);
  const [dontAnimate, setDontAnimate] = useState(false);
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [passwordRecovery, setPasswordRecovery] = useState(false);
  const [authToken, setAuthToken] = useState(null);

  const scrollToTop = (quick) => {
    window.scrollTo({
      top: 0,
      behavior: quick ? "auto" : "smooth",
    });
  };

  const scrollTo = (id) => {
    const el = document.getElementById(id.substring(1));
    // el.scrollIntoView({behavior: "smooth", block: "start", inline: "center"})
    var headerOffset = 100;
    var elementPosition = el.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    let params;
    let authStringIndex = window.location.toString().indexOf("auth_token");
    if (window.location && authStringIndex !== -1) {
      params = queryString.parse(window.location.toString().substring(authStringIndex));
    }

    if (params && params.auth_token && params.auth_token !== "") {
      setAuthToken(params.auth_token);
      setPasswordRecovery(true);
      document.getElementById("root").setAttribute("style", "overflow: hidden");
    }
  }, []);

  useEffect(() => {
    setDontAnimate(window.localStorage.getItem("dontAnimate") === "1" || passwordRecovery);
    window.localStorage.removeItem("dontAnimate");

    document.addEventListener(
      "click",
      (e) => {
        const anchor = e.target.closest("a");
        if (anchor !== null) {
          window.localStorage.setItem("dontAnimate", "1");

          setTimeout(() => {
            window.localStorage.removeItem("dontAnimate");
          }, 500);
        }
      },
      false
    );

    if (window.location.pathname === "/") {
      setLoaded(true);

      setTimeout(() => {
        scrollToTop();

        setStartRendering(true);
        setTimeout(() => {
          if (window.location.hash) scrollTo(window.location.hash);
        }, 200);
      }, 600);

      setTimeout(() => {
        setAnimated(true);
        setTimeout(() => {
          setLoaded(false);
        }, 200);
      }, 2200);
    } else {
      setStartRendering(true);
      setAnimated(true);
    }
  }, [animated, dontAnimate, passwordRecovery]);

  useEffect(() => {
    if (!window.localStorage.getItem("auth")) {
      if (
        !(
          window.location.pathname.includes("event") ||
          window.location.pathname.includes("policy") ||
          passwordRecovery
        )
      ) {
        return navigate("/");
      }
    } else {
      axiosConfig
        .get("/me")
        .then((res) => {
          setUserData(res.data);
        })
        .catch((err) => {
          navigate("/");
        });
    }
  }, [navigate, passwordRecovery]);

  useEffect(() => {
    scrollToTop(1);
    // eslint-disable-next-line
  }, [window.location.pathname]);

  let studentData = localStorage.getItem("student");

  return (
    <>
      {userData && userData.user ? (
        <>
          {userData.isParent && !studentData ? (
            <Routes>
              {window.location.pathname === "/" && navigate("/select_child")}
              <Route path="/select_child" element={<SelectChild userData={userData} />} />
            </Routes>
          ) : (
            <Dashboard userData={userData}>
              <Routes>
                {window.location.pathname === "/" && navigate("/dashboard")}
                <Route path="/dashboard" element={<DashboardLandingPage userData={userData} />} />
                <Route
                  path="/document_show/:id"
                  element={<DocumentShowPage userData={userData} />}
                />
                <Route
                  path="/document_create/:id"
                  element={<DocumentCreate userData={userData} />}
                />
                <Route path="/alerts" element={<AlertPage userData={userData} />} />
                <Route
                  path="/alerts/showInvalidDocument/:id"
                  element={<AlertInvalidDoc userData={userData} />}
                />
                <Route
                  path="/alerts/showHighTemperature/:id"
                  element={<AlertHighTemp userData={userData} />}
                />
                {userData.user.role_id === 6 && <Route
                  path="/alerts/changeDocument/:id"
                  element={<AlertDocumentForm userData={userData} />}
                />}
                <Route
                  path="/documents/create/teste"
                  element={<DocumentCreateFormDemo userData={userData} />}
                />
                <Route
                  path="/inquiry/response/teste"
                  element={<InquiryAnswerDemo userData={userData} />}
                />
                <Route
                  path="/alert/highTemp/teste"
                  element={<AlertHighTempDemo userData={userData} />}
                />
                <Route path="/documents" element={<DocumentsPage userData={userData} />} />
                <Route path="/inquiries" element={<Inquiries userData={userData} />} />
                <Route path="/inquiries/:id" element={<InquiryAnswer userData={userData} />} />
                <Route path="/entrances" element={<Entrances userData={userData} />} />
                <Route path="/news" element={<News userData={userData} />} />
                <Route path="/news/:id" element={<NewsShow userData={userData} />} />
                <Route path="/my_account" element={<Profile userData={userData} />} />
                <Route path="/changePassword" element={<ChangePassword userData={userData} />} />
                <Route
                  path="/account/delete_info"
                  element={<DeleteInfoPage userData={userData} />}
                />
                <Route path="/schedule" element={<Schedules userData={userData} />} />
                {/* <Route path="/chat" element={<Chat userData={userData} />} /> */}
                <Route path="/medical_profile" element={<MedicalProfile userData={userData} />} />
                <Route path="/student_acount" element={<StudentProfile userData={userData} />} />
                <Route path="/policy" element={<Policy />} />
                <Route path="*" element={<DashboardLandingPage userData={userData} />} />
              </Routes>
            </Dashboard>
          )}
        </>
      ) : (
        <>
          {loaded && !dontAnimate ? (
            <SplashScreen className={animated ? "fade-out-bck" : ""} />
          ) : null}
          {startRendering && (
            <div style={!animated ? { display: "none", height: 0 } : {}}>
              <Routes>
                <Route path="/event" element={<EventPage />} />
                <Route path="/" element={<HomePage noNavBar={passwordRecovery} />} />
                <Route path="/policy" element={<Policy />} />
              </Routes>
              <span
                id="scroll-top"
                className={scrollY > 20 ? "appear" : ""}
                onClick={() => {
                  scrollToTop();
                }}
              >
                <ScrollTop />
              </span>
            </div>
          )}
        </>
      )}

      {window.localStorage.getItem("auth") && userData === null && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: '95vh'
          }}
        >
          <CircularProgress size={80} />
        </div>
      )}

      {window.location.pathname !== "/select_child" && !passwordRecovery && <Footer />}
      {passwordRecovery && <RecoverPassword authToken={authToken} />}
    </>
  );
}

export default App;
