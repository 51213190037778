/** @format */

import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import InquiryQuestionMultiple from "./InquiryQuestionMultiple";
import InquiryQuestionCheckBox from "./InquiryQuestionCheckBox";
import InquiryQuestionEmotions from "./InquiryQuestionEmotions";
import InquiryQuestionText from "./InquiryQuestionText";
import SAButton from "../dashboard/overall/SA_Button";
import { ReactComponent as Arrow } from "../../img/arrow.svg";
import axiosConfig from "../../providers/axiosConfig";
import styled from "styled-components";
import SAPageTopBar from "../dashboard/overall/SA_Pagetopbar";
import Tutorial from "../Tutorial/tutorial";
import { Alert, Snackbar } from "@mui/material";
import SAIcon from "../dashboard/overall/Icon";
import PopUp from "../Documents/PopUp";

const TitleCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(37, 49, 151, 0.05);
  border-radius: 20px;
  margin-top: 30px;
  padding-top: 2px;
  padding-bottom: 5px;
  height: fit-content;
`;

const Title = styled.p`
  margin-top: 10px;
  margin-left: 30px;
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  color: #4566af;
`;

const Description = styled.p`
  margin-left: 30px;
  margin-top: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #4566af;
`;

function InquiryAnswer({ userData }) {
  const [axiosError, setAxiosError] = useState(null);

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 899px)" });
  const studentId = localStorage.getItem("student");
  sessionStorage.setItem("route", "inquiryResponse");
  useEffect(() => {
    if (isTabletOrMobile) {
    }
  }, [isTabletOrMobile]);
  const [inquiryData, setInquiryData] = useState();
  const [openPopUp, setOpenPop] = useState(false);
  const [tutorial, setTutorial] = useState(false);

  let pathname = window.location.pathname;
  let id = pathname.replace("/inquiries/", "");

  useEffect(() => {
    let link = "me/inquiries/" + id;
    axiosConfig
      .get(link, {
        params: { studentId: studentId },
      })
      .then((response) => {
        setInquiryData(response.data);
      });
  }, [id, userData, studentId]);

  async function SendPostDataRequest(event) {
    event.preventDefault();

    let data = [];
    data.push({ user_id: userData.user.id });
    const formData = new FormData(document.querySelector("form"));
    for (var pair of formData.entries()) {
      let questionId = pair[0];
      let value = pair[1];
      data.push({ question_title: questionId, answer: value });
    }
    let link = "/inquiries/answer/" + inquiryData.id;

    await axiosConfig
      .post(link, data)
      .then((res) => setOpenPop(true))
      .catch((err) => setAxiosError(err.message));
  }
  if (inquiryData) {
    return (
      <>
        {openPopUp === true && tutorial === false && (
          <PopUp
            logo={<SAIcon icon={"questionaire"} color={"#EDB84F"} size={100} />}
            titleColor={"#4566AF"}
            title={`A sua resposta foi submetida`}
            path={-1}
            backgroundColor={"rgba(0, 0, 0, 0.3)"}
            setState={setOpenPop}
          />
        )}
        <Snackbar
          open={axiosError}
          onClose={() => {
            setAxiosError(null);
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Alert severity="error">{axiosError}</Alert>
        </Snackbar>
        {userData.user.created_at === userData.user.updated_at ? (
          <div>
            <Tutorial userData={userData} setTutorial={setTutorial} continuos={true} />
          </div>
        ) : (
          <div>
            <Tutorial
              userData={userData}
              setTutorial={setTutorial}
              continuos={false}
              oneTime={true}
            />
          </div>
        )}

        <SAPageTopBar
          type={"inquerito"}
          backbutton={true}
          path={"/inquiries"}
          title={"Inquéritos"}
        />

        {tutorial && (
          <div id="inquiryResponse">
            <TitleCard>
              <Title>{"Título do inquérito"}</Title>
              <Description>{"Descrição do inquérito"}</Description>
            </TitleCard>
            <InquiryQuestionMultiple
              id={1}
              title={"Título do inquérito"}
              choices={[{ choice: "Escolha A" }, { choice: "Escolha B" }]}
              number={""}
            />
          </div>
        )}

        {!tutorial && (
          <div>
            <TitleCard>
              <Title>{inquiryData.title}</Title>
              <Description>{inquiryData.description}</Description>
            </TitleCard>

            <form action="/inquiries" method="get" onSubmit={SendPostDataRequest}>
              <div style={{ marginBottom: "10px" }}>
                {inquiryData.questions.map((question, index) => {
                  if (question.type === "multiple") {
                    return (
                      <InquiryQuestionMultiple
                        key={index}
                        id={question.id}
                        title={question.title}
                        choices={question.choices}
                        number={inquiryData.questions.indexOf(question)}
                      />
                    );
                  } else if (question.type === "checkbox") {
                    return (
                      <InquiryQuestionCheckBox
                        key={index}
                        id={question.id}
                        title={question.title}
                        choices={question.choices}
                        number={inquiryData.questions.indexOf(question)}
                      />
                    );
                  } else if (question.type === "emotion") {
                    return (
                      <InquiryQuestionEmotions
                        key={index}
                        id={question.id}
                        title={question.title}
                        choices={question.choices}
                        number={inquiryData.questions.indexOf(question)}
                      />
                    );
                  } else {
                    return (
                      <InquiryQuestionText
                        key={index}
                        id={question.id}
                        title={question.title}
                        choices={question.choices}
                        number={inquiryData.questions.indexOf(question)}
                      />
                    );
                  }
                })}
              </div>
              <SAButton
                value={"Submeter inquérito"}
                type="submit"
                endIcon={<Arrow />}
                size="adaptive"
                onclick={false}
                style={["btn-primary"]}
                textcolor="#FFF"
                align={"centered"}
              />
            </form>
          </div>
        )}
      </>
    );
  }
}
export default InquiryAnswer;
