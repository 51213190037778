import styled from "styled-components";

export const CalendarContainer = styled.div`
  min-width: auto;
  margin: auto;
  /* margin-top: 20px;
  padding: 20px; */
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
`;

export const CardWrapper = styled.div`
  background-color: #fff;
  border-radius: 20px;
  width: auto;
  height: auto;
  min-width: 400px;
  min-height: 650px;
  box-shadow: 0px 4px 10px rgba(37, 49, 151, 0.05);
  display: flex;
  justify-content: center;
`;

export const CalendarCellSubject = styled.span`
  display: inline-block;
  font-family: "Nunito";
  font-size: 18px;
  font-weight: 500;
  color: #4566af;
`;

export const CalendarCellProf = styled.span`
  display: inline-block;
  font-family: "Nunito";
  font-size: 16px;
  font-weight: 500;
  color: #757575;
`;

export const CalendarCellClassRoom = styled.span`
  display: inline-block;
  font-family: "Nunito";
  font-size: 14px;
  font-weight: 500;
  color: #757575;
`;

export const CalendarTable = styled.table`
  border-spacing: 0;
  thead {
    tr {
      th {
        color: #4566af;
        width: 120px;
      }
      th:first-child {
        border-right: 1px solid #b0c2ea;
      }
      td {
        color: #4566af;
      }
    }
  }

  tbody {
    th {
      height: 115px;
      color: #4566af;
      border: 1px solid #b0c2ea;
      border-left: 0;
      border-bottom: 0;
    }
    th:first-child {
      border-left: 0;
    }
    td {
      padding-left: 20px;
      align-items: center;
      width: 260px;
      min-width: 100px;
      max-width: 260px;
      color: #4566af;
      border: 1px solid #b0c2ea;
      border-left: 0;
      border-bottom: 0;
    }
    td:last-child {
      border-right: 0;
    }

    /* tr:nth-child(odd) {
      td {
        background-color: #edf1fc;
      }
    } */
  }
`;
