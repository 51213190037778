/** @format */

import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import SAIcon from "../dashboard/overall/Icon";

const MainDiv = styled.div`
  background: #fff;
  box-shadow: 0px 4px 10px rgba(37, 49, 151, 0.05);
  border-radius: 20px;
  padding-top: 2px;
  padding-bottom: 15px;
  padding: 20px 30px 10px 30px;
  box-sizing: border-box;
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #4566af;
`;

const AllEmotionsDiv = styled.div`
  width: 100%;

  align-items: flex-start;
  gap: 40px;
  @media screen and (min-width: 800px) {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
  }
`;

const IndividualEmotionDiv = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
  justify-content: space-between;
  @media screen and (min-width: 800px) {
    display: block;
  }
`;

const WrapperEmotion = styled.div`
  width: 70px;
  height: 70px;
  overflow: hidden;
  max-width: 70px;
  & svg {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  @media screen and (min-width: 800px) {
    width: 100%;
    height: 100%;
  }
`;

const LabelEmotions = styled.p`
  text-align: center;
  font-family: Nunito;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #4566af;
`;

function InquiryQuestionEmotions(props) {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 899px)" });
  const defaultEmotionsFill = ["#7EB1E8", "#7EB1E8", "#7EB1E8", "#7EB1E8", "#7EB1E8"];
  const [emotionsFill, setEmotionsFill] = useState(defaultEmotionsFill);
  const [emotionAnswers, setEmotionAnswers] = useState("");
  useEffect(() => {
    if (isTabletOrMobile) {
    }
  }, [isTabletOrMobile]);

  function addEmotionAnswer(value) {
    setEmotionAnswers(value);
  }

  function changeFill(id, color) {
    let tempEmotionsFill = defaultEmotionsFill;
    tempEmotionsFill[id] = color;
    setEmotionsFill(tempEmotionsFill);
  }

  return (
    <MainDiv>
      <Title>
        {props.number + 1}-{props.title}
      </Title>
      <AllEmotionsDiv>
        {props.choices.map((choice) => {
          switch (choice.choice) {
            case "happy":
              let fillHappy = emotionsFill[0];
              return (
                <IndividualEmotionDiv
                  onClick={() => {
                    addEmotionAnswer("happy");
                    changeFill(0, "#22DCCA");
                  }}
                >
                  <WrapperEmotion>
                    <SAIcon icon={"happy"} color={fillHappy} size={"100%"} />
                  </WrapperEmotion>
                  <LabelEmotions style={{}}>Feliz</LabelEmotions>
                </IndividualEmotionDiv>
              );
            case "frustated":
              let fillFrustated = emotionsFill[1];
              return (
                <IndividualEmotionDiv
                  onClick={() => {
                    addEmotionAnswer("frustated");
                    changeFill(1, "#FF4A4A");
                  }}
                >
                  <WrapperEmotion>
                    <SAIcon icon={"angry"} color={fillFrustated} size={"100%"} />
                  </WrapperEmotion>
                  <LabelEmotions>Frustado</LabelEmotions>
                </IndividualEmotionDiv>
              );
            case "sad":
              let fillSad = emotionsFill[2];
              return (
                <IndividualEmotionDiv
                  onClick={() => {
                    addEmotionAnswer("sad");
                    changeFill(2, "#4566AF");
                  }}
                >
                  <WrapperEmotion>
                    <SAIcon icon={"sad"} color={fillSad} size={"100%"} />
                  </WrapperEmotion>
                  <LabelEmotions>Triste</LabelEmotions>
                </IndividualEmotionDiv>
              );
            case "tired":
              let fillTired = emotionsFill[3];
              return (
                <IndividualEmotionDiv
                  onClick={() => {
                    addEmotionAnswer("tired");
                    changeFill(3, "#9219BC");
                  }}
                >
                  <WrapperEmotion>
                    <SAIcon icon={"tired"} color={fillTired} size={"100%"} />
                  </WrapperEmotion>
                  <LabelEmotions>Cansado</LabelEmotions>
                </IndividualEmotionDiv>
              );
            case "anxious":
              let fillAnxious = emotionsFill[4];
              return (
                <IndividualEmotionDiv
                  onClick={() => {
                    addEmotionAnswer("anxious");
                    changeFill(4, "#C62E92");
                  }}
                >
                  <WrapperEmotion>
                    <SAIcon icon={"anxious"} color={fillAnxious} size={"100%"} />
                  </WrapperEmotion>
                  <LabelEmotions>Ansioso</LabelEmotions>
                </IndividualEmotionDiv>
              );
            default:
              return <></>;
          }
        })}
      </AllEmotionsDiv>
      <input type="hidden" name={props.title} value={emotionAnswers} />
    </MainDiv>
  );
}
export default InquiryQuestionEmotions;
