import React, { useState } from "react";
import InquiryQuestionMultiple from "./InquiryQuestionMultiple";
import styled from "styled-components";
import SAPageTopBar from "../dashboard/overall/SA_Pagetopbar";
import Tutorial from "../Tutorial/tutorial";

const TitleCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(37, 49, 151, 0.05);
  border-radius: 20px;
  margin-top: 30px;
  padding-top: 2px;
  padding-bottom: 5px;
  height: fit-content;
`;

const Title = styled.p`
  margin-top: 10px;
  margin-left: 30px;
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  color: #4566af;
`;

const Description = styled.p`
  margin-left: 30px;
  margin-top: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #4566af;
`;

function InquiryAnswerDemo({ userData }) {
  sessionStorage.setItem("route", "inquiryResponse");

  const [tutorial, setTutorial] = useState(false);

  return (
    <>
      {userData.user.created_at === userData.user.updated_at ? (
        <div>
          <Tutorial
            userData={userData}
            setTutorial={setTutorial}
            continuos={true}
          />
        </div>
      ) : (
        <div>
          <Tutorial
            userData={userData}
            setTutorial={setTutorial}
            continuos={false}
            oneTime={true}
          />
        </div>
      )}

      <SAPageTopBar
        type={"inquerito"}
        backbutton={true}
        path={"/inquiries"}
        title={"Inquéritos"}
      />
      {tutorial && (
        <div id="inquiryResponse">
          <TitleCard>
            <Title>{"Título do inquérito"}</Title>
            <Description>{"Descrição do inquérito"}</Description>
          </TitleCard>
          <InquiryQuestionMultiple
            id={1}
            title={"Título do inquérito"}
            choices={[{ choice: "Escolha A" }, { choice: "Escolha B" }]}
            number={""}
          />
        </div>
      )}
    </>
  );
}
export default InquiryAnswerDemo;
