import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Card = styled.div`
  width: calc((100% / 3) - 20px);
  border-radius: 1.25rem;
  overflow: hidden;
  min-height: 290px;
  max-height: 400px;
  position: relative;
  cursor: pointer;
  box-shadow: 0px 4px 10px rgba(37, 49, 151, 0.05);
  & img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
  }
  &:after {
    content: " ";
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(180deg, rgba(20, 33, 61, 0) 50%, #14213d 100%);
    top: 0;
    left: 0;
  }
  @media (max-width: 1250px) {
    width: 100%;
  }
`;
const Caption = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 1;
  box-sizing: border-box;
  padding: 1.25rem;
  & h3,
  p {
    margin: 0;
    color: #fff;
    overflow-wrap: break-word;
  }
`;
const Label = styled.span`
  background-color: #e243a3;
  border-radius: 8px;
  padding: 0.375rem 1rem;
  box-sizing: border-box;
  position: absolute;
  right: 5%;
  top: 5%;
  color: #fff;
`;

function SA_NewsCard({ id, type, header, text, image }) {
  const navigate = useNavigate();

  function OpenNews(id) {
    navigate("/news/" + id);
    window.location.reload();
  }

  return (
    <Card
      id={id}
      onClick={() => OpenNews(id)}
      style={!type ? { width: "100%" } : {}}
    >
      <img alt="" src={image} />
      <Label
        style={type ? { visibility: "visible" } : { visibility: "hidden" }}
      >
        {type}
      </Label>
      <Caption>
        <h3 style={!type ? { fontSize: "1rem" } : {}}>{header}</h3>
        <p>{text}</p>
      </Caption>
    </Card>
  );
}

export default SA_NewsCard;
