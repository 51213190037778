/** @format */

import AlertTypes from "./AlertTypes";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { CircularProgress } from "@mui/material";
import AlertCovidPopUp from "./AlertCovidPopUp";
import { useState } from "react";

const ContentDiv = styled.table`
  padding-bottom: 15px;

  box-shadow: 0px 4px 10px rgba(37, 49, 151, 0.05);

  border-radius: 20px;
  background: white;
  width: 100%;

  tr td:nth-child(1),
  tr th:nth-child(1) {
    width: 25%;
    text-align: center;
  }

  tr td:nth-child(2),
  tr th:nth-child(2) {
    width: 55%;
    text-align: center;
  }

  tr td:nth-child(3),
  tr th:nth-child(3) {
    width: 10%;
    text-align: center;
  }

  tr td:nth-child(4),
  tr th:nth-child(4) {
    width: 10%;
    text-align: center;
  }

  @media (max-width: 1250px) {
    box-shadow: 0px 4px 10px rgba(37, 49, 151, 0);
    background: transparent;
    margin: 0;

    tr td:nth-child(1),
    tr th:nth-child(1) {
      width: 40%;
      text-align: center;
    }

    tr td:nth-child(2),
    tr th:nth-child(2) {
      width: 0;
      text-align: center;
    }

    tr td:nth-child(3),
    tr th:nth-child(3) {
      width: 30%;
      text-align: center;
    }

    tr td:nth-child(4),
    tr th:nth-child(4) {
      width: 30%;
      text-align: center;
    }
  } ;
`;

const TableHeader = styled.tr`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 30px;
  margin-right: 30px;
  border-bottom: 1px solid #4566af;
  font-family: Nunito;
  font-style: normal;
  font-weight: 700;
  color: #4566af;
  font-size: 12px;
  @media (max-width: 1250px) {
    /* border-bottom: 0px solid #4566af;
        display: none; */
    justify-content: center;
    padding: 0;
    margin: 0;
  } ;
`;

const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: row;
  gap: 5px;
  color: #ff9075;
  @media (max-width: 1250px) {
    margin-top: 0%;
    margin-bottom: 0%;
    flex-direction: column;
  }
`;

const AlertHeader = styled.th`
  text-align: center;
  @media (max-width: 1250px) {
  } ;
`;
const CauseHeader = styled.th`
  text-align: center;
  @media (max-width: 1250px) {
    display: none;
  } ;
`;
const DayHeader = styled.th`
  text-align: center;
  @media (max-width: 1250px) {
  } ;
`;
const HourHeader = styled.th`
  text-align: center;
  @media (max-width: 1250px) {
  } ;
`;
const TableWrapper = styled.tbody`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 500px;
  max-height: 500px;
  overflow-y: scroll;
`;
const TableRow = styled.tr`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 10px;
  font-family: Nunito;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  border-radius: 20px;
  box-shadow: 0px 4px 10px rgba(37, 49, 151, 0.05);
  @media (max-width: 1250px) {
    background: #ffffff;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    padding: 0 0.625rem;
    margin: 0;
    gap: 8px;
  } ;
`;
const ImgRow = styled.td`
  color: #4566af;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  @media (max-width: 1250px) {
    margin-top: 0%;
    margin-bottom: 0%;
  }
`;
const AlertRow = styled.div`
  color: #4566af;
  @media (max-width: 1250px) {
    margin-top: 0%;
    margin-bottom: 0%;
  } ;
`;
const CauseRow = styled.td`
  color: #4566af;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1250px) {
    display: none;
  } ;
`;
const DayRow = styled.td`
  color: #4566af;
  @media (max-width: 1250px) {
    margin-top: 0%;
    margin-bottom: 0%;
  } ;
`;
const HourRow = styled.td`
  color: #4566af;
  @media screen and (max-width: 800px) {
    margin-top: 0%;
    margin-bottom: 0%;
  } ;
`;
// const MediaNameAndDate = styled.div`
//   display: flex;
//   align-items: center;
//   flex-direction: row;
//   justify-content: center;
//   @media screen and (min-width: 800px) {
//     display: none;
//   } ;
// `;

function AlertTable(props) {
  const alertData = props.alertData;
  const navigate = useNavigate();
  const [showCovidAlert, setShowCovidAlert] = useState(null);

  if (alertData) {
    if (alertData?.length > 0) {
      return (
        <ContentDiv id="alert_list">
          {/* Table header */}
          <thead>
            <TableHeader>
              {/* <ImageHeader></ImageHeader> */}
              <AlertHeader>Alerta</AlertHeader>
              <CauseHeader>Descrição</CauseHeader>
              <DayHeader>Dia</DayHeader>
              <HourHeader>Hora</HourHeader>
            </TableHeader>
          </thead>

          {/* Table Content */}

          <TableWrapper>
            {alertData
              .sort((a, b) => {
                if (moment(a.created_at).isBefore(moment(b.created_at))) {
                  return 1;
                }
                return -1;
              })
              .map((data) => {
                return (
                  // Row
                  <TableRow
                    key={data.id}
                    onClick={() => {
                      if (data.alert_type_id === 1) {
                        setShowCovidAlert(true);
                      } else if (data.alert_type_id === 2) {
                        navigate(`/alerts/showHighTemperature/${data.id}`, {
                          state: {
                            info: data,
                          },
                        });
                      } else if (data.alert_type_id === 6) {
                        navigate(`/alerts/showInvalidDocument/${data.id}`, {
                          state: {
                            doc_reports: data.document_reports,
                            alert_id: data.id,
                          },
                        });
                      }
                    }}
                  >
                    <ImgRow>
                      {data &&
                        data.alert_type_id &&
                        (data.alert_type_id === 2 ? (
                          <Logo
                            style={
                              data.temperature > 37.5 ? { color: "#FF4A4A" } : { color: "#FF9075" }
                            }
                          >
                            <div>
                              <AlertTypes
                                color={data.temperature > 37.5 ? "#FF4A4A" : "#FF9075"}
                                data={data.alert_type_id}
                              />
                            </div>
                            <span>{data.temperature}ºC</span>
                          </Logo>
                        ) : (
                          <AlertTypes
                            // color={
                            //     data.alert_type_id === 2
                            //         ? data.temperature > 37.5
                            //             ? '#FF4A4A'
                            //             : '#FF9075'
                            //         : null
                            // }
                            data={data.alert_type_id}
                          />
                        ))}
                      <AlertRow>{data.alert_type.name}</AlertRow>
                    </ImgRow>

                    <CauseRow>
                      {data.description.length >= 100
                        ? data.description.substring(0, 40) + "..."
                        : data.description}
                    </CauseRow>
                    <DayRow>{moment(data.created_at).format("YYYY-MM-DD")}</DayRow>
                    <HourRow>{moment(data.created_at).format("HH:mm")}</HourRow>
                  </TableRow>
                );
              })}
          </TableWrapper>
          {showCovidAlert && <AlertCovidPopUp hide={setShowCovidAlert} />}
        </ContentDiv>
      );
    } else {
      return <p style={{ textAlign: "center" }}>Sem alertas registados até ao momento</p>;
    }
  } else {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress style={{ marginTop: "10%" }} size={80} />
      </div>
    );
  }
}
export default AlertTable;
