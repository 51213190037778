/** @format */

import React, { useEffect, useMemo, useRef, useState } from "react";
import "./main.css";
import LandingPage from "./components/LandingPage";
import FirstRow from "./components/FirstRow";
import SecondRow from "./components/SecondRow";
import ThirdRow from "./components/ThirdRow";
import FourthRow from "./components/FourthRow";
import Partners from "./components/Partners";
import News from "./components/News";
import PilotSchools from "./components/PilotSchools";
import Contacts from "./components/Contacts";
import Funding from "./components/Funding";
import NavBar from "./components/NavBar";
import MobileApp from "./components/MobileApp";

function HomePage({ noNavBar }) {
  function useIsInViewport(ref) {
    const [isIntersecting, setIsIntersecting] = useState(false);

    const observer = useMemo(
      () =>
        new IntersectionObserver(([entry]) =>
          setIsIntersecting(entry.isIntersecting)
        ),
      []
    );

    useEffect(() => {
      observer.observe(ref.current);

      return () => {
        observer.disconnect();
      };
    }, [ref, observer]);

    return isIntersecting;
  }

  const aboutRef = useRef();
  const partnersRef = useRef();
  const newsRef = useRef();
  const pilotSchoolsRef = useRef();
  const contactsRef = useRef();
  const fundingRef = useRef();
  const mobileappRef = useRef();

  const aboutInView = useIsInViewport(aboutRef);
  const partnersInView = useIsInViewport(partnersRef);
  const newsInView = useIsInViewport(newsRef);
  const pilotSchoolInView = useIsInViewport(pilotSchoolsRef);
  const contactsInView = useIsInViewport(contactsRef);
  const fundingInView = useIsInViewport(fundingRef);

  return (
    <div>
      {!noNavBar && (
        <NavBar
          aboutInView={aboutInView}
          partnersInView={partnersInView}
          newsInView={newsInView}
          pilotSchoolInView={pilotSchoolInView}
          contactsInView={contactsInView}
          fundingInView={fundingInView}
        />
      )}
      <LandingPage />
      <div ref={aboutRef}>
        <FirstRow />
        <SecondRow />
        <ThirdRow />
        <FourthRow />
      </div>
      <div ref={mobileappRef}>
        <MobileApp />
      </div>
      <div ref={partnersRef}>
        <Partners />
      </div>
      <div ref={newsRef}>
        <News />
      </div>
      <div ref={pilotSchoolsRef}>
        <PilotSchools />
      </div>
      <div ref={contactsRef}>
        <Contacts />
      </div>
      <div ref={fundingRef}>
        <Funding />
      </div>
    </div>
  );
}

export default HomePage;
