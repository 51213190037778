import styled from "styled-components";

const UpperDiv = styled.div`
  position: absolute;
  top: 0%;
  right: 0%;
  width: 100%;
  height: 100%;
  z-index: 4;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
`;
const ContentDiv = styled.div`
  position: absolute;
  top: 17%;
  right: 30%;
  margin-top: -50px;
  margin-left: -50px;
  width: 800px;
  height: 800px;
  background: white;
  z-index: 3;
  border-radius: 20px;
  justify-content: center;
`;
const EmbedDiv = styled.div`
  justify-content: center;
  text-align: center;
  margin-top: 20px;
`;

function DocumentPopUp(props) {
  return (
    <UpperDiv
      id="window"
      onClick={() => {
        let popUp = document.getElementById("window");
        popUp.style = "display:none";
      }}
    >
      <ContentDiv id="window">
        <EmbedDiv>
          <embed
            src={`${props.file_url}#toolbar=0`}
            height="700px"
            width="700px"
          ></embed>
        </EmbedDiv>
      </ContentDiv>
    </UpperDiv>
  );
}

export default DocumentPopUp;
