import styled from "styled-components";
import { Line } from "react-chartjs-2";
import { Chart, Filler, registerables } from "chart.js";
import { useState } from "react";
import AlertTypes from "./AlertTypes";
import SABackButton from "../../components/dashboard/overall/SA_BackButton";
import Tutorial from "../Tutorial/tutorial";

Chart.register(Filler);
Chart.register(...registerables);

const MainDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
`;
const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  margin-top: 10px;
  border-radius: 20px;
`;
const ContentTextDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #ffffff;
  color: #ff9075;
  padding-right: 50px;
  padding-left: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  max-width: 250px;
  margin-top: 10px;
  border-radius: 20px;
`;
const ChartWrapper = styled.div`
  max-width: 900px;
  max-height: 490px;
  min-width: 500px;
  background: #ffffff;
  border-radius: 20px;
  margin-left: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 400px; */
  &.chart {
    width: 100% !important;
    height: 100% !important;
  }
`;
const Logo = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: #ff9075;
`;
function AlertHighTempDemo(props) {
  const [tutorial, setTutorial] = useState(false);
  sessionStorage.setItem("route", "alertHighTempPage");

  return (
    <div>
      {props.userData.user.created_at === props.userData.user.updated_at ? (
        <div>
          <Tutorial setTutorial={setTutorial} userData={props.userData} continuos={true}  />
        </div>
      ) : (
        <div>
          <Tutorial setTutorial={setTutorial} userData={props.userData} continuos={false} oneTime={true}  />
        </div>
      )}
      {/* <Tutorial setTutorial={setTutorial} userData={props.userData} continuos={true} /> */}
      {/* TEMPERATURE + DATE INFO */}
      <div style={{}}>
        <SABackButton />
      </div>
      {tutorial && (
        <MainDiv>
          <div id="left-panel-high-temperature">
            <ContentDiv>
              <Logo>
                <AlertTypes data={2} />
                39.5ºC
              </Logo>
              <div></div>
              <p>Temperatura Alta Registada</p>
              <p>Dia: 15/4/2022</p>
              <p>Hora: 19:32</p>
            </ContentDiv>
            <ContentTextDiv>
              Deverá ficar isolado até ser confirmado esta temperatura!
            </ContentTextDiv>
          </div>
          {/* Graph */}
          <ChartWrapper>
            <Line
              className="chart"
              options={{
                scales: {
                  y: {
                    grid: {
                      display: false,
                    },
                    ticks: {
                      setpSize: 1,
                    },
                  },
                  x: {
                    grid: {
                      display: false,
                    },
                  },
                },
                plugins: {
                  filler: {
                    propagate: true,
                  },
                  legend: {
                    display: false,
                  },
                },
              }}
              data={{
                labels: [11, 12, 13, 14, 15, 16],
                datasets: [
                  {
                    fill: true,
                    data: [35.6, 36.2, 32.9, 34.3, 39, 38.5],
                    backgroundColor: (context: ScriptableContext<"line">) => {
                      const ctx = context.chart.ctx;
                      const gradient = ctx.createLinearGradient(0, 0, 0, 400);
                      gradient.addColorStop(0, "rgba(255,0,0,0.7)");
                      gradient.addColorStop(1, "rgba(255,255,255,0)");
                      return gradient;
                    },
                    borderColor: "#EC673D",
                    tension: 0.4, //0.2 maybe?
                  },
                ],
              }}
            />
          </ChartWrapper>
        </MainDiv>
      )}
    </div>
  );
}
export default AlertHighTempDemo;
