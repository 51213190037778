import React from 'react';
import { useTranslation } from 'react-i18next';
import image4 from '../img/image4.png';
import circleCross from '../img/circleCross.svg';
import { ReactComponent as BgWave} from '../img/blue_wave.svg';

function FourthRow() {
    const { t } = useTranslation();

    return (
        <section>
            <div className='row'>
                <div className='column'>
                    <img className='imagerow' src={image4} alt=""/>
                </div>
                <div className='column'>
                    <h2 className='row-title'>
                        {t('projeto4.title')}
                        <img className='circleCross' src={circleCross} alt=""/>
                    </h2>
                    <hr />
                    <p>{t('projeto4.txt')}</p>
                </div>
            </div>
            <div className='bg-wave'>
                    <BgWave preserveAspectRatio='none' />
            </div>
        </section>
    );
}

export default FourthRow;
