import styled from "styled-components";
import React from "react";

const MainDiv = styled.div.attrs((props) => ({
  className: props.className,
}))`
  display: flex;
  margin-top: 30px;
  margin-left: 20px;
  width: 85%;
  border-radius: 112px;
  justify-content: space-between;
  align-items: center;
  padding-right: 2%;
  &.entrys {
    border: 1.96349px solid #0dc399;
  }
  &.classes {
    border: 1.96349px solid #b0c2ea;
  }
  & svg {
    opacity: 1;
    height: 30px;
    width: 30px;
  }
  @media screen and (min-width:600px) {
    width: 90%;
    & svg {
      height: 40px;
      width: 40px;
    }
  }
  @media screen and (min-width:800px) {
    width: 92%;
    & svg {
      height: 50px;
      width: 50px;
    }
  }
  @media screen and (min-width: 1700px) {
    width: 95%;
    & svg {
      height: 70px;
      width: 70px;
    }
  }
`;

const InfoDiv = styled.div`
  display: flex;
  margin-left: 20px;
  align-items: center;
`;

const Attendance = styled.p.attrs((props) => ({
  className: props.className,
}))`
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  width: 70px;
  max-width: 40px;
  &.entrys {
    color: #0dc399;
  }
  &.classes {
    color: #b0c2ea;
  }
  &.miss_classes {
    color: #ff4a4a;
  }
`;

const Text = styled.p.attrs((props) => ({
  className: props.className,
}))`
  font-weight: 600;
  font-size: 16px;
  &.entrys {
    color: #0dc399;
  }
  &.classes {
    color: #b0c2ea;
  }
`;

const VerticalLine = styled.div.attrs((props) => ({
  className: props.className,
}))`
  height: 7vh;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 30px;
  margin-right: 30px;
  &.entrys {
    border: 1px solid #0dc399;
    background: #0dc399;
  }
  &.classes {
    border: 1px solid #b0c2ea;
    background: #b0c2ea;
  }
`;

function ListComponent({
  endIcon,
  style,
  textStyle,
  hours,
  minutes,
  title,
  subtitle,
}) {
  const STYLES = ["entrys", "classes"];
  const STYLESTEXT = ["entrys", "miss_classes"];
  const CheckStyle = STYLES.includes(style) ? style : STYLES[0];
  const CheckMiss = STYLESTEXT.includes(textStyle) ? textStyle : STYLESTEXT[0];
  return (
    <>
      <MainDiv className={`${CheckStyle} `}>
        <InfoDiv>
          <Attendance className={`${CheckStyle} ${CheckMiss} `}>
            {hours}{minutes && ":"}{minutes}
          </Attendance>

          <VerticalLine className={`${CheckStyle} `}>
            <></>
          </VerticalLine>
          <div>
            <Text className={`${CheckStyle} `}>{title}</Text>
            <Text className={`${CheckStyle} `}>{subtitle}</Text>
          </div>
        </InfoDiv>
        {endIcon}
      </MainDiv>
    </>
  );
}
export default ListComponent;
