/** @format */

import React, { useEffect } from "react";
import styled from "styled-components";
import SAInput from "./overall/SA_Input";
import { useState } from "react";
import axiosConfig from "../../providers/axiosConfig";
import SABackButton from "./overall/SA_BackButton";
import { Alert, Snackbar, Tooltip } from "@mui/material";
import SAIcon from "./overall/Icon";
import SAButton from "./overall/SA_Button";
import { useNavigate } from "react-router-dom";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 1.25rem;
`;

const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 0.75fr 0.75fr;
  margin-bottom: 1rem;
  gap: 1rem;
`;

const Column = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
`;
const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #fff;
  border-radius: 8px;
  height: 100%;
  box-sizing: border-box;
  padding: 1.25rem;
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: 28px;
`;

const RadioRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
`;

const InputWrap = styled.div`
  display: flex;
  gap: 0.625rem;
  min-width: 64px;
  font-size: 1.125rem;
  color: #fff;
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  border: 1px solid #4566af80;
  border-radius: 8px;
  width: 100%;
  & textarea {
    outline: 0;
    border: 0;
    color: #b0c2ea;
    font-size: 1rem;
    resize: none;
    width: 100%;
    &::placeholder {
      color: #b0c2ea;
    }
  }
`;

function DeleteInfoPage({ userData }) {
  const [axiosError, setAxiosError] = useState({});
  const { user } = userData;
  const [info, setInfo] = useState([]);
  const navigate = useNavigate();

  async function handleForm(event) {
    event.preventDefault();
    let message = document.getElementById("message").value;
    let fullName = document.getElementById("completeName").value;
    let email = document.getElementById("email").value;
    let contact = document.getElementById("personalContact").value;
    let radio = document.getElementsByName("language");
    let radioSelected = "";
    for (let i = 0; i < radio.length; i++) {
      if (radio[i].checked) {
        radioSelected = radio[i].value;
        break;
      }
    }

    let dataForm = new FormData();

    dataForm.append("fullName", fullName);
    dataForm.append("message", message);
    dataForm.append("email", email);
    dataForm.append("contact", contact);
    dataForm.append("type", radioSelected);

    axiosConfig
      .post("/privacy_requests/", dataForm)
      .then((res) => {
        setAxiosError({ message: "Mensagem enviada com sucesso.", severity: "success" });
        navigate("/");
      })
      .catch((err) => setAxiosError({ message: err.message, severity: "error" }));
  }

  useEffect(() => {
    axiosConfig.get("/me/info").then((res) => {
      setInfo(res.data);
    });
  }, [user]);

  return (
    <>
      {info && info.user && (
        <>
          <Snackbar
            open={axiosError.message}
            onClose={() => {
              setAxiosError({});
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <Alert severity={axiosError.severity}>{axiosError.message}</Alert>
          </Snackbar>
          <SABackButton />
          <Main>
            <Title>Direito de privacidade de dados</Title>
            <form onSubmit={handleForm}>
              <Grid>
                <Card>
                  <Card>
                    <h3>Dados Pessoais</h3>
                    <SAInput
                      label={"Nome"}
                      id={"completeName"}
                      placeholder={"Escreva o seu nome completo"}
                      type={"text"}
                      required={true}
                    />
                    <SAInput
                      label={"Email"}
                      id="email"
                      placeholder={"Escreva o seu email de contacto"}
                      type={"text"}
                      required={true}
                    />
                    <SAInput
                      label={"Contacto Pessoal"}
                      id="personalContact"
                      placeholder={"Digite o seu número de contacto Pessoal"}
                      type={"text"}
                      required={true}
                    />

                    <h4>Assunto</h4>

                    <div style={{ width: "fit-content" }}>
                      <RadioRow>
                        <p>
                          <input type={"radio"} value="information_right" name="language" /> Direito
                          de Informação
                        </p>
                        <Tooltip
                          arrow
                          title="O titular dos dados tem direito a receber informações sobre os termos de tratamento dos dados aquando a recolha dos mesmos, sendo que, poderá apresentar um pedido de informação."
                          placement="top-end"
                          enterTouchDelay={0}
                        >
                          <span>
                            <SAIcon icon={"help"} color={"#B0C2EA"} size={20} />
                          </span>
                        </Tooltip>
                      </RadioRow>
                      <RadioRow>
                        <p>
                          <input type={"radio"} value="access_right" name="language" /> Direito de
                          Acesso
                        </p>
                        <Tooltip
                          arrow
                          title="O titular dos dados tem direito a aceder aos dados pessoais e toda a informação relativa ao tratamento, destinatários, prazo de armazenamento, entre outras."
                          placement="top-end"
                          enterTouchDelay={0}
                        >
                          <span>
                            <SAIcon icon={"help"} color={"#B0C2EA"} size={20} />
                          </span>
                        </Tooltip>
                      </RadioRow>
                      <RadioRow>
                        <p>
                          <input type={"radio"} value="rectification_right" name="language" />{" "}
                          Direito de Retificação
                        </p>
                        <Tooltip
                          arrow
                          title="O titular dos dados tem direito a obter retificação dos dados inexatos ou incompletos."
                          placement="top-end"
                          enterTouchDelay={0}
                        >
                          <span>
                            <SAIcon icon={"help"} color={"#B0C2EA"} size={20} />
                          </span>
                        </Tooltip>
                      </RadioRow>
                      <RadioRow>
                        <p>
                          <input type={"radio"} value="opposition_right" name="language" /> Direito
                          de Oposição
                        </p>
                        <Tooltip
                          arrow
                          title="O titular dos dados tem direito a se opor ao tratamento dos dados em qualquer momento por motivos relacionados com a situação particular."
                          placement="top-end"
                          enterTouchDelay={0}
                        >
                          <span>
                            <SAIcon icon={"help"} color={"#B0C2EA"} size={20} />
                          </span>
                        </Tooltip>
                      </RadioRow>
                      <RadioRow>
                        <p>
                          <input type={"radio"} value="elimination_right" name="language" /> Direito
                          de Eliminação
                        </p>
                        <Tooltip
                          arrow
                          title="O titular dos dados tem direito a solicitar a eliminação dos dados pessoais, sem demora injustificada, excepto se existir fundamentação jurídica contrária."
                          placement="top-end"
                          enterTouchDelay={0}
                        >
                          <span>
                            <SAIcon icon={"help"} color={"#B0C2EA"} size={20} />
                          </span>
                        </Tooltip>
                      </RadioRow>
                      <RadioRow>
                        <p>
                          <input type={"radio"} value="limitation_right" name="language" /> Direito
                          de Limitação
                        </p>
                        <Tooltip
                          arrow
                          title="O titular dos dados tem direito de limitar o tratamento dos dados ou a portabilidade dos dados, caso se aplique uma das condições previstas no RGPD. Para o efeito, deverá submeter um pedido para os contactos abaixo referidos."
                          placement="top-end"
                          enterTouchDelay={0}
                        >
                          <span>
                            <SAIcon icon={"help"} color={"#B0C2EA"} size={20} />
                          </span>
                        </Tooltip>
                      </RadioRow>
                    </div>
                  </Card>
                </Card>
                <Column>
                  <Card>
                    <div>
                      <h3>Escreva aqui a sua mensagem</h3>
                      <InputWrap>
                        <textarea id="message" name="message" rows="25" cols="50" required />
                      </InputWrap>
                    </div>
                  </Card>
                  <div>
                    <SAButton
                      type={"submit"}
                      value={"Enviar mensagem"}
                      style={["btn-login"]}
                      size={"medium"}
                      textcolor={"#fff"}
                      align={"right"}
                    />
                  </div>
                </Column>
              </Grid>
            </form>
          </Main>
        </>
      )}
    </>
  );
}

export default DeleteInfoPage;
