import React from 'react';
import { useTranslation } from 'react-i18next';
import circleCross from '../img/circleCross.svg';
import SchoolCard from './SchoolCard';
import escolaEduardo from '../img/escola-eduardo.jpg';
import escolaAlfredo from '../img/escola-alfredo.jpg';
import escolaLouros from '../img/escola-louros.jpg';
import escolaEstreito from '../img/escola-estreito.jpg';
import { ReactComponent as BgWave} from '../img/blue_wave.svg';

function PilotSchools() {
    const { t } = useTranslation();
    return (
        <section id='schools'>
            <div className='row section-header'>
                <div
                    className='column'
                    style={{ maxWidth: '100%', flexGrow: 1, textAlign: 'center' }}
                >
                    <img src={circleCross} alt=""/>
                    <h2 className='row-title-center'>{t('escolaspiloto.title')}</h2>
                    <p>{t('escolaspiloto.txt')}</p>
                </div>
            </div>
            <div className='row' style={{ textAlign: 'center', alignItems: 'stretch' }}>
                <div className='column flex school-column'>
                    <SchoolCard
                        image={escolaEstreito}
                        title={t('pilot.name1')}
                        description={t('pilot.descrip1')}
                    />
                </div>
                <div className='column flex school-column'>
                    <SchoolCard
                        image={escolaLouros}
                        title={t('pilot.name2')}
                        description={t('pilot.descrip2')}
                    />
                </div>
                <div className='column flex school-column'>
                    <SchoolCard
                        image={escolaAlfredo}
                        title={t('pilot.name3')}
                        description={t('pilot.descrip3')}
                    />
                </div>
                <div className='column flex school-column'>
                    <SchoolCard
                        image={escolaEduardo}
                        title={t('pilot.name4')}
                        description={t('pilot.descrip4')}
                    />
                </div>
                
            </div>
            <div className='bg-wave'>
                    <BgWave preserveAspectRatio='none' />
            </div>
        </section>
    );
}

export default PilotSchools;
