/** @format */
import styled from "styled-components";
import { useEffect } from "react";
import axiosConfig from "../../providers/axiosConfig";
import { useState } from "react";
import DocumentAlerts from "./DocumentAlerts";
import SAIcon from "../dashboard/overall/Icon";

const UpperDiv = styled.div`
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  margin-bottom: 20px;
  justify-content: center;
  align-content: center;
  margin-right: 10px;
  margin-left: 10px;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;
const ContentDiv = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  background: #ffffff;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 1px 20px;
  box-shadow: 0px 4px 10px rgba(37, 49, 151, 0.05);
  width: 20%;
  min-width: 150px;
  text-align: center;
`;

function DocumentTypes({ user, docFilter, setDocFilter, child }) {
  const [information, setInformation] = useState([]);
  let userInfo = user.user;
  const changeFilter = (filter) => {
    let aux = docFilter;
    if (aux.includes(filter)) {
      aux = aux.replace(filter, "");
    } else {
      aux += filter;
    }
    setDocFilter(aux);
  };

  useEffect(() => {
    axiosConfig
      .get(`me/document_alerts/${userInfo.id}`, {
        params: {
          studentId: localStorage.getItem("student"),
        },
      })
      .then((res) => {
        setInformation(res.data.data); //[{0:{alertId ; docID ; docReportID ; docTypeID}}]
      });
  }, [userInfo]);

  return (
    <UpperDiv>
      {/* DOCUMENT TYPE */}
      <ContentDiv
        id="document-type"
        onClick={() => {
          changeFilter("2");
        }}
      >
        <SAIcon
          icon={"test"}
          size={80}
          color={docFilter.includes("2") ? "#5F80F7" : "rgba(194, 195, 199, 1)"}
        />
        <p
          style={{
            color: docFilter.includes("2") ? "#5F80F7" : "rgba(194, 195, 199, 1)",
          }}
        >
          Certificado de teste
        </p>
      </ContentDiv>
      {/* DOCUMENT TYPE */}
      <ContentDiv
        onClick={() => {
          changeFilter("1");
        }}
      >
        <SAIcon
          icon={"vaccine"}
          size={70}
          color={docFilter.includes("1") ? "#5F80F7" : "rgba(194, 195, 199, 1)"}
        />
        <p
          style={{
            color: docFilter.includes("1") ? "#5F80F7" : "rgba(194, 195, 199, 1)",
          }}
        >
          Certificado de vacinação
        </p>
      </ContentDiv>
      {/* DOCUMENT TYPE */}
      <ContentDiv
        onClick={() => {
          changeFilter("4");
        }}
      >
        <SAIcon
          icon={"documents-recovery"}
          size={70}
          color={docFilter.includes("4") ? "#5F80F7" : "rgba(194, 195, 199, 1)"}
        />
        <p
          style={{
            color: docFilter.includes("4") ? "#5F80F7" : "rgba(194, 195, 199, 1)",
          }}
        >
          Certificado de recuperação
        </p>
      </ContentDiv>
      {/* DOCUMENT TYPE */}
      <ContentDiv
        onClick={() => {
          changeFilter("3");
        }}
      >
        <SAIcon
          icon={"documents-medic"}
          size={70}
          color={docFilter.includes("3") ? "#5F80F7" : "rgba(194, 195, 199, 1)"}
        />
        <p
          style={{
            color: docFilter.includes("3") ? "#5F80F7" : "rgba(194, 195, 199, 1)",
          }}
        >
          Atestado médico
        </p>
      </ContentDiv>

      {information?.length > 0 && <DocumentAlerts info={information} user={userInfo} />}
    </UpperDiv>
  );
}

export default DocumentTypes;
