import React from "react";
import { useTranslation } from "react-i18next";
import circleCross from "../img/circleCross.svg";
import event1 from "../img/event1.png";
import event2 from "../img/event2.png";
import event3 from "../img/event3.jpg";
import event4 from "../img/event4.jpg";
import NewsCard from "./NewsCard";

function News() {
  const { t } = useTranslation();

  return (
    <section id="news">
      <div className="row section-header">
        <div
          className="column"
          style={{ maxWidth: "100%", flexGrow: 1, textAlign: "center" }}
        >
          <img src={circleCross} alt="" />
          <h2 className="row-title-center">{t("navbar3")}</h2>
        </div>
      </div>
      <div
        className="row"
        style={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflowX: "auto",
          overflowY: "hidden",
          flexWrap: "nowrap",
        }}
      >
        <div className="column" style={{ width: "30rem" }}>
          <NewsCard
            title={t("events.event3.title")}
            image={event3}
            description={t("events.event3.descript")}
            subtitle={t("events.event3.subtitle")}
            date={t("events.event3.date")}
            eventNum={3}
          />
        </div>
        <div className="column" style={{ width: "30rem" }}>
          <NewsCard
            title={t("events.event4.title")}
            image={event4}
            description={t("events.event4.descript")}
            subtitle={t("events.event4.subtitle")}
            date={t("events.event4.date")}
            eventNum={4}
          />
        </div>
        <div className="column" style={{ width: "30rem" }}>
          <NewsCard
            title={t("events.event2.title")}
            image={event2}
            description={t("events.event2.descript")}
            subtitle={t("events.event2.subtitle")}
            date={t("events.event2.date")}
            eventNum={2}
          />
        </div>
        <div className="column" style={{ width: "30rem" }}>
          <NewsCard
            title={t("events.event1.title")}
            image={event1}
            description={t("events.event1.descript")}
            subtitle={t("events.event1.subtitle")}
            date={t("events.event1.date")}
            eventNum={1}
          />
        </div>
      </div>
    </section>
  );
}

export default News;
