import React from 'react'
import { ReactComponent as SplashImage} from '../img/logo_animation.svg'

function SplashScreen(props) {

  return (
    <div className={props.className + ' splash-screen'}><div className='bg' /><SplashImage /></div>
  )
}

export default SplashScreen