import React from "react";
import { useTranslation } from "react-i18next";
import circleCross from "../img/circleCross.svg";
import funding from "../img/funding.png";
import FichaTecnica from "../assets/files/Ficha_Projeto_SeeApp.pdf";

function TechnicalSheet() {
  const { t } = useTranslation();

  return (
    <section id="funding">
      <div className="row section-header">
        <div
          className="column"
          style={{ maxWidth: "100%", flexGrow: 1, textAlign: "center" }}
        >
          <img src={circleCross} alt="" />
          <h2 className="row-title-center">{t("ficha.title")}</h2>
        </div>
      </div>
      <div className="row">
        <div className="list">
          <ul>
            <li>{t("ficha.title")}</li>
            <li>{t("ficha.ptn1")}</li>
            <li>{t("ficha.ptn2")}</li>
            <li>{t("ficha.ptn3")}</li>
            <li>{t("ficha.ptn4")}</li>
            <li>{t("ficha.ptn5")}</li>
            <li>{t("ficha.ptn6")}</li>
            <li>{t("ficha.ptn7")}</li>
            <li>{t("ficha.ptn8")}</li>
            <li>{t("ficha.ptn9")}</li>
          </ul>
          <p style={{ margin: "0 20px", fontWeight: "600", fontSize: "18px" }}>
            {t("ficha.consult")}{" "}
            <a
              href={FichaTecnica}
              rel="noopener noreferrer"
              target={"_blank"}
              style={{
                textDecoration: "underline",
                cursor: "pointer",
                color: "inherit",
              }}
            >
              {t("ficha.consult2")}
            </a>
          </p>

          <p>{t("ficha.txt")}</p>
          <img src={funding} alt="" />
        </div>
      </div>
    </section>
  );
}

export default TechnicalSheet;
