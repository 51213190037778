/** @format */
import SAButton from "../dashboard/overall/SA_Button";
import SABackButton from "../dashboard/overall/SA_BackButton";
import { useState } from "react";
import axiosConfig from "../../providers/axiosConfig";
import styled from "styled-components";
import SAIcon from "../dashboard/overall/Icon";
import PopUp from "./PopUp";
import DocumentType from "./DocumentType";
import Tutorial from "../Tutorial/tutorial";
import Uploader from "./Uploader";
import { Alert, Snackbar } from "@mui/material";

const HeaderDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  max-height: 30px;
  height: 30px;
  gap: 40px;

  width: 90%;
  margin: auto;

  @media (max-width: 1250px) {
    flex-direction: column;
  }
`;
const FileInputDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`;
const HeaderTitle = styled.p`
  color: #4566af;
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  margin: 0;
`;
const ContentDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 40px;
  width: 90%;
  margin: auto;

  @media (max-width: 1250px) {
    flex-direction: column;
  }
`;
const DocumentTypesDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const DocumentTypesTitle = styled.p`
  color: #5f80f7;
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  margin-bottom: 0;
`;
const DocumentTypesPair = styled.div`
  display: flex;
  gap: 20px; //GAP BETWEEN TYPES (HORIZONTAL)
  flex-grow: 1;
  max-height: 150px;
`;
const DocumentTypeDiv = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  width: 50%;
  padding: 10px;
  background: #ffffff;
  border-radius: 20px;
  cursor: pointer;
  text-align: center;
  box-shadow: 0px 4px 10px rgba(37, 49, 151, 0.05);
  min-width: 150px;
`;
const FormBtntDiv = styled.div`
  width: 90%;
  margin: auto;
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 1250px) {
    justify-content: center;
  }
`;

const MainDiv = styled.div`
  max-width: 1500px;
  margin: auto;
`;

const FileInputTitle = styled.p`
  color: #5f80f7;
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  @media (max-width: 1250px) {
    margin-top: 0;
  }
`;

function DocumentCreate(props) {
  const [optionTypeDoc, setOptionTypeDoc] = useState("");
  const [openPopUp, setOpenPop] = useState(false);
  const [infoToApi, setInfoToApi] = useState({});
  const [tutorial, setTutorial] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [file, setFile] = useState(null);
  sessionStorage.setItem("route", "documentsForm");

  async function HandleForm(event) {
    event.preventDefault();
    const docType = infoToApi.doc_type_id;
    if (file && docType) {
      let url = window.location.pathname;
      let userId = url.replace("/document_create/", "");

      let formData = new FormData();
      formData.append("file", file);
      formData.append("user_id", parseInt(userId));
      formData.append("document_type_id", infoToApi.doc_type_id);

      axiosConfig
        .post("/me/documents?studentId=" + localStorage.getItem("student"), formData)
        .then((response) => {
          setOpenPop(true);
        })
        .catch((err) => {
          setUploadError(err.message);
        });
    }
  }

  return (
    <>
      <SABackButton />
      <MainDiv>
        <Snackbar
          open={uploadError}
          onClose={() => {
            setUploadError(null);
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Alert severity="error">{uploadError}</Alert>
        </Snackbar>
        {/* Header */}
        <div>
          {props.userData.user.created_at === props.userData.user.updated_at ? (
            <div>
              <Tutorial userData={props.userData} setTutorial={setTutorial} continuos={true} />
            </div>
          ) : (
            <div>
              <Tutorial
                userData={props.userData}
                setTutorial={setTutorial}
                continuos={false}
                oneTime={true}
              />
            </div>
          )}
        </div>
        <div>
          {openPopUp === true && tutorial === false && (
            <PopUp
              logo={
                <DocumentType
                  doc_type_id={infoToApi.doc_type_id}
                  hasName={false}
                  logoSize={"100px"}
                  isAlert={false}
                />
              }
              titleColor={"#4566AF"}
              title={`O seu documento foi submetido.`}
              path={"/documents"}
              backgroundColor={"rgba(0, 0, 0, 0.3)"}
              setState={setOpenPop}
            />
          )}
          <HeaderDiv>
            <HeaderTitle>Adicionar Documento</HeaderTitle>
          </HeaderDiv>
          <ContentDiv>
            {/* DOCUMENT TYPES */}
            <DocumentTypesDiv id="document-types-form">
              <DocumentTypesTitle>1. Selecione o tipo de documento</DocumentTypesTitle>

              {/* PAIR OF DOC TYPES */}
              <DocumentTypesPair>
                <DocumentTypeDiv
                  onClick={() => {
                    setOptionTypeDoc("opt1");
                    setInfoToApi({
                      ...infoToApi,
                      doc_type_id: 1,
                    });
                  }}
                >
                  <SAIcon
                    size="40px"
                    icon="vaccine"
                    color={optionTypeDoc === "opt1" ? "blue" : "rgba(194, 195, 199, 1)"}
                  />

                  <span
                    style={{
                      paddingBottom: "10px",
                      paddingTop: "10px",
                      color: optionTypeDoc === "opt1" ? "blue" : "rgba(194, 195, 199, 1)",
                    }}
                  >
                    Certificado de Vacinação
                  </span>
                </DocumentTypeDiv>
                <DocumentTypeDiv
                  onClick={() => {
                    setOptionTypeDoc("opt2");
                    setInfoToApi({
                      ...infoToApi,
                      doc_type_id: 2,
                    });
                  }}
                >
                  <SAIcon
                    size="45px"
                    icon="test"
                    color={optionTypeDoc === "opt2" ? "blue" : "rgba(194, 195, 199, 1)"}
                  />

                  <span
                    style={{
                      paddingBottom: "10px",
                      paddingTop: "10px",
                      color: optionTypeDoc === "opt2" ? "blue" : "rgba(194, 195, 199, 1)",
                    }}
                  >
                    Certificado de Teste
                  </span>
                </DocumentTypeDiv>
              </DocumentTypesPair>

              {/* PAIR OF DOC TYPES */}
              <DocumentTypesPair>
                <DocumentTypeDiv
                  onClick={() => {
                    setOptionTypeDoc("opt3");
                    setInfoToApi({
                      ...infoToApi,
                      doc_type_id: 3,
                    });
                  }}
                >
                  <SAIcon
                    size="45px"
                    icon="documents-medic"
                    color={optionTypeDoc === "opt3" ? "blue" : "rgba(194, 195, 199, 1)"}
                  />

                  <span
                    style={{
                      paddingBottom: "10px",
                      paddingTop: "10px",
                      color: optionTypeDoc === "opt3" ? "blue" : "rgba(194, 195, 199, 1)",
                    }}
                  >
                    Atestado médico
                  </span>
                </DocumentTypeDiv>
                <DocumentTypeDiv
                  onClick={() => {
                    setOptionTypeDoc("opt4");
                    setInfoToApi({
                      ...infoToApi,
                      doc_type_id: 4,
                    });
                  }}
                >
                  <SAIcon
                    size="45px"
                    icon="documents-recovery"
                    color={optionTypeDoc === "opt4" ? "blue" : "rgba(194, 195, 199, 1)"}
                  />

                  <span
                    style={{
                      paddingBottom: "10px",
                      paddingTop: "10px",
                      color: optionTypeDoc === "opt4" ? "blue" : "rgba(194, 195, 199, 1)",
                    }}
                  >
                    Certificado de recuperação
                  </span>
                </DocumentTypeDiv>
              </DocumentTypesPair>
            </DocumentTypesDiv>

            {/* FILE INPUT */}
            <FileInputDiv>
              <FileInputTitle>2. Arraste ou carregue aqui o seu documento</FileInputTitle>
              <Uploader setFile={setFile} />
            </FileInputDiv>
          </ContentDiv>
          <FormBtntDiv>
            <SAButton
              value="Submeter Documento"
              size="large"
              type={"submit"}
              textcolor="#FFFFFF"
              onclick={HandleForm}
            />
          </FormBtntDiv>
        </div>
      </MainDiv>
    </>
  );
}

export default DocumentCreate;
