/** @format */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const CardWrap = styled.div.attrs((props) => ({
    className: props.className,
}))`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 30px;
    box-shadow: 0px 5.88676px 14.7169px rgba(37, 49, 151, 0.05);
    background-color: #fff;
    box-sizing: border-box;
    padding: 1rem;
    font-weight: 500;
    text-align: center;
    flex: 1;
    cursor: pointer;
    & p {
        font-size: 1.125rem;
        color: ${({ theme }) => theme.textcolor};
    }
`;
const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
`;
const ImageWrap = styled.div`
    width: 80px;
    height: 80px;

    & svg {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
`;
function SA_SmallCard({ icon, header, onclick, type, style, alert }) {
    const [textcolor, SetColor] = useState('');
    const theme = {
        textcolor: textcolor,
    };

    useEffect(() => {
        switch (type) {
            case 'alerta':
                SetColor('#092F83');
                break;
            case 'documento':
                SetColor('#5F80F7');
                break;
            default:
                break;
        }
    }, [type]);

    return (
        <CardWrap onClick={onclick} type={type} theme={theme} style={style}>
            <Row>
                <ImageWrap>{icon}</ImageWrap>
                {alert?.alert_type_id === 2 && (
                    <span
                        style={
                            alert.temperature > 37.5 ? { color: '#FF4A4A' } : { color: '#FF9075' }
                        }
                    >
                        {alert.temperature}ºC
                    </span>
                )}
            </Row>
            <p>{header}</p>
        </CardWrap>
    );
}

export default SA_SmallCard;
