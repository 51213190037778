/** @format */

import { useEffect, useState } from "react";
import DocumentPopUp from "../Documents/DocumentPopUp";
import DocumentShowCard from "../Documents/DocumentShowCard";
import SABackButton from "../dashboard/overall/SA_BackButton";
import DocumentViewer from "../Documents/DocumentViewer";
import axiosConfig from "../../providers/axiosConfig";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import SAButton from "../dashboard/overall/SA_Button";
import { useNavigate } from "react-router-dom";

const MainDiv = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
  align-content: center;
`;
// const ButtonDiv = styled.div`
//   width: 100%;
//   text-align: center;
//   border-radius: 20px;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   color: white;
//   cursor: pointer;
//   gap: 10px;
// `;

function AlertInvalidDoc(props) {
  const userInfo = props.userData.user;
  const location = useLocation(); //Document Report Resource
  const documentReport = location.state?.doc_reports;
  const alert_id = location.state?.alert_id;
  const navigate = useNavigate();
  const [docInfo, setDocInfo] = useState();
  let documentId = location.state?.doc_reports.document_id;
  useEffect(() => {
    axiosConfig.get(`/documents/${documentId}`).then((res) => {
      setDocInfo(res.data);
    });
  }, [documentId]);

  return (
    <>
      {documentReport && docInfo && (
        <>
          <SABackButton />
          <MainDiv>
            {/* Info Card */}
            <DocumentShowCard
              documentInfo={docInfo}
              isAlert={true}
              replaceBtn={
                userInfo.role_id === 6 && (
                  <SAButton
                    value="Submeter Novo Documento"
                    size="fullwidth"
                    textcolor="#FFFFFF"
                    onclick={() => {
                      navigate(`/alerts/changeDocument/${alert_id}`, {
                        state: {
                          userId: userInfo.id,
                          doc_type_id: docInfo.document_type_id,
                          doc_id: docInfo.id,
                          alertId: alert_id,
                        },
                      });
                    }}
                  />
                )
              }
            />

            {/* DOCUMENT VIEWER */}
            {docInfo.file_url && <DocumentViewer file_url={docInfo.file_url} />}
          </MainDiv>
          {docInfo.file_url && (
            <div id="window" style={{ display: "none", width: "100%" }}>
              <DocumentPopUp file_url={docInfo.file_url} />
            </div>
          )}
        </>
      )}
    </>
  );
}
export default AlertInvalidDoc;
