import { useEffect, useState } from "react";
import axiosConfig from "../../../providers/axiosConfig";
import SubjectIcons from "./SubjectIcons";
import {
  MainDiv,
  InfoDiv,
  Hours,
  Text,
  SubText,
  Separator,
  NoLessons,
  LessonsWrapperMargins,
} from "../styles/MobileLessonCalendar.styles";
import Moment from "react-moment";
import moment from "moment";

// Mobile lesson calendar component
function MobileLessonCalendar({ date, userData }) {
  //Vars & Consts
  const [lessons, setLessons] = useState();
  const studentId = localStorage.getItem("student");
  var separatorActive = false;

  //Axios Call
  useEffect(() => {
    axiosConfig
      .get(`/users/lessons/${date}`, {
        params: { studentId: studentId },
      })
      .then((resp) => {
        setLessons(resp.data);
      })
      .catch((err) => null);
  }, [date, userData, studentId]);

  //Page Content
  if (lessons && lessons.length !== 0) {
    return (
      <LessonsWrapperMargins>
        {lessons.sort((a, b) => {
          if (moment(a.startTime).isBefore(moment(b.startTime))) {
            return -1;
          }
          return 1;
        }).map((lesson, index) => {
          // Main Content
          return (
            <MainDiv key={index}>
              <InfoDiv>
                <Hours>
                  <span>
                    <Moment date={lesson.startTime} format={"HH:mm"} />
                  </span>
                  <span style={{ opacity: 0.5 }}>
                    <Moment date={lesson.endTime} format={"HH:mm"} />
                  </span>
                </Hours>

                {separatorActive ? (
                  <Separator active></Separator>
                ) : (
                  <Separator></Separator>
                )}

                <div>
                  <Text>{lesson.subject}</Text>
                  <br />
                  <SubText>Prof. {lesson.educator}</SubText>
                  <br />
                  <SubText>Sala {lesson.classroom}</SubText>
                </div>
              </InfoDiv>
              <SubjectIcons subjectId={lesson.lessonId} iconOpacity={0.25} />
            </MainDiv>
          );
        })}
      </LessonsWrapperMargins>
    );
  } else {
    return (
      <NoLessons>
        <h4>Não existe horário escolar para o respetivo dia!</h4>
      </NoLessons>
    );
  }
}
export default MobileLessonCalendar;
