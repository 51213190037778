/** @format */

import Modal from 'react-modal';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { ReactComponent as ExpandeView } from '../../img/expande_pop_up.svg';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';

const ModalStyle = {
  overlay: {
    zIndex: 300,
  },
  content: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    textAlign: 'center',
    border: 'none',
    transform: 'translate(-50%, -50%)',
    background: '#fff',
    boxShadow: '0px 4px 10px rgba(37, 49, 151, 0.05)',
    boxSizing: 'border-box',
    padding: '3rem',
    maxWidth: '90%',
    minWidth: '30%',
  },
};

const ModalStyleMobile = {
  overlay: {
    zIndex: 300,
  },
  content: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    textAlign: 'center',
    border: 'none',
    background: '#fff',
    boxShadow: '0px 4px 10px rgba(37, 49, 151, 0.05)',
    boxSizing: 'border-box',
    inset: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const CloseModalButton = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  color: white;
  background-color: #4566af;
  top: 20px;
  right: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(37, 49, 151, 0.05);
  border: none;
  z-index: 301;
  cursor: pointer;
`;

const ImagePreview = styled.img`
  max-width: 100%;
  height: auto;
  max-height: 80vh;
`;

const PdfContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  z-index: 3;

  button {
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 10px;
    border-radius: 50px;
    border: none;
    color: white;
    background-color: #4566af;
    box-shadow: 0px 4px 10px rgba(37, 49, 151, 0.05);
    cursor: pointer;
  }

  .react-pdf__Page__canvas {
    margin: 0 auto;
    width: 80% !important;
    height: 100% !important;
  }

  .react-pdf__Page {
    overflow: hidden;
  }
`;

const UploadedDocument = styled.div`
  box-shadow: 0px 4px 21px -4px rgba(0, 0, 0, 0.26);
  background-color: #fff;
  z-index: 3;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: default;
  min-width: 200px;

  img {
    height: 100%;
    width: 100%;
  }
`;

const NoDocument = styled.div`
  background-color: white;
  height: 100%;
  padding: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const Container = styled.div`
  border-radius: 20px;
  box-shadow: 0px 3.95785px 11.8736px -2.37471px #ccdcff;
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  max-height: 500px;
`;

function DocumentViewer(props) {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1250px)' });
  const fit = props.fit;
  const [openModal, setOpenModal] = useState(false);
  const [previewIsImage, setPreviewIsImage] = useState(false);
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    setPreviewIsImage(!props.file_url.includes('.pdf'));
  }, [props.file_url]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  const changePage = (next) => {
    if (next && pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    } else if (!next && pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  return (
    <Container style={{ flexGrow: props.file_url ? 0 : 1, boxShadow: fit ? 'none' : null }}>
      {props.file_url ? (
        <>
          <UploadedDocument>
            {previewIsImage ? (
              <img src={props.file_url} alt='Preview not found' />
            ) : (
              <Document
                file={props.file_url}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={(err) => console.log(err)}
              >
                <Page pageNumber={1} width={500} />
              </Document>
            )}
          </UploadedDocument>

          <ExpandeView
            width='30px'
            style={{
              position: 'absolute',
              right: '20px',
              bottom: '0px',
              cursor: 'pointer',
            }}
            onClick={() => {
              setOpenModal(true);
            }}
          />
          <Modal
            style={isTabletOrMobile ? ModalStyleMobile : ModalStyle}
            isOpen={openModal}
            onRequestClose={() => setOpenModal(false)}
            contentLabel='Pop up do documento'
          >
            <CloseModalButton onClick={() => setOpenModal(false)}>
              <CloseIcon />
            </CloseModalButton>
            {previewIsImage ? (
              <ImagePreview src={props.file_url} />
            ) : (
              <PdfContainer>
                {numPages > 1 && (
                  <button
                    type='button'
                    onClick={() => changePage(false)}
                    style={pageNumber === 1 ? { backgroundColor: '#E2E8F0' } : null}
                  >
                    <ArrowBackIosNewIcon />
                  </button>
                )}
                <Document file={props.file_url} onLoadSuccess={onDocumentLoadSuccess}>
                  <Page pageNumber={pageNumber} />
                </Document>
                {numPages > 1 && (
                  <button
                    type='button'
                    onClick={() => changePage(true)}
                    style={pageNumber === numPages ? { backgroundColor: '#E2E8F0' } : null}
                  >
                    <ArrowForwardIosIcon />
                  </button>
                )}
              </PdfContainer>
            )}
          </Modal>
        </>
      ) : (
        <NoDocument>Sem documento</NoDocument>
      )}
    </Container>
  );
}

export default DocumentViewer;
