import React, { useLayoutEffect, useState } from "react";
import styled from "styled-components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ReactComponent as ClockIcon } from "../../../icons/clock.svg";
import { ReactComponent as AlertIcon } from "../../../icons/alerts.svg";
import { ReactComponent as ChatIcon } from "../../../icons/chat.svg";
import { ReactComponent as DocIcon } from "../../../icons/doc.svg";
import { ReactComponent as EntranceIcon } from "../../../icons/entrance.svg";
import { ReactComponent as InquiryIcon } from "../../../icons/inquiry.svg";
import { ReactComponent as NewsIcon } from "../../../icons/news.svg";

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
`;
const BarWrapper = styled.div.attrs(({ theme }) => ({ background: theme.bg }))`
  height: 102px;
  width: 100%;
  border-radius: 30px;
  padding: 0.625rem 2.5rem;
  background: ${({ theme }) => theme.bg};
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  @media (max-width: 1250px) {
    height: auto;
    flex-grow: 1;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 1.25rem;
  }
`;
const IconDIv = styled.div`
  height: 130px;
  width: 130px;
  position: absolute;
  top: -1.35rem;
  & svg {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  @media (max-width: 1250px) {
    height: 70px;
    width: 70px;
    top: 0;
  }
`;
const HeaderWrap = styled.div`
  display: flex;
  flex-direction: column;
  & h1 {
    color: #fff;
    margin: 0;
    font-weight: 700;
  }
  & p {
    color: #ffffff80;
    margin: 0;
    font-weight: 500;
  }
  @media (max-width: 1250px) {
    flex-direction: row;
    align-items: center;
    margin-left: 90px;
    margin: 0 auto;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  margin-left: auto;
  gap: 1.25rem;
  @media (max-width: 1250px) {
    margin: 1.25rem auto 0 auto;
  }
`;
const BackButtonWrapper = styled.div`
  display: flex;
  gap: 0.625rem;
  color: #b0c2ea;
  align-items: center;
`;
const BackButton = styled.a`
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 3.26531px 8.16326px rgba(37, 49, 151, 0.05);
  border-radius: 8px;
  color: #4566af;
  padding: 0.625rem;
  cursor: pointer;
`;
const ICONS = [
  "",
  <ClockIcon />,
  <AlertIcon />,
  <ChatIcon />,
  <DocIcon />,
  <EntranceIcon />,
  <InquiryIcon />,
  <NewsIcon />,
];
function SA_PageTopBar({
  type,
  button,
  backbutton,
  path,
  title,
  subtitle,
  parentButton,
}) {
  const [hasIcon, SetIcon] = useState();
  const [background, SetBackground] = useState("");
  const theme = {
    bg: background,
  };

  useLayoutEffect(() => {
    switch (type) {
      case "default":
        SetIcon(ICONS[0]);
        SetBackground("#4566AF");
        break;
      case "horario":
        SetIcon(ICONS[1]);
        SetBackground(
          "linear-gradient(268.21deg, #70C1FC 14.79%, #2E8FE8 95.65%)"
        );
        break;
      case "alerta":
        SetIcon(ICONS[2]);
        SetBackground(
          "linear-gradient(267.78deg, #FF9674 13.03%, #FF7474 104.26%)"
        );
        break;
      case "chat":
        SetIcon(ICONS[3]);
        SetBackground(
          "linear-gradient(268.21deg, #70C1FC 14.79%, #2E8FE8 95.65%)"
        );
        break;
      case "documento":
        SetIcon(ICONS[4]);
        SetBackground(
          "linear-gradient(268.21deg, #7086FC 14.79%, #2E6DE8 95.65%)"
        );
        break;
      case "entrada":
        SetIcon(ICONS[5]);
        SetBackground(
          "linear-gradient(268.21deg, #0DC399 14.79%, #35E1BA 95.65%)"
        );
        break;
      case "inquerito":
        SetIcon(ICONS[6]);
        SetBackground(
          "linear-gradient(268.21deg, #FCDD70 14.79%, #E8CA2E 95.65%)"
        );
        break;
      case "noticia":
        SetIcon(ICONS[7]);
        SetBackground(
          "linear-gradient(268.21deg, #F57AC5 14.79%, #E243A3 95.65%)"
        );
        break;

      default:
        break;
    }
  }, [type]);

  return (
    <ContentWrapper>
      <BarWrapper theme={theme}>
        <IconDIv
          style={
            type !== "default" ? { display: "block" } : { display: "none" }
          }
        >
          {hasIcon}
        </IconDIv>
        <HeaderWrap
          style={
            type !== "default"
              ? { marginLeft: "140px" }
              : {  }
          }
        >
          <h1>{title}</h1>
          <p>{subtitle}</p>
        </HeaderWrap>
        <ButtonWrapper>{button}</ButtonWrapper>
      </BarWrapper>
      <BackButtonWrapper
        style={
          backbutton ? { visibility: "visible" } : { visibility: "hidden" }
        }
      >
        <BackButton href={path}>
          <ArrowBackIcon />
        </BackButton>
        <span>Voltar</span>
      </BackButtonWrapper>
    </ContentWrapper>
  );
}

export default SA_PageTopBar;
