/** @format */

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import instagram from '../img/instagram.svg';
import facebook from '../img/facebook.svg';
import footer_wave from '../img/footer-background.png';
import footer_wave_mobile from '../img/footer-background-mobile.png';
import logo from '../img/logo.svg';
import { ReactComponent as MailIcon } from '../img/whiteMailIcon.svg';
import { ReactComponent as PhoneIcon } from '../img/whitePhoneIcon.svg';
import styled from 'styled-components';
import moment from 'moment';

const FooterTitle = styled.h3`
  color: #ffffff;

  @media (max-width: 800px) {
    text-align: center;
  }
`;

const FooterLink = styled.a`
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  color: #ffffff;
`;

const FooterRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  max-width: 1000px;

  .footer_column {
    width: 33%;
  }

  .footer_column:nth-child(3) div {
    margin-left: auto;
  }

  .footer_contacts {
    align-items: center;
    display: flex;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    width: 100%;
    align-items: center;

    .footer_column {
      width: 100%;
      align-items: center;
      position: relative;
    }

    .footer_column:nth-child(1) div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .footer_column::after {
      content: "";
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      position: absolute;
      width: 90%;
      margin: auto;
      bottom: 0;
    }

    .footer_column:nth-child(2)::after {
      border-bottom: none;
    }

    .footer_column:nth-child(3) div {
      margin-left: 0;
    }

    div:nth-child(1) {
      order: 1;
    }

    div:nth-child(2) {
      order: 3;
    }

    div:nth-child(3) {
      order: 2;
    }
  }
`;

function Footer() {
  const { t } = useTranslation();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 900px)' });
  const [mainpage, SetMainPage] = useState(false);
  let authenticationToken = window.localStorage.getItem('auth');
  useEffect(() => {
    if (!authenticationToken) {
      SetMainPage(true);
    } else {
      SetMainPage(false);
    }
  }, [authenticationToken]);
  return (
    <footer style={!mainpage ? { background: '#f6fbff' } : { background: '#fff' }}>
      <div className='row' style={{ flexDirection: 'column' }}>
        <img src={logo} alt='' />
        <p>{t('footer.title')}</p>
      </div>
      <FooterRow>
        <div className='footer_column border'>
          <FooterTitle>Contactos</FooterTitle>
          <div>
            <p className='footer_contacts'>
              <MailIcon />
              <FooterLink
                href='mailto:info@seeapp.pt'
                rel='noreferrer noopener'
                style={{ textDecoration: 'none', color: '#fff' }}
              >
                Email: info@seeapp.pt
              </FooterLink>
            </p>
            <p className='footer_contacts'>
              <PhoneIcon />
              <FooterLink
                href='tel:(+351)291 103 685'
                rel='noreferrer noopener'
                style={{ textDecoration: 'none', color: '#fff' }}
              >
                Telefone: (+351) 291 103 685
              </FooterLink>
            </p>
          </div>
        </div>

        <div className='footer_column'>
          <div className='row socials' style={{ flexWrap: 'nowrap' }}>
            <FooterLink
              href='https://www.instagram.com/seeapp_saude.escolas/'
              target='_blank'
              rel='noreferrer noopener'
            >
              <img src={instagram} alt='instagram' />
            </FooterLink>
            <FooterLink
              href='https://www.facebook.com/See-App-103419235703022'
              target='_blank'
              rel='noreferrer noopener'
            >
              <img src={facebook} alt='facebook' />
            </FooterLink>
          </div>
        </div>
        <div className='footer_column border'>
          <div>
            <FooterTitle>Políticas</FooterTitle>

            <FooterLink href={'/policy'}>
              <p>Política de Privacidade</p>
            </FooterLink>
            {authenticationToken && (
              <FooterLink href={'/account/delete_info'}>Exercício de direitos - RGPD</FooterLink>
            )}
          </div>
        </div>
      </FooterRow>
      <FooterRow>
        <p style={{ margin: 'auto', marginBottom: '20px' }}>
          © {moment().format("YYYY")} SH SeeHealth Lda {t('footer.txt')}
        </p>
      </FooterRow>
      <div className='footer-wave'>
        <img
          style={{ height: '100%' }}
          src={isTabletOrMobile ? footer_wave_mobile : footer_wave}
          alt=''
        />
      </div>
    </footer>
  );
}

export default Footer;
