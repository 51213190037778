/** @format */
import styled from 'styled-components';
import SAIcon from '../dashboard/overall/Icon';

const Div = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

function DocumentType(props, isAlert, heightString) {
  let fillColor = '#5F80F7';
  let letterColor = '#333333';
  let title = props.title;
  if (props.isAlert === true) {
    fillColor = '#FF9075';
    letterColor = '#FF9075';
  }

  let size = 30;
  if (title) {
    size = 40;
    letterColor = '#4566af';
  }
  if (props.logoSize !== undefined) {
    size = props.logoSize;
  }

  return (
    <Div id='div' height={`${heightString}`}>
      {props.doc_type_id === 1 ? (
        <SAIcon icon={'vaccine'} color={fillColor} size={size} />
      ) : props.doc_type_id === 2 ? (
        <SAIcon icon='test' color={fillColor} size={size} />
      ) : props.doc_type_id === 3 ? (
        <SAIcon icon='documents-recovery' color={fillColor} size={size} />
      ) : props.doc_type_id === 4 ? (
        <SAIcon icon='documents-medic' color={fillColor} size={size} />
      ) : (
        <></>
      )}
      {props.hasName === true && (
        <span
          style={
            title
              ? { color: letterColor, fontSize: '20px', fontWeight: 'bold' }
              : { color: letterColor }
          }
        >
          {props.doc_type_name}
        </span>
      )}
    </Div>
  );
}

export default DocumentType;
