/** @format */
import { Alert, Snackbar } from "@mui/material";
import useScrollPosition from "@react-hook/window-scroll";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import burger from "../img/burger.svg";
import burgerColor from "../img/burgerColor.svg";
import cross from "../img/cross.svg";
import logo from "../img/logo.svg";
import logoCor from "../img/logoColor.svg";
import navbarIconWhite from "../img/navbar-icon-white.svg";
import navbarIcon from "../img/navbar-icon.svg";
import { ReactComponent as LockIcon } from "./../img/KeyLockIcon.svg";
import { ReactComponent as MailIcon } from "./../img/MailIcon.svg";
import SAButton from "./dashboard/overall/SA_Button";

const LoginStyles = {
  overlay: {
    zIndex: 300,
  },
  content: {
    position: "absolute",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    width: "40%",
    textAlign: "center",
    border: "none",
    transform: "translate(-50%, -50%)",
    background: "#FFFFFF",
    boxShadow: "0px 4px 10px rgba(37, 49, 151, 0.05)",
    borderRadius: "30px",
    boxSizing: "border-box",
    padding: "3rem",
  },
};
const LoginMobile = {
  overlay: {
    zIndex: 300,
  },
  content: {
    position: "absolute",
    top: "100px",
    left: "0",
    right: "auto",
    bottom: "auto",
    width: "100%",
    height: "60%",
    textAlign: "center",
    border: "none",
    background: "#FFFFFF",
    boxShadow: "0px 4px 10px rgba(37, 49, 151, 0.05)",
    borderRadius: "30px",
    boxSizing: "border-box",
    padding: "1.25rem",
  },
};
// const RegisterStyles = {
//   content: {
//     position: "absolute",
//     top: "50%",
//     left: "50%",
//     right: "auto",
//     bottom: "auto",
//     marginRight: "-50%",
//     width: "50%",
//     border: "none",
//     transform: "translate(-50%, -50%)",
//     background: "#FFFFFF",
//     boxShadow: "0px 4px 10px rgba(37, 49, 151, 0.05)",
//     borderRadius: "30px",
//     boxSizing: "border-box",
//     padding: "3rem",
//   },
// };
// const RegisterMobile = {
//   overlay: {
//     zIndex: 3,
//   },
//   content: {
//     position: "absolute",
//     top: "100px",
//     left: "0",
//     right: "auto",
//     bottom: "auto",
//     width: "100%",
//     height: "85%",
//     border: "none",
//     background: "#FFFFFF",
//     boxShadow: "0px 4px 10px rgba(37, 49, 151, 0.05)",
//     borderRadius: "30px",
//     boxSizing: "border-box",
//     padding: "1.25rem",
//     zIndex: "100",
//   },
// };

const LoginTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5rem;
  text-align: left;
  & p {
    margin: 0;
  }
`;

const InputBox = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1.25rem;
  margin: 2rem auto 1rem auto;
  border-bottom: 0.5px solid #4566af;
  box-sizing: border-box;
  padding: 0.625rem 0;

  & input {
    outline: none;
    width: 100%;
    border: 0;
    &::placeholder {
      color: #092f8380;
    }
  }
`;

const InputHelpers = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.5rem;
  & label {
    color: #a1a1a1;
  }
  & a {
    text-decoration: none;
    color: #5f80f7;
  }
`;

// const RegisterTitle = styled.div`
//   display: flex;
//   width: 100%;
//   justify-content: flex-start;
// `;
// const Column = styled.div`
//   display: flex;
//   flex-direction: column;
//   font-size: 16px;
// `;
// const FlexRow = styled.div`
//   display: flex;
//   gap: 1.25rem;
//   align-items: stretch;
//   width: 100%;
//   margin: 0.625rem 0;

//   @media (max-width: 1250px) {
//     flex-direction: column;
//   }
// `;

function NavBar({
  aboutInView,
  partnersInView,
  newsInView,
  pilotSchoolInView,
  contactsInView,
  fundingInView,
  white,
}) {
  const scrollY = useScrollPosition(60);
  const initialLang = window.localStorage.getItem("i18nextLng");
  const { t, i18n } = useTranslation();
  const [activeLang, setActiveLang] = useState(
    initialLang === "en" || initialLang === "en-US" ? "en" : "pt"
  );
  const [menuOpen, setMenuOpen] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1250px)" });
  const [modalIsOpen, setIsOpen] = useState(false);
  // const [registerModalIsOpen, setRegisterModalIsOpen] = useState(false);
  const [forgotPasswordModal, setForgotPasswordModal] = useState(false);

  // const [login_email, setLoginEmail] = useState("");
  // const [login_password, setLoginPassword] = useState("");
  // const [forgot_email, setForgotEmail] = useState("");

  const [open, setOpen] = useState(false);
  const [openforgot, setOpenForgot] = useState(null);
  // const Gender = ["Masculino", "Feminino", "Outro"];
  // const Civil = ["Solteiro/a", "Casado/a", "Viuvo/a"];
  // const Nationality = ["Portuguesa", "Espanhola", "Inglesa", "Venezuelana"];
  // const Educator = ["Pai", "Mãe", "Outro"];

  // const [registerBirthDate, setRegisterBirthDate] = useState(new Date());

  // const navigate = useNavigate();
  // let token, bearer;

  function openLoginModal(e) {
    e.preventDefault();
    setIsOpen(true);
    // closeRegisterModal(false);
    toggleMenu();
  }
  function closeLoginModal() {
    setIsOpen(false);
  }
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    setOpenForgot(null);
  };
  // function openRegisterModal() {
  //   setRegisterModalIsOpen(true);
  //   closeLoginModal(false);
  //   toggleMenu();
  // }
  // function closeRegisterModal() {
  //   setRegisterModalIsOpen(false);
  // }

  function toggleMenu() {
    if (isTabletOrMobile) setMenuOpen(!menuOpen);
  }

  const changeLanguage = (lng) => {
    setActiveLang(lng);
    i18n.changeLanguage(lng);
    toggleMenu();
  };

  const navItemClick = (e) => {
    if (window.location.pathname !== "/event") {
      e.preventDefault();
      toggleMenu();

      const id = e.target.getAttribute("href").substring(2);
      const el = document.getElementById(id);
      var headerOffset = 100;
      var elementPosition = el.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      setTimeout(() => {
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }, 100);
    }
  };

  // async function SendLoginRequest(e) {
  //   e.preventDefault();

  //   localStorage.clear("auth");

  //   const data = {
  //     email: login_email,
  //     password: login_password,
  //   };

  //   const loginRequest = await axiosConfig
  //     .post("/login", data)
  //     .catch((err) => setOpen(true));

  //   if (loginRequest?.status === 200) {
  //     token = loginRequest.data.access_token;
  //     bearer = loginRequest.data.token_type;
  //     localStorage.setItem("auth", JSON.stringify(loginRequest.data));
  //     axiosConfig.defaults.headers.common["Authorization"] =
  //       bearer + " " + token;
  //   }
  //   if (localStorage.getItem("auth")) {
  //     closeLoginModal();
  //     navigate("/");
  //     window.location.reload();
  //   }
  // }

  // async function SendForgotPasswordRequest(e) {
  //   e.preventDefault();

  //   const data = {
  //     email: forgot_email,
  //   };

  //   axiosConfig
  //     .post("/forgot_password", data)
  //     .then((res) => {
  //       setOpenForgot(["Verifique o seu email", "info"]);
  //       setForgotPasswordModal(false);
  //     })
  //     .catch((err) => setOpenForgot(["Email incorrecto!", "error"]));

  //   // if (forgotRequest.status === 200) {
  //   //   token = forgotRequest.data.access_token;
  //   //   bearer = forgotRequest.data.token_type;
  //   //   localStorage.setItem("auth", JSON.stringify(forgotRequest.data));
  //   //   axiosConfig.defaults.headers.common["Authorization"] =
  //   //     bearer + " " + token;
  //   // }
  //   // if (localStorage.getItem("auth")) {
  //   //   closeLoginModal();
  //   //   navigate("/");
  //   //   window.location.reload();
  //   // }
  // }

  // async function sendRegisterRequest(e) {
  //   e.preventDefault();
  // }

  useEffect(() => {
    if (menuOpen || modalIsOpen /*|| registerModalIsOpen*/) {
      setOpen(false);
      setOpenForgot(null);
      document.getElementById("root").setAttribute("style", "overflow: hidden");
    } else {
      document.getElementById("root").setAttribute("style", "");
    }
  }, [menuOpen, modalIsOpen, /*registerModalIsOpen*/]);

  return (
    <header className={scrollY > 5 || white ? "scroll" : ""}>
      <nav>
        <a href="/">
          <img
            style={{ width: "100px" }}
            src={scrollY > 5 || white ? logoCor : logo}
            alt=""
          />
        </a>
        <img
          className="mobile-menu-button"
          src={menuOpen ? cross : scrollY > 5 ? burgerColor : burger}
          onClick={toggleMenu}
          alt=""
        />

        <div className={menuOpen ? "open link-container" : "link-container"}>
          <a onClick={navItemClick} href="/#about">
            {t("navbar1")}
            <img
              className="icon"
              src={scrollY > 5 ? navbarIcon : navbarIconWhite}
              alt=""
              style={aboutInView ? { opacity: 1 } : null}
            />
          </a>

          <a onClick={navItemClick} href="/#partners">
            {t("navbar2")}
            <img
              className="icon"
              src={scrollY > 5 ? navbarIcon : navbarIconWhite}
              alt=""
              style={partnersInView && !aboutInView ? { opacity: 1 } : null}
            />
          </a>

          <a onClick={navItemClick} href="/#news">
            {t("navbar3")}
            <img
              className="icon"
              src={scrollY > 5 ? navbarIcon : navbarIconWhite}
              alt=""
              style={newsInView && !partnersInView ? { opacity: 1 } : null}
            />
          </a>
          <a onClick={navItemClick} href="/#schools">
            {t("navbar4")}
            <img
              className="icon"
              src={scrollY > 5 ? navbarIcon : navbarIconWhite}
              alt=""
              style={pilotSchoolInView && !newsInView ? { opacity: 1 } : null}
            />
          </a>
          <a onClick={navItemClick} href="/#contacts">
            {t("navbar6")}
            <img
              className="icon"
              src={scrollY > 5 ? navbarIcon : navbarIconWhite}
              alt=""
              style={
                contactsInView && !pilotSchoolInView ? { opacity: 1 } : null
              }
            />
          </a>
        </div>
        <div className={menuOpen ? "open lang-container" : "lang-container"}>
          <div className="login">
            {/* <a onClick={openRegisterModal} href="/#">
              {t("registo")}
            </a> */}
            <a onClick={openLoginModal} href="/#">
              {t("login")}
            </a>
          </div>
          <div className="lang">
            <a
              href="/#"
              className={activeLang === "pt" ? "selected" : ""}
              onClick={() => changeLanguage("pt")}
            >
              PT
            </a>
            <a
              href="/#"
              className={activeLang === "en" ? "selected" : ""}
              onClick={() => changeLanguage("en")}
            >
              EN
            </a>
            <a
              href="/#"
              className={activeLang === "en" ? "line right" : "line left"}
            >
              ___
            </a>
          </div>
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeLoginModal}
          style={isTabletOrMobile ? LoginMobile : LoginStyles}
          contentLabel="Pop up de Login"
        >
          <LoginTitle>
            <h2>Entrar</h2>
            <p>Aceda à sua conta para continuar</p>
          </LoginTitle>
          {/* 
          <form
            // onSubmit={(e) => {
            //   SendLoginRequest(e);
            // }}
          > */}
          <InputBox>
            <MailIcon />
            <input
              type="text"
              name="email"
              placeholder="Email"
              // onChange={(event) => setLoginEmail(event.target.value)}
            />
          </InputBox>

          <InputBox>
            <LockIcon />
            <input
              type="password"
              name="password"
              placeholder="Password"
              // onChange={(event) => setLoginPassword(event.target.value)}
            />
          </InputBox>
          <InputHelpers>
            <label>
              <input type="checkbox" name="remember" />
              Lembrar
            </label>
            <span>
              <a
                href="/#"
                onClick={() => {
                  setForgotPasswordModal(true);
                  closeLoginModal();
                }}
              >
                Esqueci-me da palavra-passe?
              </a>
            </span>
          </InputHelpers>
          <div style={{ width: "100%" }}>
            <SAButton
              disable={true}
              type={"submit"}
              value={"Login"}
              style={["btn-login"]}
              size={"fullwidth"}
              textcolor={"#fff"}
              centered={true}
            />
          </div>
          <p style={{ fontSize: 20, marginTop: "20px" }}>
            {t("mobileapp.txt3")}
          </p>

          {/* </form> */}
          {open && (
            <Snackbar
              open={open}
              autoHideDuration={3000}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              onClose={handleClose}
            >
              <Alert severity="error" sx={{ width: "100%" }}>
                Email ou Password incorrectos!
              </Alert>
            </Snackbar>
          )}
        </Modal>

        <Modal
          isOpen={forgotPasswordModal}
          onRequestClose={setForgotPasswordModal}
          style={isTabletOrMobile ? LoginMobile : LoginStyles}
          contentLabel="Pop up de Esqueceu Palavra Passe"
        >
          <LoginTitle>
            <h3>Esqueci-me da palavra-passe</h3>
            <p>
              Insira o email associado à sua conta para receber o email de
              recuperação dos dados de acesso!
            </p>
          </LoginTitle>

          {/* <form onSubmit={SendForgotPasswordRequest}> */}
          <InputBox>
            <MailIcon />
            <input
              type="text"
              name="email"
              placeholder="Email"
              // onChange={(event) => setForgotEmail(event.target.value)}
            />
          </InputBox>

          <InputHelpers>
            <span>
              <a
                href="/#"
                onClick={(e) => {
                  setForgotPasswordModal(false);
                  openLoginModal(e);
                }}
              >
                Fazer Login
              </a>
            </span>
          </InputHelpers>
          <div style={{ width: "100%" }}>
            <SAButton
              disable={true}
              type={"submit"}
              value={"Pedir Nova Palavra-Passe"}
              style={["btn-login"]}
              size={"fullwidth"}
              textcolor={"#fff"}
              centered={true}
            />
          </div>
          <p style={{ fontSize: 20, marginTop: "20px" }}>
            {t("mobileapp.txt3")}
          </p>
          {/* </form> */}
          {openforgot && (
            <Snackbar
              open={openforgot}
              autoHideDuration={3000}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              onClose={handleClose}
            >
              <Alert severity={openforgot[1]} sx={{ width: "100%" }}>
                {openforgot[0]}
              </Alert>
            </Snackbar>
          )}
        </Modal>
        {/* <Modal
          isOpen={registerModalIsOpen}
          onRequestClose={closeRegisterModal}
          className="registo"
          style={isTabletOrMobile ? RegisterMobile : RegisterStyles}
          contentLabel="Pop up de registo"
        >
          <RegisterTitle>
            <h3>Informação Biográfica</h3>
          </RegisterTitle>

          <form
            onSubmit={sendRegisterRequest}
            style={{ overflowY: "auto", height: "85%" }}
          >
            <SAInput
              label={"Nome Completo"}
              placeholder={"Filipa Carvalhal Nóbrega"}
              type={"text"}
            />
            <FlexRow>
              <SADatePicker
                label={"Data de Nascimento"}
                value={registerBirthDate}
                onChange={setRegisterBirthDate}
              />
              <SASelect data={Gender} label={"Sexo"} />
              <SASelect data={Civil} label={"Estado Civil"} />
            </FlexRow>
            <FlexRow>
              <SAInput
                label={"Naturalidade"}
                placeholder={"Funchal, São Pedro"}
                type={"text"}
              />
              <SASelect data={Nationality} label={"Nacionalidade"} />
            </FlexRow>
            <Column>
              <label>Encarregado de educação</label>
              <FlexRow>
                <SASelect data={Educator} size={"small"} />
                <SAInput placeholder={"Nome Completo"} type={"text"} />
              </FlexRow>
            </Column>
            <Column style={{ marginTop: "1.25rem" }}>
              <SAButton
                value={"Guardar Alterações"}
                size={"adaptive"}
                textcolor={"#fff"}
                align={"right"}
              />
            </Column>
          </form>
        </Modal> */}
      </nav>
    </header>
  );
}

export default NavBar;
