/** @format */

import Calendar from "react-calendar";
import { ReactComponent as CalendarArrow } from "../../../img/Calendar/arrow.svg";
import { ReactComponent as ReverseCalendarArrow } from "../../../img/Calendar/reverseArrow.svg";
import { useState } from "react";
import { useEffect } from "react";
import { CalendarContainer, CalendarHeader } from "../styles/Calendar.styles";
import moment from "moment";

//Get Month
function getMonthText(month) {
  var monthNames = [
    "janeiro",
    "fevereiro",
    "março",
    "abril",
    "maio",
    "junho",
    "julho",
    "agosto",
    "setembro",
    "outubro",
    "novembro",
    "dezembro",
  ];

  return monthNames[month];
}

//Get Week
function getWeekText(week) {
  var weekNames = [
    "Domingo",
    "Segunda-feira",
    "Terça-feira",
    "Quarta-feira",
    "Quinta-feira",
    "Sexta-feira",
    "Sábado",
  ];

  return weekNames[week];
}

// Schedulings Calendar Component
function SchedulingsCalendar({ setDateFilter, setGeneralDate }) {
  const [value, setValue] = useState(new Date());

  useEffect(() => {
    setDateFilter(value);
    setGeneralDate(value);
  }, [setDateFilter, setGeneralDate, value]);

  return (
    <>
      <CalendarContainer>
        <CalendarHeader>
          <div>
            <span className="selectedDayMonth">
              {value.getDate()} {getMonthText(value.getMonth())}
            </span>
            <span className="selectedYear">{value.getFullYear()}</span>
            <br />
            <span className="selectedWeekDay">{getWeekText(value.getDay())}</span>
          </div>
        </CalendarHeader>

        <Calendar locale="pt"
          minDate={new Date(moment().subtract(1, "year").startOf('month').format("YYYY-MM-DD"))}
          maxDate={new Date(moment().add(1, "year").endOf('month').format("YYYY-MM-DD"))}
          onChange={setValue}
          value={value}
          nextLabel={<ReverseCalendarArrow style={{ fill: "#4566AF" }} />}
          prevLabel={<CalendarArrow style={{ fill: "#4566AF" }} />}
        />
      </CalendarContainer>
    </>
  );
}

export default SchedulingsCalendar;
