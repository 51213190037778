/** @format */

import { useEffect, useState } from "react";
import axiosConfig from "../../../providers/axiosConfig";
import {
  CalendarContainer,
  CardWrapper,
  CalendarTable,
} from "../styles/ManualLessonCalendar.styles";
import moment from "moment/moment";
import { CircularProgress } from "@mui/material";

// Manual Lesson Calendar Compnent
function ManualLessonCalendar({ userData, generalDate }) {
  const [fullWeekSchedule, setFullWeekSchedule] =
    useState();
  const [hours, setHours] = useState();
  // const [date, setDate] = useState();
  const studentId = localStorage.getItem("student");

  // let date = moment(generalDate).format("YYYY-MM-DD HH:mm");

  useEffect(() => {
    axiosConfig
      .get(`/me/weekSchedule`, {
        params: {
          studentId: studentId,
          date: moment(generalDate).format(
            "YYYY-MM-DD HH:mm"
          ),
        },
      })
      .then((resp) => {
        setFullWeekSchedule(resp.data);
        getHours(resp.data);
      });// eslint-disable-next-line
  }, [userData, studentId]);

  const getHours = (fullWeekSchedule) => {
    let startHours = [];
    let endHours = [];

    // eslint-disable-next-line
    [
      ...fullWeekSchedule.weekSchedule.mon,
      ...fullWeekSchedule.weekSchedule.tue,
      ...fullWeekSchedule.weekSchedule.wed,
      ...fullWeekSchedule.weekSchedule.thu,
      ...fullWeekSchedule.weekSchedule.fri,
    ].forEach((lesson) => {
      let startHour = moment(lesson.startAt).format(
        "HH:mm"
      );
      let endHour = moment(lesson.endAt).format("HH:mm");

      if (!startHours.includes(startHour)) {
        startHours.push(startHour);
        endHours.push(endHour);
      }
    });

    startHours.sort();
    endHours.sort();

    let myHours = startHours.map((startHour, index) => {
      return { start: startHour, end: endHours[index] };
    });
    setHours(myHours);
  };

  return (
    <>
      <div style={{ flexGrow: 1 }}>
        <div style={{ marginBottom: "1rem" }}>
          <h3>Horário Escolar</h3>
        </div>
        <CardWrapper>
          <CalendarContainer>
            {fullWeekSchedule ? (
              <CalendarTable>
                <thead>
                  <tr>
                    <th>Hora</th>
                    <th>Seg</th>
                    <th>Ter</th>
                    <th>Qua</th>
                    <th>Qui</th>
                    <th>Sex</th>
                  </tr>
                </thead>

                <tbody>
                  {hours &&
                  fullWeekSchedule &&
                  fullWeekSchedule?.weekSchedule !== [] ? (
                    hours?.map((hour, index) => {
                      return (
                        <tr key={index}>
                          <th>
                            {hour.start} <br /> {hour.end}
                          </th>
                          <td>
                            {fullWeekSchedule.weekSchedule?.mon.map(
                              (data, index) => {
                                return (
                                  moment(
                                    data.startAt
                                  ).format("HH:mm") ===
                                    hour.start && (
                                    <div key={index}>
                                      <span>
                                        <b>
                                          {data.subjectName}{" "}
                                        </b>
                                      </span>
                                      <span>
                                        {" "}
                                        {data.classroom}
                                      </span>
                                      <br />
                                    </div>
                                  )
                                );
                              }
                            )}
                          </td>
                          <td>
                            {fullWeekSchedule.weekSchedule?.tue.map(
                              (data, index) => {
                                return (
                                  moment(
                                    data.startAt
                                  ).format("HH:mm") ===
                                    hour.start && (
                                    <div key={index}>
                                      <span>
                                        <b>
                                          {data.subjectName}{" "}
                                        </b>
                                      </span>
                                      <span>
                                        {" "}
                                        {data.classroom}
                                      </span>
                                      <br />
                                    </div>
                                  )
                                );
                              }
                            )}
                          </td>
                          <td>
                            {fullWeekSchedule.weekSchedule?.wed.map(
                              (data, index) => {
                                return (
                                  moment(
                                    data.startAt
                                  ).format("HH:mm") ===
                                    hour.start && (
                                    <div key={index}>
                                      <span>
                                        <b>
                                          {data.subjectName}{" "}
                                        </b>
                                      </span>
                                      <span>
                                        {" "}
                                        {data.classroom}
                                      </span>
                                      <br />
                                    </div>
                                  )
                                );
                              }
                            )}
                          </td>
                          <td>
                            {fullWeekSchedule.weekSchedule?.thu.map(
                              (data, index) => {
                                return (
                                  moment(
                                    data.startAt
                                  ).format("HH:mm") ===
                                    hour.start && (
                                    <div key={index}>
                                      <span>
                                        <b>
                                          {data.subjectName}{" "}
                                        </b>
                                      </span>
                                      <span>
                                        {" "}
                                        {data.classroom}
                                      </span>
                                      <br />
                                    </div>
                                  )
                                );
                              }
                            )}
                          </td>
                          <td>
                            {fullWeekSchedule.weekSchedule?.fri.map(
                              (data, index) => {
                                return (
                                  moment(
                                    data.startAt
                                  ).format("HH:mm") ===
                                    hour.start && (
                                    <div key={index}>
                                      <span>
                                        <b>
                                          {data.subjectName}{" "}
                                        </b>
                                      </span>
                                      <span>
                                        {" "}
                                        {data.classroom}
                                      </span>
                                      <br />
                                    </div>
                                  )
                                );
                              }
                            )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <td rowSpan={2} colSpan={6}>
                      Sem dados
                    </td>
                  )}
                </tbody>
              </CalendarTable>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress size={80} />
              </div>
            )}
          </CalendarContainer>
        </CardWrapper>
      </div>
    </>
  );
}

export default ManualLessonCalendar;
