/** @format */

import React, { useEffect } from "react";
import styled from "styled-components";
import { useState } from "react";
import axiosConfig from "../../providers/axiosConfig";
import SABackButton from "./overall/SA_BackButton";
import SAEditButton from "./overall/SA_EditButton";
import SAInput from "./overall/SA_Input";
import SAButton from "./overall/SA_Button";
import { Alert, CircularProgress, Snackbar } from "@mui/material";

import Uploader from "../Documents/Uploader";
import DocumentViewer from "../Documents/DocumentViewer";
import moment from "moment";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 1.25rem;

  & h4 {
    margin: 0;
  }
`;

const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 0.75fr 0.75fr;
  margin-bottom: 1rem;
  margin-top: 1rem;
  gap: 1rem;

  @media (max-width: 1250px) {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;

const Column = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
`;
const RowSpaced = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media (max-width: 1250px) {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }
`;
const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 20px;
  min-height: 3rem;
`;

// const Status = styled.span`
//   padding: 0 0.625rem;
//   border-radius: 8px;
//   background-color: transparent;
//   line-height: 39px;
//   position: absolute;
//   top: 1.25rem;
//   right: 1.25rem;

//   &.updated {
//     background-color: #0dc3992e;
//     color: #0dc399;
//   }
//   &.missing {
//     background-color: #e15a481a;
//     color: #e15a48;
//   }
// `;

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #fff;
  border-radius: 8px;
  height: 100%;
  box-sizing: border-box;
  padding: 1.25rem;

  h3 {
    margin: 0;
    margin-bottom: 1rem;
  }

  p {
    margin: 0;
  }

  ul {
    margin: 0;
  }
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: 28px;
`;

const RadioRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  p {
    margin: 0;
    display: block;
    position: relative;
    padding-left: 25px;
  }

  input[type="checkbox"] {
    position: absolute;
    cursor: pointer;
    opacity: 0;
    height: 20px;
    width: 20px;
    top: 0;
    left: 0;
    margin: 0;
    z-index: 10;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border-radius: 50px;
    border-style: solid;
    border-width: 2px;
    padding: 2px;
    border-color: #4f81ec;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .checkmark .check {
    opacity: 0;
    background-color: #4f81ec;
    border-radius: 50px;
    width: 100%;
    height: 100%;
  }

  input[type="checkbox"]:checked ~ .checkmark .check {
    opacity: 1;
  }
`;

const InputWrap = styled.div`
  display: flex;
  gap: 0.625rem;
  min-width: 64px;
  font-size: 1.125rem;
  color: #fff;
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  border: 1px solid #4566af80;
  border-radius: 8px;
  width: 100%;
  & textarea {
    outline: 0;
    border: 0;
    color: #b0c2ea;
    font-size: 1rem;
    resize: none;
    width: 100%;
    &::placeholder {
      color: #b0c2ea;
    }
  }
`;
const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #b0c2ea;
  margin: 1rem 0 1rem 0;
`;

function MedicalProfile({ userData }) {
  const { user } = userData;
  const studentId = localStorage.getItem("student");
  const [info, setInfo] = useState([]);
  const [edit, SetEdit] = useState(false);
  const [axiosError, setAxiosError] = useState(null);
  const [showOtherProblems, SetShowOtherProblems] = useState(false);
  const [showOtherAllergies, SetShowOtherAllergies] = useState(false);
  const [problems, SetProblems] = useState([]);
  const [allergies, SetAllergies] = useState([]);
  const [intolerances, SetIntolerances] = useState([]);
  const [otherproblem, SetOtherProblem] = useState([]);
  const [otherallergy, SetOtherAllergy] = useState([]);
  const [file, setFile] = useState(null);
  const [observation, SetObservation] = useState("");
  const [possibleSymptoms] = useState([
    "Asma",
    "Bronquite",
    "Diabetes",
    "Enxaqueca",
    "Problemas de Visão",
  ]);
  const [possibleIntolerances] = useState(["Lactose", "Glúten"]);
  const [possibleAllergies] = useState(["Alimentar", "Cutânea", "Rinite"]);

  function handleProblem(e) {
    let isChecked = e.target.checked;
    const aux = problems;

    if (isChecked) {
      if (aux.indexOf(e.target.value) === -1) {
        aux.push(e.target.value);
      }
    } else {
      aux.splice(aux.indexOf(e.target.value), 1);
    }
    SetProblems(aux);
  }
  function handleIntolerance(e) {
    let isChecked = e.target.checked;
    const aux = intolerances;

    if (isChecked) {
      if (aux.indexOf(e.target.value) === -1) {
        aux.push(e.target.value);
      }
    } else {
      aux.splice(aux.indexOf(e.target.value), 1);
    }
    SetIntolerances(aux);
  }
  function handleAllergy(e) {
    let isChecked = e.target.checked;
    const aux = allergies;

    if (isChecked) {
      if (aux.indexOf(e.target.value) === -1) {
        aux.push(e.target.value);
      }
    } else {
      aux.splice(aux.indexOf(e.target.value), 1);
    }
    SetAllergies(aux);
  }

  function SendToApi(event) {
    event.preventDefault();
    const formData = new FormData();
    formData.append(
      "allergies",
      JSON.stringify(allergies.filter((el) => possibleAllergies.includes(el)))
    );

    formData.append("otherallergys", JSON.stringify([...new Set(otherallergy)]));
    formData.append(
      "intolerances",
      JSON.stringify(intolerances.filter((el) => possibleIntolerances.includes(el)))
    );
    formData.append(
      "symptoms",
      JSON.stringify(problems.filter((el) => possibleSymptoms.includes(el)))
    );
    formData.append("othersymptoms", JSON.stringify([...new Set(otherproblem)]));
    formData.append("otherintolerances", null);
    formData.append("observation", observation);
    if (file) {
      formData.append("vaccination_file", file);
    }

    if (info.medical_info[0]) {
      axiosConfig
        .post("/me/medical_info/" + info.medical_info[0].id, formData)
        .then((res) => {
          window.location.reload();
        })
        .catch((err) => setAxiosError(err.message));
    } else {
      let userId;
      if (userData.isParent) {
        userId = studentId;
      } else {
        userId = userData.user.id;
      }
      axiosConfig
        .post("/me/new_medical_info/" + userId, formData)
        .then((res) => {
          window.location.reload();
        })
        .catch((err) => setAxiosError(err.message));
    }
  }

  useEffect(() => {
    axiosConfig
      .get("/me/medical_info", {
        params: {
          studentId: studentId,
        },
      })
      .then((res) => {
        setInfo(res.data);
        const medical_info = res.data.medical_info[0];
        if (medical_info) {
          SetProblems([...medical_info.symptoms].filter((elem) => possibleSymptoms.includes(elem)));
          SetIntolerances(
            typeof medical_info.intolerances === "string"
              ? [medical_info.intolerances]
              : medical_info.intolerances
          );
          SetAllergies(
            [...medical_info.alergies].filter((elem) => elem && possibleAllergies.includes(elem))
          );
          SetObservation(medical_info.observation);
          SetOtherProblem(
            [...medical_info.symptoms].filter((elem) => elem && !possibleSymptoms.includes(elem))
          );
          SetShowOtherProblems(
            [...medical_info.symptoms].filter((elem) => elem && !possibleSymptoms.includes(elem))
              .length > 0
          );
          SetOtherAllergy(
            [...medical_info.alergies].filter((elem) => elem && !possibleAllergies.includes(elem))
          );
          SetShowOtherAllergies(
            [...medical_info.alergies].filter((elem) => elem && !possibleAllergies.includes(elem))
              .length > 0
          );
        }
      });
  }, [user, userData, studentId, possibleAllergies, possibleSymptoms]);

  return (
    <>
      {info && info.user ? (
        <>
          <Snackbar
            open={axiosError}
            onClose={() => {
              setAxiosError(null);
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <Alert severity="error">{axiosError}</Alert>
          </Snackbar>
          <SABackButton />
          <Main>
            {!edit ? (
              <>
                <RowSpaced>
                  <Title>Ficha de Informações Médicas</Title>
                  {userData.isParent && (
                    <SAEditButton isRelative={true} onclick={() => SetEdit(true)} />
                  )}
                </RowSpaced>
                <Grid>
                  <Card>
                    <h3>Dados Pessoais</h3>
                    <RowSpaced>
                      <Column>
                        <div>
                          <h4>Nome</h4>
                          <span>{info.user.name}</span>
                        </div>
                        <div>
                          <h4>Número de Utente de Saúde</h4>
                          <span>{info.user.cc ? info.user.cc : "N/A"}</span>
                        </div>
                      </Column>
                      <Column>
                        <div>
                          <h4>Data de nascimento</h4>
                          <span>
                            {info.user.birth_date
                              ? moment(info.user.birth_date).format("YYYY-MM-DD")
                              : "N/A"}
                          </span>
                        </div>
                        <div>
                          <h4>Unidade de Saúde</h4>
                          <span>
                            {info.user.health_establishment
                              ? info.user.health_establishment
                              : "N/A"}
                          </span>
                        </div>
                      </Column>
                    </RowSpaced>
                  </Card>
                  <Column>
                    <Card>
                      <div>
                        <h3>Boletim de vacinas</h3>
                        <Column>
                          {info &&
                          info.medical_info[0] &&
                          info.medical_info[0].vaccination_file_url ? (
                            <>
                              {/* <Status
                                className={`status ${
                                  info.medical_info[0].vaccination_verified
                                    ? "updated"
                                    : "missing"
                                } `}
                              >
                                {info.medical_info[0].vaccination_verified
                                  ? "Validado"
                                  : "Não Validado"}
                              </Status> */}
                              <DocumentViewer
                                fit
                                file_url={info.medical_info[0].vaccination_file_url || ""}
                              />
                            </>
                          ) : (
                            <p>N/A</p>
                          )}
                        </Column>
                      </div>
                    </Card>
                  </Column>
                </Grid>
                <Card>
                  <h3>Problemas de Saúde</h3>
                  {info &&
                  info.medical_info[0] &&
                  info.medical_info[0].symptoms &&
                  info.medical_info[0].symptoms[0] ? (
                    <ul>
                      {info &&
                        [...info.medical_info[0].symptoms].sort().map((elem, index) => {
                          if (elem !== "") return <li key={index}>{elem}</li>;
                          return null;
                        })}
                    </ul>
                  ) : (
                    <p>N/A</p>
                  )}
                </Card>

                <Grid>
                  <Card>
                    <h3>Intolerâncias</h3>
                    {info &&
                    info.medical_info[0] &&
                    info.medical_info[0].intolerances &&
                    info.medical_info[0].intolerances[0] ? (
                      <ul>
                        {[...info.medical_info[0].intolerances].sort().map((intolerance, index) => {
                          if (intolerance !== "") return <li key={index}>{intolerance}</li>;
                          return null;
                        })}
                      </ul>
                    ) : (
                      <div style={{ width: "fit-content" }}>N/A</div>
                    )}
                    <Divider />
                    <h3 style={{ marginTop: 0 }}>Alergias</h3>
                    {info &&
                    info.medical_info[0] &&
                    info.medical_info[0].alergies &&
                    info.medical_info[0].alergies[0] ? (
                      <ul>
                        {[...info.medical_info[0].alergies].sort().map((alergy, index) => {
                          if (alergy !== "") return <li key={index}>{alergy}</li>;
                          return null;
                        })}
                      </ul>
                    ) : (
                      <div style={{ width: "fit-content" }}>N/A</div>
                    )}
                  </Card>
                  <Column>
                    <Card>
                      <div>
                        <h3>Outras observações</h3>

                        {info && info.medical_info[0] && info.medical_info[0].observation ? (
                          <span>{info.medical_info[0].observation}</span>
                        ) : (
                          <span>N/A</span>
                        )}
                      </div>
                    </Card>
                  </Column>
                </Grid>
              </>
            ) : (
              <form onSubmit={SendToApi}>
                <RowSpaced>
                  <Title>Ficha de Informações Médicas</Title>
                  <Row style={{ width: "fit-content" }}>
                    <SAButton
                      type={"reset"}
                      textcolor={"#fff"}
                      bgColor={"#9A9AA399"}
                      size={"medium"}
                      onclick={() => SetEdit(false)}
                      value={"Cancelar"}
                    />
                    <SAButton
                      type={"submit"}
                      textcolor={"#fff"}
                      size={"medium"}
                      value={"Guardar Alterações"}
                    />
                  </Row>
                </RowSpaced>
                <Grid>
                  <Card>
                    <h3>Dados Pessoais</h3>
                    <RowSpaced>
                      <Column>
                        <div>
                          <h4>Nome</h4>
                          <span>{info.user.name}</span>
                        </div>
                        <div>
                          <h4>Número de Utente de Saude</h4>
                          <span>{info.user.cc ? info.user.cc : "N/A"}</span>
                        </div>
                      </Column>
                      <Column>
                        <div>
                          <h4>Data de nascimento</h4>
                          <span>{info.user.birth_date ? info.user.birth_date : "N/A"}</span>
                        </div>
                        <div>
                          <h4>Unidade de Saúde</h4>
                          <span>
                            {info.user.health_establishment
                              ? info.user.health_establishment
                              : "N/A"}
                          </span>
                        </div>
                      </Column>
                    </RowSpaced>
                  </Card>
                  <Column>
                    <Card>
                      <div>
                        <h3>Boletim de vacinas</h3>
                        <Uploader
                          setFile={setFile}
                          fit
                          defaultDoc={info.medical_info[0]?.vaccination_file_url}
                        />
                      </div>
                    </Card>
                  </Column>
                </Grid>
                <Card>
                  <h3>Problemas de Saúde</h3>
                  <Row>
                    {possibleSymptoms.map((problem, index) => (
                      <RadioRow key={index}>
                        <p>
                          <input
                            defaultChecked={problems.includes(problem)}
                            type={"checkbox"}
                            value={problem}
                            name="problema"
                            onChange={(e) => handleProblem(e)}
                          />
                          <span className="checkmark">
                            <span className="check"></span>
                          </span>
                          {problem}
                        </p>
                      </RadioRow>
                    ))}
                    <RadioRow>
                      <p>
                        <input
                          defaultChecked={otherproblem.length > 0}
                          type={"checkbox"}
                          value="outros"
                          name="problema"
                          onChange={(e) => {
                            if (!e.target.checked) {
                              SetOtherProblem([]);
                            }
                            SetShowOtherProblems(e.target.checked);
                          }}
                        />
                        <span className="checkmark">
                          <span className="check"></span>
                        </span>
                        Outros
                      </p>
                      {showOtherProblems && (
                        <SAInput
                          type={"text"}
                          placeholder="Escreva aqui..."
                          defaultvalue={otherproblem.join(", ")}
                          onchange={(e) => {
                            SetOtherProblem(e.target.value.split(",").map((elem) => elem.trim()));
                          }}
                        />
                      )}
                    </RadioRow>
                  </Row>
                  {/* Listagem de problemas de saude */}
                </Card>

                <Grid>
                  <Card>
                    <h3>Intolerâncias e Alergias</h3>
                    <span>Possui alguma intolerância alimentar?</span>
                    <Row>
                      {possibleIntolerances.map((elem, index) => (
                        <RadioRow key={index}>
                          <p>
                            <input
                              defaultChecked={intolerances.includes(elem)}
                              type={"checkbox"}
                              value={elem}
                              name="intolerancia"
                              onChange={(e) => handleIntolerance(e)}
                            />
                            <span className="checkmark">
                              <span className="check"></span>
                            </span>
                            {elem}
                          </p>
                        </RadioRow>
                      ))}
                    </Row>
                    <Divider />
                    <span>Possui alguma alergia?</span>
                    {/* LISTAR ALERGIAS */}

                    <Row>
                      {possibleAllergies.map((elem, index) => (
                        <RadioRow key={index}>
                          <p>
                            <input
                              defaultChecked={allergies.includes(elem)}
                              type={"checkbox"}
                              value={elem}
                              name="alergia"
                              onChange={(e) => handleAllergy(e)}
                            />
                            <span className="checkmark">
                              <span className="check"></span>
                            </span>
                            {elem}
                          </p>
                        </RadioRow>
                      ))}

                      <RadioRow>
                        <p>
                          <input
                            defaultChecked={otherallergy.length > 0}
                            type={"checkbox"}
                            value="outra"
                            name="alergia"
                            onChange={(e) => {
                              if (!e.target.checked) {
                                SetOtherProblem([]);
                              }
                              SetShowOtherAllergies(e.target.checked);
                            }}
                          />
                          <span className="checkmark">
                            <span className="check"></span>
                          </span>
                          Outra
                        </p>

                        {showOtherAllergies && (
                          <SAInput
                            type={"text"}
                            placeholder="Escreva aqui..."
                            value={otherallergy.join(", ")}
                            onchange={(e) => {
                              SetOtherAllergy(e.target.value.split(",").map((elem) => elem.trim()));
                            }}
                          />
                        )}
                      </RadioRow>
                    </Row>
                  </Card>
                  <Column>
                    <Card>
                      <div>
                        <h3>Outras observações</h3>
                        <p>Observação</p>
                        <InputWrap>
                          <textarea
                            id="message"
                            name="message"
                            rows="10"
                            cols="50"
                            defaultValue={observation}
                            placeholder="Escreva aqui..."
                            onChange={(e) => SetObservation(e.target.value)}
                          />
                        </InputWrap>
                      </div>
                    </Card>
                  </Column>
                </Grid>
              </form>
            )}
          </Main>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress style={{ marginTop: "20%" }} size={80} />
        </div>
      )}
    </>
  );
}

export default MedicalProfile;
