/** @format */

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import SAButton from "../dashboard/overall/SA_Button";
import { Alert, Snackbar } from "@mui/material";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { useMediaQuery } from "react-responsive";
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const FileInput = styled.input`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  z-index: 2;
  cursor: pointer;
  opacity: 0;
  position: absolute;
`;
const FileInputDash = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 1px dashed #d0d5dd;
  cursor: pointer;
  background: transparent;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-grow: 1;
  padding: 20px;
`;
const FormInputDiv = styled.div`
  box-sizing: border-box;
  padding: 30px;
  flex-grow: 1;
  background: white;
  border-radius: 20px;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  display: flex;
  overflow: hidden;
  box-shadow: 0px 4px 10px rgba(37, 49, 151, 0.05);

  .react-pdf__Page__canvas {
    margin: 0 auto;
    width: 80% !important;
    height: 100% !important;
  }

  @media (max-width: 1250px) {
    width: 100%;
  }
`;
const PdfContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  z-index: 3;

  button {
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 10px;
    border-radius: 50px;
    border: none;
    color: white;
    background-color: #4566af;
    box-shadow: 0px 4px 10px rgba(37, 49, 151, 0.05);
    cursor: pointer;
  }

  .react-pdf__Page__canvas {
    margin: 0 auto;
    width: 80% !important;
    height: 100% !important;
  }

  .react-pdf__Page {
    overflow: hidden;
  }
`;

const UploadedDocument = styled.div`
  box-shadow: 0px 4px 21px -4px rgba(0, 0, 0, 0.26);
  background-color: #fff;
  z-index: 3;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 40%;
  padding: 10px;
  cursor: default;
  min-width: 280px;
  max-width: 350px;
`;

const DocButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;
  flex-grow: 1;
`;

const DocButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  color: #4566af;
`;

const ModalStyle = {
  overlay: {
    zIndex: 300,
  },
  content: {
    position: "absolute",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    textAlign: "center",
    border: "none",
    transform: "translate(-50%, -50%)",
    background: "#fff",
    boxShadow: "0px 4px 10px rgba(37, 49, 151, 0.05)",
    boxSizing: "border-box",
    padding: "3rem",
    maxWidth: "90%",
  },
};

const ModalStyleMobile = {
  overlay: {
    zIndex: 300,
  },
  content: {
    position: "fixed",
    width: "100%",
    height: "100%",
    textAlign: "center",
    border: "none",
    background: "#fff",
    boxShadow: "0px 4px 10px rgba(37, 49, 151, 0.05)",
    boxSizing: "border-box",
    inset: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

const CloseModalButton = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  color: white;
  background-color: #4566af;
  top: 20px;
  right: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(37, 49, 151, 0.05);
  border: none;
  z-index: 301;
  cursor: pointer;
`;

const ImagePreview = styled.img`
  max-width: 100%;
  height: auto;
`;

function Uploader({ fit, defaultDoc, setFile }) {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1250px)" });
  const [preview, setPreview] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [previewIsImage, setPreviewIsImage] = useState(false);
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [fileError, setFileError] = useState(false);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  const removeError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFileError(false);
  };
  const changePage = (next) => {
    if (next && pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    } else if (!next && pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  useEffect(() => {
    if (defaultDoc) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          setPreview(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", defaultDoc);
      xhr.responseType = "blob";
      xhr.send();
    }
  }, [defaultDoc]);

  return (
    <>
      <Snackbar
        open={fileError}
        onClose={removeError}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert severity="error">Ficheiro inválido</Alert>
      </Snackbar>
      <div
        style={{
          height: fit ? "none" : "100%",
          minHeight: fit ? 0 : "300px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <FormInputDiv style={{ boxShadow: "none" }}>
          <FileInput
            type="file"
            id="myfile"
            name="myfile"
            accept="image/png,image/jpeg,application/pdf"
            onChange={(e) => {
              if (e.target.files.length > 0) {
                let file = e.target.files[0];
                setFile(file);
                if ("image/png,image/jpeg,application/pdf".includes(file.type)) {
                  setFileError(false);
                  setPageNumber(1);
                  setPreviewIsImage("image/png,image/jpeg".includes(file.type));
                  var reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onload = function () {
                    setPreview(reader.result);
                  };
                  reader.onerror = function (error) {
                    setFileError(true);
                    e.target.value = "";
                    setPreview(null);
                  };
                } else {
                  setFileError(true);
                  e.target.value = "";
                  setPreview(null);
                }
              }
            }}
          />
          <FileInputDash>
            {!preview ? (
              <label>
                {window.innerWidth > 1080
                  ? "Arraste ou carregue aqui o seu documento"
                  : "Carregue aqui o seu documento"}
              </label>
            ) : (
              <UploadedDocument>
                {previewIsImage ? (
                  <img src={preview} width={150} alt="Preview" />
                ) : (
                  <Document file={preview} onLoadSuccess={onDocumentLoadSuccess}>
                    <Page pageNumber={1} width={150} />
                  </Document>
                )}
                <DocButtons>
                  <DocButton
                    type="button"
                    onClick={() => {
                      setOpenModal(true);
                    }}
                  >
                    <VisibilityIcon />
                  </DocButton>
                  <DocButton
                    type="button"
                    onClick={() => {
                      setPreview(null);
                      document.getElementById("myfile").value = "";
                    }}
                  >
                    <DeleteOutlineIcon />
                  </DocButton>
                </DocButtons>
              </UploadedDocument>
            )}
          </FileInputDash>
          <Modal
            style={isTabletOrMobile ? ModalStyleMobile : ModalStyle}
            isOpen={openModal}
            onRequestClose={() => setOpenModal(false)}
            contentLabel="Pop up do documento"
          >
            <CloseModalButton onClick={() => setOpenModal(false)}>
              <CloseIcon />
            </CloseModalButton>
            {previewIsImage ? (
              <ImagePreview src={preview} />
            ) : (
              <PdfContainer>
                {numPages > 1 && (
                  <button
                    type="button"
                    onClick={() => changePage(false)}
                    style={pageNumber === 1 ? { backgroundColor: "#E2E8F0" } : null}
                  >
                    <ArrowBackIosNewIcon />
                  </button>
                )}
                <Document file={preview} onLoadSuccess={onDocumentLoadSuccess}>
                  <Page pageNumber={pageNumber} />
                </Document>
                {numPages > 1 && (
                  <button
                    type="button"
                    onClick={() => changePage(true)}
                    style={pageNumber === numPages ? { backgroundColor: "#E2E8F0" } : null}
                  >
                    <ArrowForwardIosIcon />
                  </button>
                )}
              </PdfContainer>
            )}
          </Modal>
          {preview && (
            <div style={{ marginTop: "10px", width: "100%" }}>
              <SAButton value="Alterar Documento" size="fullwidth" textcolor="#FFFFFF" />
            </div>
          )}
        </FormInputDiv>
      </div>
    </>
  );
}

export default Uploader;
