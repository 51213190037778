import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";

const MainDiv = styled.div`
  background: #fff;
  box-shadow: 0px 4px 10px rgba(37, 49, 151, 0.05);
  border-radius: 20px;
  margin-top: 20px;
  margin-bottom:10px;
  padding-top: 2px;
  padding-bottom: 15px;
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 30px;
  color: #4566af;
`;

const Input = styled.input`
  margin-left: 30px;
`;

const Label = styled.label`
  font-weight: 400;
  font-size: 16px;
  color: #4566af;
`;

function InquiryQuestionMultiple(props) {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 899px)" });

  useEffect(() => {
    if (isTabletOrMobile) {
    }
  }, [isTabletOrMobile]);

  return (
    <MainDiv>
      <Title>
        {props.number + 1}-{props.title}
      </Title>
      {props.choices.map((choice) => {
        return (
          <>
            <Input
              type="radio"
              id={choice.id}
              name={props.title}
              value={choice.choice}
            />
            <Label for={choice.id}> {choice.choice}</Label>
            <br />
          </>
        );
      })}
    </MainDiv>
  );
}
export default InquiryQuestionMultiple;
