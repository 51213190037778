/** @format */

import React, { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import SAQRBar from "./home/SA_QRBar";
import SATemperatureBar from "./home/SA_TemperatureBar";
import Modal from "react-modal";
import { QRCodeSVG } from "qrcode.react";
import SAIcon from "./overall/Icon";
import SASmallCard from "./overall/SA_SmallCard";
import SAInquiryCard from "./overall/SA_InquiryCard";
import SANewsCard from "./news/SA_NewsCard";
import axiosConfig from "../../providers/axiosConfig";
import { useNavigate } from "react-router-dom";
import Tutorial from "../Tutorial/tutorial";
import SAScheduleItem from "../../components/dashboard/home/SA_ScheduleItem";
import moment from "moment";
import AlertCovidPopUp from "../Alerts/AlertCovidPopUp";
import { CircularProgress } from "@mui/material";
// import { NewspaperOutlined } from "@mui/icons-material";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(69, 102, 175, .70)",
    zIndex: 200,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    textAlign: "center",
    border: "none",
    transform: "translate(-50%, -50%)",
    background: "#FFFFFF",
    boxShadow: "0px 4px 10px rgba(37, 49, 151, 0.05)",
    borderRadius: "30px",
  },
};

const Landing = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 0.5fr;
  gap: 30px;

  @media (max-width: 1250px) {
    grid-template-columns: unset;
    grid-template-rows: auto auto;
  }
`;

const Subtitle = styled.p`
  color: #000000;
  font-weight: 700;
  font-size: 18px;
`;

const FlexRow = styled.div`
  display: flex;
  gap: 1.25rem;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const InquiryFlexRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;
  margin-left: 1.25rem;
`;

const ScheduleWrap = styled.div`
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 4.63309px 11.5827px rgba(37, 49, 151, 0.05);
  box-sizing: border-box;
  padding: 1rem 1.25rem;
  @media (min-width: 1250px) {
    height: 100%;
  }
`;
const ScheduleContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  overflow-y: scroll;
  overflow-x: none;
  max-height: 550px;
  padding-bottom: 10px;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #b0c2ea;
    border-radius: 34.3668px;
    transform: rotate(-90deg);
  }
  &::-webkit-scrollbar-track {
    border: 2.05654px solid #b0c2ea;
    border-radius: 34.3668px;
    transform: rotate(-90deg);
    margin-top: 15px;
  }
`;
const DateTopRight = styled.div`
  & p:nth-child(1) {
    font-size: 25px;
  }
  & p:nth-child(2) {
    font-size: 20px;
  }
  & p {
    margin: 5px;
  }
`;

function DashboardLandingPage({ userData, studentData, setStudentData }) {
  const date = moment().format("ddd");
  const [qrModal, setQrModal] = useState(false);
  const [tutorial, setTutorial] = useState(false);
  const studentId = localStorage.getItem("student");
  const [dashboardData, setDashboardData] = useState(null);
  const [showCovidAlert, setShowCovidAlert] = useState(null);
  sessionStorage.setItem("route", "dashboardlanding");
  const [lessons, setLessons] = useState();
  const navigate = useNavigate();

  function openQRmodal() {
    setQrModal(true);
  }
  function closeQrModal() {
    setQrModal(false);
  }

  useEffect(() => {
    if (qrModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflowX = "hidden";
      document.body.style.overflowY = "overlay";
    }
  }, [qrModal]);

  useEffect(() => {
    axiosConfig
      .get("me/dashboard", {
        params: { studentId: studentId },
      })
      .then((res) => {
        setDashboardData(res.data);
        window.localStorage.setItem("dashboardData", JSON.stringify(res.data));
      });
  }, [userData, studentId]);

  //Axios Call
  useEffect(() => {
    axiosConfig
      .get(`/users/lessons/${date}`, {
        params: { studentId: studentId },
      })
      .then((resp) => {
        setLessons(resp.data);
      })
      .catch((err) => null);
  }, [userData, studentId, date]);

  return userData && userData.user && dashboardData ? (
    <>
      {userData.user.created_at === userData.user.updated_at ? (
        <div>
          <Tutorial
            userData={userData}
            setTutorial={setTutorial}
            continuos={true}
            isParent={userData.isParent}
          />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "auto",
            width: "fit-content",
          }}
        >
          <Tutorial
            userData={userData}
            setTutorial={setTutorial}
            isParent={userData.isParent}
            continuos={false}
            oneTime={true}
          />
          <DateTopRight>
            <p>
              <b>{moment().format("D MMMM")}</b> {moment().format("YYYY")}
            </p>
            <p>{moment().format("dddd")}</p>
          </DateTopRight>
        </div>
      )}

      {/*  */}
      <Landing>
        <div className="mainwrapper">
          {dashboardData.entry && dashboardData.entry.entrance ? (
            <div>
              <SAQRBar
                user_type={userData.user.role_id}
                qr={userData.isParent ? false : true}
                username={dashboardData.user.name}
                time={moment(dashboardData.entry.entrance.created_at).format("HH:mm")}
                gate={"Portão da Escola"}
                onclick={openQRmodal}
                image={dashboardData.user.photo_url}
              />
            </div>
          ) : (
            <div>
              <SAQRBar
                user_type={userData.user.role_id}
                qr={userData.isParent ? false : true}
                username={dashboardData.user.name}
                onclick={openQRmodal}
                image={dashboardData.user.photo_url}
              />
            </div>
          )}
          <div id="avgTemperature">
            {tutorial && <SATemperatureBar date={"01/06/2022"} temperature={"36.7"} />}
            {!tutorial && dashboardData.averageTemperature?.date && (
              <SATemperatureBar
                date={dashboardData.averageTemperature.date}
                temperature={dashboardData.averageTemperature.temperature}
              />
            )}
          </div>
          <FlexRow>
            <div>
              <TitleRow>
                <SAIcon icon={"alerts2"} color={"#FF9075"} size={40} />
                <h3>Alertas</h3>
              </TitleRow>
              <FlexRow>
                {tutorial && (
                  <div id="alertas">
                    <SASmallCard
                      icon={<SAIcon icon={"covid"} color={"#FF9075"} />}
                      type={"alert"}
                      header={"Teste de covid positivo"}
                    />
                  </div>
                )}

                {!tutorial && dashboardData.alerts && dashboardData.alerts.length > 0
                  ? dashboardData.alerts
                      .sort((a, b) => {
                        if (moment(a.created_at).isBefore(moment(b.created_at))) {
                          return 1;
                        }
                        return -1;
                      })
                      .map((alert, index) => {
                        let icon;
                        switch (alert.alert_type.id) {
                          case 1:
                            icon = <SAIcon icon={"covid"} color={"#FF4A4A"} />;
                            break;
                          case 2:
                            icon = (
                              <SAIcon
                                icon={"fever"}
                                color={alert.temperature > 38.5 ? "#FF4A4A" : "#FF9075"}
                              />
                            );
                            break;
                          case 3:
                            icon = <SAIcon icon={"valid-entrance"} color={"#22DCCA"} />;
                            break;
                          case 4:
                            icon = <SAIcon icon={"injure"} color={"#FF4A4A"} />;
                            break;
                          case 5:
                            icon = <SAIcon icon={"tired"} color={"#FF9075"} />;
                            break;
                          case 6:
                            icon = <SAIcon icon={"documents"} color={"#FF9075"} />;
                            break;
                          default:
                            break;
                        }
                        if (index < 4) {
                          return (
                            <SASmallCard
                              style={{
                                cursor: [1, 2, 6].includes(alert.alert_type_id)
                                  ? "pointer"
                                  : "initial",
                              }}
                              onclick={() => {
                                if (alert.alert_type_id === 1) {
                                  setShowCovidAlert(true);
                                } else if (alert.alert_type_id === 2) {
                                  navigate(`/alerts/showHighTemperature/${alert.id}`, {
                                    state: {
                                      info: alert,
                                    },
                                  });
                                } else if (alert.alert_type_id === 6) {
                                  navigate(`/alerts/showInvalidDocument/${alert.id}`, {
                                    state: {
                                      doc_reports: alert.document_reports,
                                      alert_id: alert.id,
                                    },
                                  });
                                }
                              }}
                              alert={alert}
                              key={index}
                              icon={icon}
                              type={"alerta"}
                              header={alert.alert_type ? alert.alert_type.name : ""}
                            />
                          );
                        }
                        return null;
                      })
                  : !tutorial && <p>Não Existem Alertas recentes</p>}
              </FlexRow>
            </div>
            <div>
              <TitleRow>
                <SAIcon icon={"documents"} color={"#0018EE"} size={40} />
                <h3>Documentos</h3>
              </TitleRow>
              <FlexRow id="documents">
                {tutorial && (
                  <SASmallCard
                    icon={<SAIcon icon={"vaccine"} color={"#5F80F7"} />}
                    type={"documento"}
                    header={"Certificado de Vacinação"}
                  />
                )}
                {!tutorial && dashboardData.documents && dashboardData.documents.length > 0
                  ? dashboardData.documents
                      .sort((a, b) => {
                        if (moment(a.created_at).isBefore(moment(b.created_at))) {
                          return 1;
                        }
                        return -1;
                      })
                      .map((document, index) => {
                        var icon;
                        switch (document.document_type.id) {
                          case 1:
                            icon = <SAIcon icon={"vaccine"} color={"#5F80F7"} />;
                            break;
                          case 2:
                            icon = <SAIcon icon={"test"} color={"#5F80F7"} />;
                            break;
                          case 3:
                            icon = <SAIcon icon={"documents-medic"} color={"#5F80F7"} />;
                            break;
                          case 4:
                            icon = <SAIcon icon={"documents-recovery"} color={"#5F80F7"} />;
                            break;
                          default:
                            break;
                        }
                        return (
                          <SASmallCard
                            key={index}
                            icon={icon}
                            type={"documento"}
                            header={document.document_type.description}
                            onclick={() => navigate("/document_show/" + document.id)}
                          />
                        );
                      })
                  : !tutorial && (
                      <p style={{ textAlign: "center", width: "100%" }}>Não Existem Documentos</p>
                    )}
              </FlexRow>
            </div>
          </FlexRow>
        </div>
        <div className="mainwrapper" id="horarios">
          <TitleRow>
            <SAIcon icon={"schedule"} color={"#4566AF"} size={40} />
            <h3>Horário do dia</h3>
          </TitleRow>
          <ScheduleWrap>
            {tutorial ? (
              <ScheduleContent>
                <SAScheduleItem
                  start_time={moment().format("YYYY-MM-DD") + " 8:00"}
                  end_time={moment().format("YYYY-MM-DD") + " 9:00"}
                  subject={"Matemática"}
                  professor={"Alberto Freitas"}
                />
                <SAScheduleItem
                  start_time={moment().format("YYYY-MM-DD") + " 9:30"}
                  end_time={moment().format("YYYY-MM-DD") + " 11:00"}
                  subject={"Educação Física"}
                  professor={"António João"}
                />
                <SAScheduleItem
                  start_time={moment().format("YYYY-MM-DD") + " 11:00"}
                  end_time={moment().format("YYYY-MM-DD") + " 12:30"}
                  subject={"Físico-química"}
                  professor={"Maria Albertina"}
                />
                <SAScheduleItem
                  start_time={moment().format("YYYY-MM-DD") + " 12:30"}
                  end_time={moment().format("YYYY-MM-DD") + " 14:00"}
                  subject={"Almoço"}
                />
                <SAScheduleItem
                  start_time={moment().format("YYYY-MM-DD") + " 14:00"}
                  end_time={moment().format("YYYY-MM-DD") + " 15:30"}
                  subject={"Português"}
                  professor={"Rosélia Almeida"}
                />
              </ScheduleContent>
            ) : lessons?.length > 0 ? (
              <ScheduleContent>
                {lessons
                  .sort((a, b) => {
                    if (moment(a.startTime).isBefore(moment(b.startTime))) {
                      return -1;
                    }
                    return 1;
                  })
                  .map((lesson, index) => {
                    return (
                      <SAScheduleItem
                        key={index}
                        start_time={lesson.startTime}
                        end_time={lesson.endTime}
                        subject={lesson?.subject}
                        professor={lesson?.educator}
                        local={lesson?.classroom}
                      />
                    );
                  })}
              </ScheduleContent>
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p style={{ textAlign: "center", fontSize: "1.2rem" }}>Sem aulas hoje</p>
              </div>
            )}
          </ScheduleWrap>
        </div>
      </Landing>
      <div style={{ marginTop: "1.5rem" }}>
        <TitleRow>
          <SAIcon icon={"questionaire"} color={"#EDB84F"} size={40} />
          <h3>Inquéritos</h3>
        </TitleRow>
        <InquiryFlexRow id="inquiry">
          {tutorial && (
            <SAInquiryCard
              header={"Título do Inquérito"}
              text={"Descrição do Inquérito"}
              info={"10 perguntas"}
            />
          )}
          {!tutorial &&
          dashboardData.inquiries &&
          dashboardData.inquiries.active &&
          dashboardData.inquiries.active.length > 0
            ? dashboardData.inquiries.active
                .sort((a, b) => {
                  if (moment(a.created_at).isBefore(moment(b.created_at))) {
                    return 1;
                  }
                  return -1;
                })
                .map((inquiry, index) => {
                  return (
                    <SAInquiryCard
                      onclick={() => navigate(`/inquiries/${inquiry.id}`)}
                      key={index}
                      header={inquiry.title}
                      text={inquiry.description}
                      info={inquiry.inquiry_questions.length + " perguntas"}
                    />
                  );
                })
            : !tutorial && <h3>Não Existem Inquéritos</h3>}
        </InquiryFlexRow>
      </div>
      <div style={{ marginTop: "1.5rem" }}>
        <TitleRow>
          <SAIcon icon={"news"} color={"#E243A3"} size={40} />
          <h3>Notícias</h3>
        </TitleRow>
        {tutorial && (
          <div id="news" style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
            <SANewsCard
              type={"Escola"}
              header={"Título da notícia"}
              image={
                "https://st4.depositphotos.com/2727515/21449/i/600/depositphotos_214499698-stock-photo-enjoying-nature-feeling-free.jpg"
              }
            />
            <SANewsCard
              type={"Escola"}
              header={"Título da notícia"}
              image={
                "https://st4.depositphotos.com/2727515/21449/i/600/depositphotos_214499698-stock-photo-enjoying-nature-feeling-free.jpg"
              }
            />
            <SANewsCard
              type={"Escola"}
              header={"Título da notícia"}
              image={
                "https://st4.depositphotos.com/2727515/21449/i/600/depositphotos_214499698-stock-photo-enjoying-nature-feeling-free.jpg"
              }
            />
          </div>
        )}
        <InquiryFlexRow>
          {!tutorial && dashboardData.news && dashboardData.news.length > 0
            ? dashboardData.news
                .sort((a, b) => {
                  if (moment(a.created_at).isBefore(moment(b.created_at))) {
                    return 1;
                  }
                  return -1;
                })
                .map((news, index) => {
                  return (
                    <SANewsCard
                      id={news.id}
                      key={index}
                      type={news.type}
                      header={news.title}
                      image={news.image}
                    />
                  );
                })
            : !tutorial && <h3>Não Existem Notícias</h3>}
        </InquiryFlexRow>
      </div>
      <Modal
        isOpen={qrModal}
        onRequestClose={closeQrModal}
        style={customStyles}
        contentLabel="Pop up de Login"
      >
        <QRCodeSVG size={300} bgColor={"#FFFFFF"} fgColor={"#000000"} value={userData.user.uuid} />
        <Subtitle>Clicar fora para fechar</Subtitle>
      </Modal>
      {showCovidAlert && <AlertCovidPopUp hide={setShowCovidAlert} />}
    </>
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "95vh",
      }}
    >
      <CircularProgress size={80} />
    </div>
  );
}

export default DashboardLandingPage;
