/** @format */

import styled from "styled-components";

export const CardWrapper = styled.div`
  background-color: #fff;
  border-radius: 20px;
  width: auto;
  height: auto;
  box-shadow: 0px 4px 10px rgba(37, 49, 151, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 431px) {
    min-width: 400px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const PillButton = styled.button.attrs((props) => ({
  className: props.className,
}))`
  width: 120px;
  color: #4566af;
  background-color: #fff;
  height: 40px;
  font-family: "Nunito";
  font-size: 15px;
  border-radius: 30px;
  border: none;
  font-weight: 500;

  :hover {
    background-color: #4566af;
    color: #fff;
  }
  &.bt-active {
    background-color: #4566af;
    color: #fff;
  }
`;

export const WeekPill = styled.button`
  border: none;
  border-radius: 10px;
  min-width: 48px;
  width: "auto";
  min-height: 32px;
  max-height: 36px;
  background-color: #fff;
  color: #4566af;
  font-family: "Nunito";
  font-size: 16px;
  font-weight: 600;
  :hover {
    background-color: #4566af;
    color: white;
  }
  :active {
    background-color: #4566af;
    color: white;
  }
  &.bt-active {
    background-color: #4566af;
    color: white;
  }
`;
