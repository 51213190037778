/** @format */

import React from 'react';
import styled from 'styled-components';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SAIcon from '../overall/Icon';
import { ReactComponent as CirclePlusIcon } from '../../../icons/circle_plus1.svg';
import { useMediaQuery } from 'react-responsive';
import { Avatar } from '@mui/material';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  & h3 {
    margin: 0;
  }
`;
const Bar = styled.div`
  background-color: #4566af;
  height: 110px;
  box-sizing: border-box;
  padding: 1.25rem;
  border-radius: 20px;
  display: flex;
  box-shadow: 0px 4.63717px 11.5929px rgba(37, 49, 151, 0.05);
  & .bg {
    position: absolute;
    left: 10%;
    fill: red;
  }
  @media (max-width: 1250px) {
    flex-direction: column-reverse;
    padding: 0.625rem;
    align-items: center;
    position: relative;
  }
`;
const StudentImage = styled.div`
  position: relative;
  width: 10%;
  min-width: 130px;
  height: 120px;

  @media (max-width: 1250px) {
    height: 80px;
    position: relative;
  }
`;
const ImageWrap = styled.div`
  position: absolute;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  border: 5px solid #fff;
  left: 0;
  top: -60%;
  overflow: hidden;

  & img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  @media (max-width: 1250px) {
    width: 80px;
    height: 80px;
    position: absolute;
    top: 0;
  }
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: 2.5rem;
  & div {
    display: inline-flex;
    align-items: center;
    gap: 0.625rem;
    & svg {
      color: #fff;
      width: 50px;
      height: 50px;
    }
  }

  & h3 {
    font-size: 1.5rem;
    color: #fff;
    margin: 0;
  }
  & h4 {
    font-size: 1rem;
    color: #fff;
    margin: 0;
  }
  & p {
    color: #fff;
    margin: 0;
  }

  @media (max-width: 1250px) {
    flex-direction: row;
    justify-content: space-between;
    margin-left: unset;
    width: 100%;
    align-items: center;

    & h3 {
      font-size: 1.1rem;
    }
    & div {
      display: inline-flex;
      align-items: center;
      gap: 0.2rem;
      box-sizing: border-box;
      & svg {
        color: #fff;
        width: 30px;
        height: 30px;
      }
    }
    & .gate {
      align-self: end;
      width: 10%;
      min-width: 130px;
    }
  }
`;
const QRWrap = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.25rem;
  & h3 {
    overflow-wrap: break-word;
    width: 30%;
    line-height: 2.5rem;
    margin: 0;
    color: #fff;
    font-size: 1.5rem;
  }
  @media (max-width: 1250px) {
    flex-direction: column-reverse;
    margin-left: unset;
    position: absolute;
    top: -50%;
  }
`;
const QR = styled.div`
  width: 140px;
  height: 140px;
  background-color: #4566af;
  border: 5px solid #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  & svg {
    object-fit: contain;
    width: 50%;
    height: 50%;
  }
  @media (max-width: 1250px) {
    width: 70px;
    height: 70px;
  }
`;
function SA_QRBar({ username, time, gate, qr, image, user_type, onclick }) {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1250px)' });
  return (
    <Wrapper>
      {user_type === 6 ? (
        <>
          <div>
            <h3>Olá Encarregado de Educação</h3>
            <p>
              O seu educando,{' '}
              <span style={{ fontWeight: 'bold' }}>
                {username.split(' ')[0] + ' ' + username.split(' ')[username.split(' ').length - 1]}
              </span>
            </p>
          </div>
        </>
      ) : (
        <>
          <h3>
            Olá {username.split(' ')[0] + ' ' + username.split(' ')[username.split(' ').length - 1]}
            !
          </h3>
        </>
      )}
      <Bar>
        <CirclePlusIcon className='bg' />
        {isTabletOrMobile ? (
          <Column>
            <StudentImage>
              <ImageWrap>
                <Avatar
                  variant={'circular'}
                  src={image}
                  sx={{ width: '100%', height: '100%' }}
                />
              </ImageWrap>
            </StudentImage>
            {user_type === 6 && time ? (
              <>
                <div style={{ alignSelf: 'center' }}>
                  <h3>Entrou às {time}</h3>
                  <AccessTimeIcon />
                </div>
                <p className='gate'>{gate}</p>
              </>
            ) : user_type === 5 && time ? (
              <>
                <div style={{ alignSelf: 'center' }}>
                  <h3>Entraste às {time}</h3>
                  <AccessTimeIcon />
                </div>
                <p className='gate'>{gate}</p>
              </>
            ) : (
              <>
                <div>
                  <h4>Sem entrada registada</h4>
                </div>
                <p className='gate'>{gate}</p>
              </>
            )}
          </Column>
        ) : (
          <>
            <StudentImage>
              <ImageWrap>
                <Avatar
                  variant={'circular'}
                  src={image}
                  sx={{ width: '100%', height: '100%' }}
                />
              </ImageWrap>
            </StudentImage>
            <Column>
              {user_type === 6 && time ? (
                <>
                  <Column style={{ alignItems: 'flex-start', margin: 0, width: '100%' }}>
                    <div>
                      <h3>Entrou às {time}</h3>
                      <AccessTimeIcon />
                    </div>
                  </Column>
                  <p>{gate}</p>
                </>
              ) : user_type === 5 && time ? (
                <>
                  <Column style={{ alignItems: 'flex-start', margin: 0, width: '100%' }}>
                    <div>
                      <h3>Entraste às {time}</h3>
                      <AccessTimeIcon />
                    </div>
                  </Column>
                  <p>{gate}</p>
                </>
              ) : (
                <>
                  <Column style={{ alignItems: 'flex-start', margin: 0, width: '100%' }}>
                    <h4>Sem entrada registada</h4>
                  </Column>
                  <p>{gate}</p>
                </>
              )}
            </Column>
          </>
        )}

        {qr && (
          <QRWrap>
            <QR onClick={onclick} id='qrcode-btn'>
              <SAIcon icon={'qr-code'} color={'#fff'} />
            </QR>
          </QRWrap>
        )}
      </Bar>
    </Wrapper>
  );
}

export default SA_QRBar;
