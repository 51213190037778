/** @format */

import styled from "styled-components";

export const CalendarContainer = styled.div`
  max-width: 450px;
  margin: auto;
  margin-top: 20px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  position: relative;

  .react-calendar {
    margin: 0;
    width: 100%;
    border: none;
    background: white;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;

    @media (max-width: 431px) {
      margin: 0;
    }
  }

  .react-calendar__month-view__weekdays abbr,
  .react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday {
    text-align: center;
    text-decoration: none;
  }

  .react-calendar__tile {
    font-family: Nunito;
    font-weight: 400;
    border: 0;
    font-size: 16px;
    height: 60px;
    color: #4566af;
    background-color: transparent;
    position: relative;
    z-index: 3;
    cursor: pointer;

    &::after {
      content: "";
      display: block;
      background-color: transparent;
      border-radius: 50%;
      height: 36px;
      width: 36px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
    }
  }

  .react-calendar__year-view__months__month {
    &::after {
      width: 100%;
      border-radius: 8px;
    }
  }

  .react-calendar__decade-view__years__year {
    &::after {
      width: 100%;
      border-radius: 8px;
    }
  }

  .react-calendar__tile:hover {
    color: #fff;
    &::after {
      background-color: #6ec1b2;
      color: #4566af;
    }
  }

  .react-calendar__tile:disabled {
    display: none;
  }

  .react-calendar__tile--now {
    color: #fff;
    &::after {
      background-color: #4566af;
      color: #fff;
    }
  }

  .react-calendar__tile--active {
    color: #fff;
    &::after {
      background-color: #4566af;
      color: #fff;
    }
  }

  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: transparent;
  }

  .react-calendar__navigation__label {
    background-color: #4566af;
    width: auto;
    height: auto;
    color: white;
    border: none;
    border-radius: 9px;
    box-sizing: border-box;
    padding: 0.625rem;
    cursor: pointer;

    @media (min-width: 431px) {
      :hover {
        background-color: grey;
      }
    }
  }
  .react-calendar__navigation {
    position: relative;
    width: 100%;
    margin: 20px 0;
    right: unset;
    display: flex;
    align-items: center;
    gap: 0.625rem;

    @media (max-width: 431px) {
      position: relative;
      width: 100%;
      margin: 20px 0;
      right: unset;
    }
  }
  .react-calendar__navigation__prev-button,
  .react-calendar__navigation__next-button {
    background: transparent;
    border: unset;
    cursor: pointer;

    :disabled {
      opacity: 0.5;
    }
  }
  .react-calendar__navigation__prev2-button,
  .react-calendar__navigation__next2-button {
    visibility: hidden;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    opacity: 0.5;
  }
`;

export const CalendarHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 25px;
  margin-left: 25px;

  .selectedDayMonth {
    font-family: Nunito;
    font-weight: 600;
    font-size: 24px;
    color: #4566af;
    height: 60px;
  }
  .selectedYear {
    margin-left: 8px;
    font-family: Nunito;
    font-weight: 400;
    font-size: 24px;
    color: #4566af;
    height: 60px;
  }
  .selectedWeekDay {
    font-family: Nunito;
    font-weight: 400;
    font-size: 16px;
    color: #4566af;
    height: 60px;
  }
`;
