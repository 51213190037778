import styled from "styled-components";

export const MainDiv = styled.div`
  display: flex;
  margin-bottom: 10px;
  width: auto;
  border-radius: 112px;
  justify-content: space-between;
  align-items: center;
  padding-right: 30px;
  border: 2px solid #b0c2ea;
  padding-top: 7px;
  padding-bottom: 7px;
  & svg {
    opacity: 1;
    height: 60px;
    width: 60px;
  }
  @media screen and (min-width: 600px) {
    width: "auto";
    & svg {
      height: 40px;
      width: 40px;
    }
  }
  @media screen and (min-width: 800px) {
    width: "auto";
    & svg {
      height: 50px;
      width: 50px;
    }
  }
  @media screen and (min-width: 1700px) {
    width: "auto";
    & svg {
      height: 70px;
      width: 70px;
    }
  }
`;

export const InfoDiv = styled.div`
  display: flex;
  margin-left: 20px;
  align-items: center;
`;

export const Hours = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-weight: 500;
  font-size: 16px;
  width: 60px;
  max-width: 70px;
  min-width: 60px;
  color: #b0c3ea;
`;

export const Text = styled.span`
  font-weight: 600;
  font-size: 16px;
`;

export const SubText = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: #b0c2ea;
`;

export const Separator = styled.div`
  width: 3px;
  height: 40px;
  background-color: ${(props) => (props.active ? "#2BCAA6" : " #b0c3ea")};
  border-radius: 15px;
  margin-left: 20px;
  margin-right: 20px;
`;

export const NoLessons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  opacity: 0.4;
`;

export const LessonsWrapperMargins = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  margin-top: 40px;
`;
