/** @format */

import { ReactComponent as Covid } from "../../img/covid.svg";
import SAButton from "../dashboard/overall/SA_Button";
import styled from "styled-components";
// import axiosConfig from "../../providers/axiosConfig";

const UpperDiv = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4;
  justify-content: center;
  background-color: rgba(255, 0, 0, 0.3);

  @media (max-width: 1250px) {
    left: 0;
  }
`;

const MiddleDiv = styled.div`
  position: absolute;
  top: 30%;
  left: 35%;
  margin-top: -50px;
  margin-left: -50px;
  width: 700px;
  height: 400px;
  background: white;
  z-index: 3;
  border-radius: 20px;
  justify-content: center;

  @media (max-width: 1250px) {
    top: 25%;
    left: 0;
    margin: 0;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    padding: 1.25rem;
  }
`;

const ContentDiv = styled.div`
  margin-top: 20px;
  text-align: center;
  justify-content: center;
`;

const Title = styled.p`
  color: red;
  font-size: 25px;
  font-weight: 500px;
`;
const Description = styled.div`
  text-align: center;
  padding-left: 160px;
  padding-right: 160px;
  @media (max-width: 1250px) {
    padding: 0%;
  }
`;
const BtnDiv = styled.div`
  justify-content: center;
  display: flex;
`;

function AlertCovidPopUp({ notification, hide }) {
  return (
    <UpperDiv>
      <MiddleDiv>
        <ContentDiv>
          {/* SVG */}
          <Covid width="200px" />

          {/* TÍTULO */}
          <Title>Teste Positivo Covid 19</Title>

          {/* DESCRIÇÃO */}
          <Description>
            <p>
              O teste à Covid 19 foi positivo, o que deverá ficar em isolamento e aguardar mais
              instruções do responsável de saúde.
            </p>
            <p>Confirmo que tomei conhecimento desta informação.</p>
          </Description>

          <BtnDiv>
            <SAButton
              textcolor="#FFFFFF"
              value="Continuar"
              onclick={() => {
                hide(false);
              }}
            />
          </BtnDiv>
        </ContentDiv>
      </MiddleDiv>
    </UpperDiv>
  );
}

export default AlertCovidPopUp;
