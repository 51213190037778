/** @format */

import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as InquiryCardLogo } from '../../img/inquiries_card.svg';
import styled from 'styled-components';

const MainCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(37, 49, 151, 0.05);
  border-radius: 20px;
  margin-bottom: 20px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  padding: 20px;
  @media screen and (min-width: 800px) {
    width: 32%;
  }
`;

const TopCardDiv = styled.div`
  display: flex;
`;

const MainInfo = styled.div`
  margin-left: 20px;
  align-items: center;
`;

const Title = styled.h1`
  color: #4566af;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 5px;
`;

const Description = styled.p`
  color: #4566af;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  margin-top: 5px;
  margin-bottom: 10px;
`;

const BottomCardDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* @media screen and (min-width: 1250px) {
    margin-left: 21%;
    margin-right: 25px;
  } */
`;

const NumQuestions = styled.p`
  margin-top: 0px;
  margin-bottom: 0px;
  color: #b0c2ea;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
`;

const AnswerButton = styled.input`
  background: #4566af;
  color: #ffffff;
  border: none;
  border-radius: 48px;
  width: 150px;
  height: 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 10px;
  @media screen and (min-width: 800px) {
    margin-bottom: 0px;
  }
`;

function InquiryCard(props) {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 899px)' });

  useEffect(() => {
    if (isTabletOrMobile) {
    }
  }, [isTabletOrMobile]);

  return (
    <MainCard id='inquiry'>
      <TopCardDiv id='inquiry'>
        <InquiryCardLogo />
        <MainInfo>
          <Title>{props.title}</Title>
          <Description>{props.description}</Description>
        </MainInfo>
      </TopCardDiv>
      <BottomCardDiv>
        <NumQuestions>{props.num_questions} perguntas</NumQuestions>
        <a href={'inquiries/' + props.id}>
          <AnswerButton
            type={'button'}
            /*className='answer_inquiry'*/ value={
              'Responder Inquérito' /*t("inquiries.answer_inquiry")*/
            }
            style={{}}
          />
        </a>
      </BottomCardDiv>
    </MainCard>
  );
}
export default InquiryCard;
