/** @format */

import React, { useState } from "react";
import styled from "styled-components";
import axiosConfig from "../../../providers/axiosConfig";
import { ReactComponent as ChatBubble } from "../../../icons/chatbubble.svg";
import SAIcon from "./Icon";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SendIcon from "@mui/icons-material/Send";
import CachedIcon from "@mui/icons-material/Cached";
import { Tooltip } from "@mui/material";

const BubbleWrap = styled.div.attrs((props) => ({
  className: props.className,
}))`
  z-index: 3;
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 80px;
  height: 80px;
  cursor: pointer;
  &.active {
    animation: rotateChat 0.2s cubic-bezier(0.4, 0.1, 0.12, 0.35) both;
  }
  &.inactive {
    animation: rotateChatReverse 0.2s cubic-bezier(0.4, 0.1, 0.12, 0.35) both;
  }
  & .bubble {
    width: 100%;
    height: 100%;
  }
  & .icon {
    width: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
const ChatContainer = styled.div`
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  background-color: #f1f1f1;
  position: fixed;
  bottom: 105px;
  right: 30px;
  width: 30%;
  max-width: 380px;
  height: calc(100vh * 0.65);
  border-radius: 30px 30px 0px 0px;
  box-shadow: 0px 4px 10px rgba(37, 49, 151, 0.05);
  @media (max-width: 1250px) {
    width: 90%;
  }
`;
const ChatHeader = styled.div`
  display: flex;
  background: linear-gradient(268.21deg, #70c1fc 14.79%, #2e8fe8 95.65%);
  border-radius: 30px 30px 0px 0px;
  height: 65px;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
  width: 100%;
  color: #fff;
  box-sizing: border-box;
  & .caption {
    display: flex;
    align-items: center;
    gap: 1.25rem;
    width: 100%;
    & svg {
      width: 30px;
    }
    & p {
      color: #fff;
      margin: 0;
    }
  }
  & .close {
    cursor: pointer;
  }
`;
const ChatBox = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  padding: 0.625rem 1.25rem;
  margin-top: auto;
  gap: 1.25rem;
`;
const Chat = styled.form`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #fff;
  border-radius: 30px;
  box-sizing: border-box;
  padding: 0 1rem;
  min-height: 36px;
  max-height: 46px;
  width: 100%;
  & button {
    outline: 0;
    border: 0;
    background: transparent;
  }
  & input {
    height: 100%;
    border: 0;
    outline: 0;
    width: 100%;
  }
  & svg {
    color: #6dbffc;
    cursor: pointer;
  }
`;

const ChatMessageContent = styled.div`
  display: flex;
  gap: 1.25rem;
  flex-direction: column;
  box-sizing: border-box;
  padding: 1.25rem 0.625rem;
  height: 100%;
  overflow-y: auto;
`;
const DefaultMessage = styled.div`
  background-color: #f6fbff;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 0.625rem;
`;
const SupportMessage = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 0.625rem;
  width: 90%;
  box-shadow: 0px 5px 15px -3px #ccdcff;
  & p {
    color: #000;
  }
`;
const SupportOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 0.625rem;
`;
const Option = styled.div.attrs((props) => ({
  id: props.id,
}))`
  background: #ffffff;
  border: 1px solid #b0b0b0;
  border-radius: 10px;
  padding: 0.25rem 0.625rem;
  color: #000;
  cursor: pointer;

  &:hover {
    background-color: #f1f1f1;
  }
`;
const UserMessage = styled.div`
  background-color: #4566af;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 0.625rem;
  width: 90%;
  align-self: flex-end;
  box-shadow: 0px 5px 15px -3px #ccdcff;
  & p {
    color: #fff;
  }
`;

function SASupport({ userData }) {
  const [open, OpenChat] = useState(false);
  // const [dashData, SetDashData] = useState([]);
  const [ticketcreated, SetTicketCreated] = useState(false);
  const [supportticket, SetSupportTicket] = useState("");
  const [answer, SetAnswer] = useState("");
  const [message, SetMessage] = useState("");
  const [messages, SetMessages] = useState([]);
  const options = {
    confusion: "Dúvidas em navegar na plataforma.",
    entrance: "Registo de entrada.",
    inquiry: "Preencher inqueritos.",
    documents: "Submeter documentos.",
  };

  function GetOption(opt, first_message) {
    if (!ticketcreated) {
      switch (opt.id) {
        case "1":
          CreateTicket("system_problem", first_message);
          SetAnswer(options.system_problem);
          break;
        case "2":
          CreateTicket("entrance", first_message);
          SetAnswer(options.entrance);
          break;
        case "3":
          CreateTicket("inquiry", first_message);
          SetAnswer(options.inquiry);
          break;
        case "4":
          CreateTicket("documents", first_message);
          SetAnswer(options.documents);
          break;

        default:
          break;
      }
    }
  }
  function CreateTicket(type, first_message) {
    let dashdata = localStorage.getItem("dashboardData");
    const { school } = JSON.parse(dashdata);
    const data = {};
    data.school_id = school.school_id;
    if (type === "system_problem") {
      data.type = type;
    } else {
      data.type = "user_problem";
    }
    data.status = "open";
    data.first_message = first_message;

    axiosConfig.post("/support_tickets", data).then((res) => {
      SetTicketCreated(true);
      SetSupportTicket(res.data.ticket.id);
    });
  }

  function CheckTicket() {
    axiosConfig
      .get("/latest_user_support_ticket")
      .then((res) => {
        SetTicketCreated(true);
        SetAnswer(options[res.data.type]);
        SetSupportTicket(res.data.id);

        axiosConfig.get("/support_tickets/" + res.data.id).then((res) => {
          SetMessages(res.data.messages);
        });
      })
      .catch((error) => {
        if (error.status === 404) {
        }
      });
  }
  function SendMessage(event) {
    event.preventDefault();
    const data = {
      message: message,
      support_ticket_id: supportticket,
      user_id: userData.user.id,
    };
    axiosConfig.post("/support_messages", data).then((res) => {
      axiosConfig.get("/support_tickets/" + supportticket).then((res) => {
        SetMessages(res.data.messages);
        SetMessage("");
      });
    });
  }
  useState(() => {
    setInterval(CheckTicket, 15000);
  }, [])
  return (
    <>
      <BubbleWrap onClick={() => OpenChat(!open)} className={`${open ? "active" : "inactive"}`}>
        <div>
          <ChatBubble className="bubble" />
          <div className="icon">
            <SAIcon icon="help" color="#1FCCE8" />
          </div>
        </div>
      </BubbleWrap>
      <ChatContainer style={open ? { display: "flex" } : { display: "none" }}>
        <ChatHeader>
          <div className="caption">
            <SAIcon icon="help" color="#fff" />
            <p>Fale connosco</p>
          </div>
          <KeyboardArrowDownIcon
            fontSize={"large"}
            className={"close"}
            onClick={() => OpenChat(false)}
          />
        </ChatHeader>
        <ChatMessageContent>
          <DefaultMessage>
            <p>
              Olá. Para mantermos o histórico desta conversa e de acordo com o Regulamento de
              Proteção de Dados, informamos que este chat será guardado.
            </p>
          </DefaultMessage>

          {ticketcreated ? (
            <></>
          ) : (
            <>
              <SupportMessage>
                <p>A sua questão relaciona-se com algum destes temas?</p>
              </SupportMessage>
              <SupportOptions>
                <Option
                  id={1}
                  onClick={(e) => GetOption(e.target, "Dúvidas em navegar na plataforma?")}
                >
                  Dúvidas em navegar na plataforma?
                </Option>
                <Option id={2} onClick={(e) => GetOption(e.target, "Registo de entrada?")}>
                  Registo de entrada?
                </Option>
                <Option id={3} onClick={(e) => GetOption(e.target, "Preencher inqueritos?")}>
                  Preencher inqueritos?
                </Option>
                <Option id={4} onClick={(e) => GetOption(e.target, "Submeter documentos?")}>
                  Submeter documentos?
                </Option>
              </SupportOptions>
            </>
          )}

          {answer ? (
            <>
              <UserMessage>
                <p>{answer}</p>
              </UserMessage>
            </>
          ) : (
            <></>
          )}
          {messages && messages.length > 0 ? (
            messages.map((message, index) => {
              if (message.user_id === userData.user.id) {
                return (
                  <UserMessage key={index}>
                    <p>{message.message}</p>
                  </UserMessage>
                );
              } else {
                return (
                  <SupportMessage key={index}>
                    <p>{message.message}</p>
                  </SupportMessage>
                );
              }
            })
          ) : (
            <></>
          )}
        </ChatMessageContent>
        <ChatBox style={ticketcreated ? { display: "flex" } : { display: "none" }}>
          <Tooltip arrow title="Criar novo ticket" placement="top-start" enterTouchDelay={0}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                SetTicketCreated(false);
                SetMessages([]);
              }}
            >
              <CachedIcon />
            </div>
          </Tooltip>
          <Chat onSubmit={SendMessage}>
            <input
              type={"text"}
              placeholder={"Escreva aqui..."}
              value={message}
              onChange={(e) => SetMessage(e.target.value)}
              required
            />
            <button type="submit">
              <SendIcon />
            </button>
          </Chat>
        </ChatBox>
      </ChatContainer>
    </>
  );
}

export default SASupport;
