import { Avatar } from "@mui/material";
import React from "react";
import styled from "styled-components";

const Main = styled.div`
  cursor: pointer;
  align-items: center;
`;

const Name = styled.p`
  color: #fff;
  font-family: Nunito;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
`;

function SelectChildInput({ userData, setStudentInfo }) {
  return (
    <Main
      onClick={() => {
        setStudentInfo(userData);
      }}
    >
      <Avatar
        alt=""
        src={userData.photo_url}
        style={{ width: "200px", height: "200px" }}
      />
      <Name>
        {userData.name.split(" ")[0] +
          " " +
          userData.name.split(" ")[userData.name.split(" ").length - 1]}
      </Name>
    </Main>
  );
}
export default SelectChildInput;
