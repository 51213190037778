import React from "react";
import DashboardNavBar from "./DashboardNavBar";
import styled from "styled-components";
import SASupport from "./overall/SA_Support";

const Main = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  min-height: 100vh;
  background-color: #f6fbff;
  padding-bottom: 2rem;
`;
const ContentWrapper = styled.div`
  width: 90%;
  height: 100%;
  margin: 0 auto;
  padding-top: 120px;
  box-sizing: border-box;
`;

function Dashboard({ children, userData }) {
  
  return (
    <div>
      <DashboardNavBar userData={userData} />
      <Main>
        <ContentWrapper>{children}</ContentWrapper>
      </Main>
      <SASupport userData={userData} />
    </div>
  );
}

export default Dashboard;
