import React from "react";
import styled from "styled-components";
import EditIcon from "@mui/icons-material/Edit";

const EditWrap = styled.div`
  position: absolute;
  right: 1.25rem;
  display: flex;
  align-items: center;
  gap: 0.625rem;
  z-index: 1;

  & span {
    color: #b0c2ea;
  }
`;
const Square = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4566af;
  padding: 0.625rem;
  box-sizing: border-box;
  border-radius: 8px;
  color: #fff;
  text-align: center;
  height: 35px;
  width: 35px;
  font-size: 1.25rem;
  cursor: pointer;
`;

function SAEditButton({ onclick, isRelative, label }) {
  return (
    <EditWrap
      style={
        isRelative
          ? { position: "relative", right: "0" }
          : { position: "absolute" }
      }
    >
      <span>{label === undefined ? "Alterar" : label}</span>
      <Square onClick={onclick}>
        <EditIcon />
      </Square>
    </EditWrap>
  );
}

export default SAEditButton;
