/** @format */

import * as React from "react";
import "./themes/css/introJsToolTip.css";
// import { Steps } from "intro.js-react";
// import { steps as dashboardSteps } from "./steps/dashboardSteps";
// import { steps as dashboardParentSteps } from "./steps/dashboardParentSteps";
// import { steps as alertListSteps } from "./steps/alertListSteps";
// import { steps as alertHighTempSteps } from "./steps/alertHighTempSteps";
// import { steps as schedulesSteps } from "./steps/schedulesSteps";
// import { steps as schedulesParentSteps } from "./steps/schedulesSteps";
// import { steps as entrancesSteps } from "./steps/entrancesSteps";
// import { steps as documentsSteps } from "./steps/documentsSteps";
// import { steps as documentsFormSteps } from "./steps/documentsFormSteps";
// import { steps as chatsSteps } from "./steps/chatsSteps";
// import { steps as inqueriyListSteps } from "./steps/inquiryListSteps";
// import { steps as inqueriyResponseSteps } from "./steps/inquiryResponseSteps";
// import { steps as newsSteps } from "./steps/newsSteps";

import { ReactComponent as TutorialIcon } from "../../img/tutorial.svg";

import styled from "styled-components";
import { useState } from "react";
// import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const TutorButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;

  & :hover {
    background: transparent;
  }
  & .MuiTouchRipple-root {
    color: transparent;
  }
`;

const Tutorial = ({ setTutorial, continuos, isParent, oneTime, userData }) => {
  // const [stepsEnabled, setStepsEnabled] = useState(false);
  const [isCountinous, setIsContinous] = useState(continuos);
  // const [isStudentParent, setIsStudentParent] = useState(isParent);
  // let singleStep = false;
  // if (oneTime !== undefined) {
  //   singleStep = true;
  // }
  // const navigate = useNavigate();
  // let initialStep = 0;
  // let steps = [];
  // let continueTutorial = false;
  // let route = sessionStorage.getItem("route");

  function startTour() {
    setIsContinous(true);
    setTutorial(true);
    // setStepsEnabled(true);
    // setIsStudentParent(isParent);
  }

  // switch (route) {
  //   case "dashboardlanding":
  //     if (isStudentParent) {
  //       steps = dashboardParentSteps;
  //     } else {
  //       steps = dashboardSteps;
  //     }
  //     break;
  //   case "alertListPage":
  //     steps = alertListSteps;
  //     break;
  //   case "alertHighTempPage":
  //     steps = alertHighTempSteps;
  //     break;
  //   case "schedules":
  //     if (isStudentParent) {
  //       steps = schedulesParentSteps;
  //     } else {
  //       steps = schedulesSteps;
  //     }
  //     break;
  //   case "entrances":
  //     steps = entrancesSteps;
  //     break;
  //   case "documentsForm":
  //     steps = documentsFormSteps;
  //     break;
  //   case "documents":
  //     steps = documentsSteps;
  //     break;
  //   case "chats":
  //     steps = chatsSteps;
  //     break;
  //   case "inquiryList":
  //     steps = inqueriyListSteps;
  //     break;
  //   // case "inquiryResponse":
  //   //   steps = inqueriyResponseSteps;
  //   //   break;
  //   // case "news":
  //   //   steps = newsSteps;
  //   //   break;

  //   default:
  //     break;
  // }

  useEffect(() => {
    if (isCountinous) {
      startTour();
    }
    // eslint-disable-next-line
  }, [isCountinous]);

  // function onComplete() {
  //   continueTutorial = true;
  //   if (isCountinous) {
  //     switch (route) {
  //       case "dashboardlanding":
  //         if (oneTime) {
  //           setTutorial(false);
  //           window.scrollTo({
  //             top: 0,
  //             behavior: "smooth",
  //           });
  //         } else {
  //           navigate("/alerts");
  //         }
  //         break;
  //       case "alertListPage":
  //         if (oneTime) {
  //           setTutorial(false);
  //           window.scrollTo({
  //             top: 0,
  //             behavior: "smooth",
  //           });
  //         } else {
  //           navigate("/alert/highTemp/teste");
  //         }
  //         break;
  //       case "alertHighTempPage":
  //         if (oneTime) {
  //           setTutorial(false);
  //           window.scrollTo({
  //             top: 0,
  //             behavior: "smooth",
  //           });
  //         } else {
  //           navigate("/schedule");
  //         }
  //         break;
  //       case "schedules":
  //         if (oneTime) {
  //           setTutorial(false);
  //           window.scrollTo({
  //             top: 0,
  //             behavior: "smooth",
  //           });
  //         } else {
  //           navigate("/entrances");
  //         }
  //         break;
  //       case "entrances":
  //         if (oneTime) {
  //           setTutorial(false);
  //           window.scrollTo({
  //             top: 0,
  //             behavior: "smooth",
  //           });
  //         } else {
  //           navigate("/documents");
  //         }
  //         break;
  //       case "documents":
  //         if (oneTime) {
  //           setTutorial(false);
  //           window.scrollTo({
  //             top: 0,
  //             behavior: "smooth",
  //           });
  //         } else {
  //           navigate("/documents/create/teste");
  //         }
  //         break;
  //       case "documentsForm":
  //         if (oneTime) {
  //           setTutorial(false);
  //           window.scrollTo({
  //             top: 0,
  //             behavior: "smooth",
  //           });
  //         } else {
  //           // navigate('/chat');
  //           navigate("/inquiries");
  //         }
  //         break;
  //       // case 'chats':
  //       //     if (oneTime) {
  //       //         setTutorial(false);
  //       //         window.scrollTo({
  //       //             top: 0,
  //       //             behavior: 'smooth',
  //       //         });
  //       //     } else {
  //       //         navigate('/inquiries');
  //       //     }
  //       //     break;
  //       case "inquiryList":
  //         if (oneTime) {
  //           setTutorial(false);
  //           window.scrollTo({
  //             top: 0,
  //             behavior: "smooth",
  //           });
  //         } else {
  //           navigate("/inquiry/response/teste");
  //         }
  //         break;
  //       case "inquiryResponse":
  //         if (oneTime) {
  //           setTutorial(false);
  //           window.scrollTo({
  //             top: 0,
  //             behavior: "smooth",
  //           });
  //         } else {
  //           navigate("/news");
  //         }
  //         break;
  //       case "news":
  //         if (oneTime) {
  //           setTutorial(false);
  //           window.scrollTo({
  //             top: 0,
  //             behavior: "smooth",
  //           });
  //         } else {
  //           navigate("/changePassword");
  //         }
  //         break;
  //       case "changePassword":
  //         if (oneTime) {
  //           setTutorial(false);
  //           window.scrollTo({
  //             top: 0,
  //             behavior: "smooth",
  //           });
  //         } else {
  //           navigate("/changePassword");
  //         }
  //         break;
  //       case "finalStep":
  //         setIsContinous(false);
  //         navigate("/");
  //         break;
  //       default:
  //         break;
  //     }
  //   }
  // }

  // function onExit() {
  //   if (!isCountinous || singleStep) {
  //     setStepsEnabled(false);
  //     setIsContinous(false);
  //     setTutorial(false);
  //     // if (route === 'alertHighTempPage') {
  //     //     navigate('/alerts');
  //     // }
  //     // if (route === 'inquiryResponse') {
  //     //     navigate('/inquiries');
  //     // }
  //     // if (route === 'documentsForm') {
  //     //     navigate('/documents');
  //     // }
  //   } else if (isCountinous && !continueTutorial) {
  //     if (userData.user.created_at === userData.user.updated_at)
  //       navigate("/changePassword");
  //     else navigate("/");
  //   }
  // }

  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <TutorButton onClick={startTour}>
          <TutorialIcon />
        </TutorButton>
      </div>

      {/* <Steps
        enabled={stepsEnabled}
        steps={steps}
        initialStep={initialStep}
        onExit={onExit}
        onComplete={onComplete}
        options={{
          nextLabel: "Seguinte",
          prevLabel: "Anterior",
          doneLabel: continuos && route !== "final" ? "Seguinte" : "Terminar",
          showBullets: false,
        }}
      /> */}
    </>
  );
};

export default Tutorial;
