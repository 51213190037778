import React from "react";
import { useTranslation } from "react-i18next";
import image1 from "../img/image1.png";
import circleCross from "../img/circleCross.svg";

function FirstRow() {
  const { t } = useTranslation();
  // const data = ["teste1", "teste2", "teste3"];
  return (
    <section>
      <div id="about" className="row">
        <div className="column">
          <h2 className="row-title">
            {t("projeto1.title")}
            <img className="circleCross" src={circleCross} alt="" />
          </h2>
          <hr />
          <p>{t("projeto1.txt1")}</p>
          <p>{t("projeto1.txt2")}</p>
        </div>
        <div className="column">
          <img className="imagerow" src={image1} alt="" />
        </div>
      </div>
    </section>
  );
}

export default FirstRow;
