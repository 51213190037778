/** @format */

import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosConfig from '../../providers/axiosConfig';
import styled from 'styled-components';
import { Alert, Snackbar } from '@mui/material';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;
const Dropbtn = styled.div`
  & .iconbutton {
    &:hover {
      background-color: transparent;
    }
    & .MuiTouchRipple-root {
      background-color: transparent;
      display: none;
    }
    & .typography {
      text-align: left;
      margin-left: 0.625rem;
      font-size: 1rem;
      & p {
        margin: 0;
        color: #4566af;
        font-weight: 700;
      }
      & span {
        color: #bcc9e7;
        font-weight: 400;
      }
    }
  }
`;

export default function UserMenu({ userData }) {
  const { user } = userData;

  const [anchorEl, setAnchorEl] = useState(null);
  const [children, setChildren] = useState([]);
  const [uploadError, setUploadError] = useState(null);
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function AccountLogout(e) {
    e.preventDefault();
    axiosConfig.post('logout').then((res) => {
      localStorage.clear('auth');
      window.location.reload();
    });
  }

  React.useEffect(() => {
    axiosConfig
      .get('me/children')
      .then((res) => {
        setChildren(res.data.children);
      })
      .catch((err) => {
        setUploadError(err);
      });
  }, []);

  return (
    <>
      {user && (
        <>
          <Snackbar
            open={uploadError}
            onClose={() => {
              setUploadError(null);
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
          >
            <Alert severity='error'>{uploadError}</Alert>
          </Snackbar>
          <Wrapper onClick={handleClick}>
            <Dropbtn>
              <IconButton
                className='iconbutton'
                size='small'
                sx={{ ml: 2 }}
                aria-controls={anchorEl ? 'account-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={anchorEl ? 'true' : undefined}
              >
                <Avatar sx={{ width: 50, height: 50 }} src={user.photo_url}></Avatar>
                <div className='typography'>
                  <p>
                    {user.name.split(' ')[0] +
                      ' ' +
                      user.name.split(' ')[user.name.split(' ').length - 1]}
                  </p>
                  <span>{user.role_name}</span>
                </div>
              </IconButton>
            </Dropbtn>
            <KeyboardArrowDownIcon fontSize={'medium'} />
          </Wrapper>
          <Menu
            className='usermenu'
            anchorEl={anchorEl}
            id='account-menu'
            open={anchorEl !== null}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={() => navigate('my_account')} className='menuitem' component='div'>
              Definições de conta
            </MenuItem>
            {userData.isParent && children.length > 1 && (
              <MenuItem
                onClick={() => {
                  localStorage.removeItem('student');
                  navigate('/select_child');
                }}
                className='menuitem'
                component='div'
              >
                Mudar educando
              </MenuItem>
            )}
            <MenuItem onClick={AccountLogout} className='menuitem' component='div'>
              Terminar Sessão
            </MenuItem>
          </Menu>
        </>
      )}
    </>
  );
}
