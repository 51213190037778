/** @format */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axiosConfig from '../../providers/axiosConfig';
import SANewsCard from '../dashboard/news/SA_NewsCard';
import SABackButton from '../dashboard/overall/SA_BackButton';
import moment from 'moment';

const GridWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 0.3fr;
    gap: 150px;
    margin-top: 2.5rem;

    @media (max-width: 1250px) {
        grid-template-columns: unset;
        display: flex;
        flex-direction: column;
    }
`;
const RowSpaced = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 150px;

    & h2 {
        font-size: 36px;
        color: #4566af;
    }
    & span {
        background-color: #b0c2ea;
        height: fit-content;
        border-radius: 8px;
        text-align: center;
        color: #fff;
        width: 20%;
        padding: 0.625rem 1.25rem;
        box-sizing: border-box;
    }
    @media (max-width: 1250px) {
        grid-template-columns: unset;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 0.625rem;

        & h2 {
            font-size: 24px;
        }

        & span {
            width: 100%;
        }
    }
`;
const Column = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.275rem;
`;
const ImageWrapper = styled.div`
    max-height: 450px;
    min-height: 350px;
    width: 100%;
    overflow: hidden;
    border-radius: 20px;
    margin: 2.5rem 0;
    & img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
    @media (max-width: 1250px) {
        min-height: auto;
    }
`;

function NewsShow({ userData }) {
    const [newsInfo, SetNewsInfo] = useState();
    const [news, SetNews] = useState([]);
    const studentId = localStorage.getItem('student');

    useEffect(() => {
        const idnews = window.location.pathname.replace('/news/', '');
        axiosConfig
            .get('me/news/' + idnews)
            .then((res) => {
                SetNewsInfo(res.data);
            })
            .then(
                axiosConfig
                    .get('me/news', {
                        params: { studentId: studentId },
                    })
                    .then((res) => {
                        SetNews(res.data);
                    })
            );
    }, [userData, studentId]);

    useEffect(() => {}, []);

    return (
        <>
            <SABackButton />
            {newsInfo && (
                <GridWrapper>
                    <div>
                        <RowSpaced>
                            <h2>{newsInfo.title}</h2>
                            <span>{moment(newsInfo.date).format('DD [de] MMMM')}</span>
                        </RowSpaced>
                        <ImageWrapper>
                            <img alt='' src={newsInfo.image} />
                        </ImageWrapper>
                        <p>{newsInfo.subtitle}</p>
                        <p>{newsInfo.description}</p>
                    </div>
                    <Column>
                        {news.slice(news.length - 3).map((news) => {
                            if (news.id !== newsInfo.id) {
                                return (
                                    <SANewsCard
                                        id={news.id}
                                        header={news.title}
                                        image={news.image}
                                        key={news.id}
                                    />
                                );
                            } else {
                                return null;
                            }
                        })}
                    </Column>
                </GridWrapper>
            )}
        </>
    );
}

export default NewsShow;
