import React from "react";
import styled from "styled-components";
import { ReactComponent as CirclePlusIcon } from "../../../icons/circle_plus2.svg";
import ThermostatIcon from "@mui/icons-material/Thermostat";

const Bar = styled.div`
  margin: 1.875rem 0;
  background: linear-gradient(267.78deg, #52cdeb 13.03%, #9eddf2 104.26%);
  height: 110px;
  box-sizing: border-box;
  padding: 1.25rem;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4.63717px 11.5929px rgba(37, 49, 151, 0.05);
  font-size: 1.25rem;
  color: #fff;
  & h3,
  h1,
  p {
    color: #fff;
  }
  & div {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
  }
  & .bg {
    position: absolute;
    left: 10%;
    fill: red;
  }

  @media (max-width: 1250px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & h3 {
      font-size: 1rem;
      text-align: center;
      margin: 0;
    }
    & div {
      margin-top: 0.625rem;
    }
    & h1 {
      font-size: 1.5rem;
      text-align: center;
      margin: 0;
    }
  }
`;

function SA_TemperatureBar({ date, temperature }) {
  let newDate = new Date(date);
  newDate = newDate.toLocaleDateString("pt-PT", {
    day: "numeric",
    month: "long",
  });
  return (
    <Bar>
      <h3>A média de temperatura no passado dia {newDate} foi de</h3>
      <div>
        <h1>{temperature}ºC</h1>
        <ThermostatIcon fontSize="3.25" />
      </div>
      <CirclePlusIcon className="bg" />
    </Bar>
  );
}

export default SA_TemperatureBar;
