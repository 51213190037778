/** @format */

import SAButton from "../dashboard/overall/SA_Button";
import axiosConfig from "../../providers/axiosConfig";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import SABackButton from "../../components/dashboard/overall/SA_BackButton";
import Uploader from "../Documents/Uploader";
import { useState } from "react";

const FileInputDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 800px;
`;

const FileInputTitle = styled.p`
  color: #5f80f7;
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
`;
// const FormUpperDiv = styled.div`
//   display: flex;
//   flex-direction: column;
//   gap: 40px;
// `;
// const FormInputDiv = styled.div`
//   background: white;
//   border-radius: 20px;
//   height: 200px;
//   width: 400px;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   display: flex;
//   box-shadow: 0px 4px 10px rgba(37, 49, 151, 0.05);
// `;
const FormBtntDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const HeaderDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-right: 650px;
  gap: 40px;
`;
const HeaderTitle = styled.p`
  color: #4566af;
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
`;

function AlertDocumentForm() {
  const [file, setFile] = useState(null);
  const location = useLocation(); //Document Report Resource
  const data = location.state; //userId:{...} ; doc_id:{...} ; doc_type_id:{...} ; alertId:{...}
  const navigate = useNavigate();
  async function HandleForm(event) {
    event.preventDefault();
    let formData = new FormData();
    formData.append("file", file);
    formData.append("document_id", data.doc_id);
    formData.append("alert_id", data.alertId);
    await axiosConfig.post("/me/document", formData).then((postRes) => {
      // Save information from Document Table
      navigate("/dashboard");
    });
  }

  return (
    <div>
      <SABackButton />
      <HeaderDiv>
        <HeaderTitle>Trocar Documento</HeaderTitle>
      </HeaderDiv>

      <FileInputDiv>
        <form onSubmit={HandleForm}>
          <FileInputDiv>
            <FileInputTitle>2. Arraste ou carregue aqui o seu documento</FileInputTitle>
            <Uploader setFile={setFile} />
          </FileInputDiv>
          <FormBtntDiv>
            <SAButton value="Submeter Documento" size="large" type={"submit"} textcolor="#FFFFFF" />
          </FormBtntDiv>
        </form>
      </FileInputDiv>
    </div>
  );
}
export default AlertDocumentForm;
