import styled from "styled-components";

export const CardWrapper = styled.div`
  background-color: #fff;
  border-radius: 20px;
  width: auto;
  height: auto;
  box-shadow: 0px 4px 10px rgba(37, 49, 151, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 431px) {
    min-width: 400px;
  }
`;

export const SchedulePillWrapper = styled.div`
  border: 2px #c14aeb solid;
  border-radius: 115px;
  min-width: 340px;
  width: "auto";
  min-height: 60px;
  max-height: 80px;
  margin-bottom: 10px;
`;

export const PillTitle = styled.span`
  color: #c14aeb;
  font-family: "Nunito";
  font-weight: 600;
  font-size: 16px;
`;

export const PillLocation = styled.span`
  color: #c14aeb;
  font-family: "Nunito";
  font-weight: 400;
  font-size: 14px;
`;

export const Separator = styled.div`
  background-color: #4566af;
  margin-left: 20px;
  margin-right: 20px;
  height: 1px;
`;
