/** @format */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axiosConfig from '../../providers/axiosConfig';
import logo from '../../img/logo.svg';
import SelectChildInput from './SelectChildInput';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

const Main = styled.div`
  background: linear-gradient(11.6deg, #b0c2ea -17.99%, #1fcce8 140.77%);
  & svg {
    width: 100%;
    height: 100%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  padding: 25px 0;

  a {
    font-size: 24px;
    text-decoration: underline;
    cursor: pointer;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  gap: 30px;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 50px;
  justify-content: center;
`;

const Title = styled.p`
  color: #fff;
  font-family: Nunito;
  font-weight: 500;
  font-size: 33.5331px;
  text-align: center;
`;

function SelectChild({ userData }) {
  const navigate = useNavigate();
  const [childData, setChildData] = useState(null);
  const [studentInfo, setStudentInfo] = useState();
  useEffect(() => {
    axiosConfig.get('/me/children').then((response) => {
      setChildData(response.data);
    });
  }, []);

  useEffect(() => {
    if (studentInfo) {
      localStorage.setItem('student', studentInfo.id);
      navigate('/dashboard');
      window.location.reload();
    }
  }, [studentInfo, navigate]);

  const logout = (e) => {
    e.preventDefault();
    axiosConfig.post('logout').then((res) => {
      localStorage.clear('auth');
      window.location.reload();
    });
  };

  if (childData && childData) {
    const children = childData.children;
    if (children.length > 1) {
      return (
        <Main>
          <Wrapper>
            <img alt='' style={{ width: '200px' }} src={logo} />
            <Title>Selecione um educando</Title>
            <InputWrapper>
              {children.map((child) => {
                return <SelectChildInput userData={child[0]} setStudentInfo={setStudentInfo} />;
              })}
            </InputWrapper>
            <a href="/#" onClick={logout}>Logout</a>
          </Wrapper>
        </Main>
      );
    } else if (children.length > 0) {
      return (
        <Main>
          <Wrapper>
            {!studentInfo ? setStudentInfo(children[0][0]) : <></>}
            <CircularProgress />
          </Wrapper>
        </Main>
      );
    } else {
      return (
        <Main>
          <Wrapper>
            <img alt='' style={{ width: '200px' }} src={logo} />
            <Title>Não tem nenhum educando registado</Title>
            <a href="/#" onClick={logout}>Logout</a>
          </Wrapper>
        </Main>
      );
    }
  }
}
export default SelectChild;
