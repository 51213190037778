import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

function NewsCard(props) {
    const { t } = useTranslation();
    const [hovering, setHovering] = useState(false);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 899px)' });

    useEffect(() => {
        if (isTabletOrMobile) {
            setHovering(true);
        }
    }, [isTabletOrMobile]);

    return (
        <div
            className='card'
            onMouseOver={() => {
                if (!isTabletOrMobile) setHovering(true);
            }}
            onMouseLeave={() => {
                if (!isTabletOrMobile) setHovering(false);
            }}
        >
            <img src={props.image} alt=""/>
            <div className='info'>
                <h3 className='title'>{props.title}</h3>
                <p className='description'>{hovering ? props.description : props.subtitle}</p>
                <h1 className='date'>{props.date}</h1>
            </div>
            <a href={'/event?id=' + props.eventNum}>
                <input type={'button'} className='know-more' value={t('events.know_more')} />
            </a>
        </div>
    );
}

export default NewsCard;
