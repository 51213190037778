import React from 'react';
import { useTranslation } from 'react-i18next';
import image3 from '../img/image3.png';
import circleCross from '../img/circleCross.svg';

function ThirdRow() {
    const { t } = useTranslation();

    return (
        <section>
            <div className='row'>
                <div className='column'>
                    <h2 className='row-title'>
                        {t('projeto3.title')}
                        <img className='circleCross' src={circleCross} alt="" />
                    </h2>
                    <hr />
                    <p>{t('projeto3.txt')}</p>
                </div>
                <div className='column'>
                    <img className='imagerow' src={image3} alt="" />
                </div>
            </div>
        </section>
    );
}

export default ThirdRow;
