/** @format */

import { Alert, Snackbar } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import SAButton from "./dashboard/overall/SA_Button";
import SAInput from "./dashboard/overall/SA_Input";
import { ReactComponent as Stars } from "../img/stars.svg";
import { ReactComponent as Logo } from "../img/logo.svg";
import { useMediaQuery } from "react-responsive";
import axiosConfig from "../providers/axiosConfig";

const Background = styled.div`
  width: 100vw;
  height: 100vh;
  background: linear-gradient(11.6deg, #b0c2ea -17.99%, #1fcce8 140.77%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;

  @media (max-width: 800px) {
    background: #fff;
  }
`;

const MainDiv = styled.div`
  background-color: #fff;
  border-radius: 30px;
  box-sizing: border-box;
  padding: 20px;
  z-index: 10;

  & form {
    margin-top: 20px;

    gap: 10px;
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 800px) {
    width: 100%;
    border-radius: 0;
  }
`;

const RecoverPassword = ({ authToken }) => {
  const [error, setError] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState(null);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 800px)" });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setError(null);
  };

  const validatePasswordRequirements = (password) => {
    if (
      password &&
      !password.match(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
      )
    ) {
      return "A senha tem de ter pelo menos 10 carateres, deve conter pelo menos uma letra maíuscula, uma minúscula, um número e um carater especial (@$!%*#?).";
    }
  };

  const validate = () => {
    let failVal = false;
    const error = validatePasswordRequirements(newPassword);
    if (error) {
      failVal = true;
      setError(error);
    } else if (newPasswordConfirmation !== newPassword) {
      failVal = true;
      setError("As passwords não são iguais");
    }
    return failVal;
  };

  const sendNewPassword = (e) => {
    e.preventDefault();
    const failed = validate();
    if (!failed) {
      axiosConfig
        .post("/recover_password", {
          new_password: newPassword,
          auth_token: authToken,
        })
        .then((res) => {
          setError({ severity: "success", message: "Palavra-passe alterada" });
          window.location.reload();
        })
        .catch((err) => setError({ severity: "error", message: err.message }));
    }
  };

  return (
    <Background>
      {!isTabletOrMobile && (
        <>
          <Stars style={{ position: "absolute", height: "100%" }} />
          <Logo style={{ width: "20%", maxWidth: "200px" }} />
        </>
      )}
      <MainDiv>
        <div>
          <h3>Recuperação de Palavra Passe</h3>
          <p>Insira a sua nova palavra passe</p>
        </div>

        <form onSubmit={sendNewPassword}>
          <SAInput
            type="password"
            id="password"
            label={"Nova Palavra-passe"}
            visibilityIcon={true}
            onchange={(e) => setNewPassword(e.target.value)}
          />

          <SAInput
            type="password"
            id="password_repeat"
            label={"Confirme a Palavra-passe"}
            visibilityIcon={true}
            onchange={(e) => setNewPasswordConfirmation(e.target.value)}
          />

          <div style={{ width: "100%", marginTop: "20px" }}>
            <SAButton
              type={"submit"}
              value={"Alterar Palavra-Passe"}
              style={["btn-login"]}
              size={"fullwidth"}
              textcolor={"#fff"}
              centered={true}
            />
          </div>
        </form>

        <Snackbar
          open={error}
          autoHideDuration={10000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleClose}
        >
          <Alert severity={"error"} sx={{ width: "100%" }}>
            {error}
          </Alert>
        </Snackbar>
      </MainDiv>
    </Background>
  );
};

export default RecoverPassword;
