import React, { useEffect } from "react";
import { useState } from "react";
import axiosConfig from "../../providers/axiosConfig";
import ProfileList from "./ProfileList";
import SABackButton from "./overall/SA_BackButton";
import { CircularProgress } from "@mui/material";

function StudentProfile({ userData }) {
  const { user } = userData;
  const studentId = localStorage.getItem("student");
  const [info, setInfo] = useState([]);

  useEffect(() => {
    axiosConfig
      .get("/me/info", {
        params: { studentId: studentId, studentProfile: true },
      })
      .then((res) => {
        setInfo(res.data);
      });
  }, [user, studentId]);

  return (
    <>
      {info && info.user ? (
        <>
          <SABackButton />
          <br />
          <ProfileList info={info} userData={userData} studentProfile />
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress style={{ marginTop: "20%" }} size={80} />
        </div>
      )}
    </>
  );
}
export default StudentProfile;
