/** @format */

// import DocumentFilter from './DocumentFilter';
import { ReactComponent as Documento } from '../../img/ver_doc.svg';
import { ReactComponent as Download } from '../../img/descarregar_doc.svg';
import DocumentType from './DocumentType';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

const MainDiv = styled.div`
  padding-top: 20px;
  padding-bottom: 15px;
  box-shadow: 0px 4px 10px rgba(37, 49, 151, 0.05);
  min-width: 550px;
  max-height: 400px;
  border-radius: 30px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
`;
const TableHeader = styled.div`
  display: flex;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 1rem;
  box-sizing: border-box;
  margin-bottom: 5px;
  border: 1px solid #5f80f7;
  background: #5f80f7;
  border-radius: 30px;
  font-family: Nunito;
  font-style: normal;
  font-weight: 700;
  max-height: 50px;
  color: #4566af;
  font-size: 12px;
`;
const NameHeader = styled.p`
  color: #ffffff;
  font-size: 18px;
  width: 50%;
`;
const DateHeader = styled.p`
  color: #ffffff;
  font-size: 18px;
  width: 50%;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: 50px;
  cursor: pointer;
  margin: 0 1rem;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 5px;
  font-family: Nunito;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  border-radius: 30px;
  border: 1px solid #b0c2ea;

  div:nth-child(1) {
    width: 57%;
  }

  div:nth-child(2) {
    width: 50%;
  }
`;

const TypeDiv = styled.div`
  color: #4566af;
  display: inline-flex;
  align-items: flex-start;
  gap: 20px;
`;
const Date = styled.div``;
const BtnDiv = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 20px;
`;

function DocumentTable(props) {
  const documentData = props.documentDatas;
  const navigate = useNavigate();
  return (
    <MainDiv key={documentData}>
      {documentData && documentData.length > 0 ? (
        <>
          {/* <DocumentFilter /> */}

          <TableHeader>
            <NameHeader>Nome do ficheiro</NameHeader>
            <DateHeader>Data de submissão</DateHeader>
          </TableHeader>
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            fontWeight: '700',
          }}
        >
          <p>Não existem documentos submetidos</p>
        </div>
      )}

      {/* Table Content */}
      <div
        style={{
          borderShadow: '0px 4px 10px rgba(37, 49, 151, 0.05)',
          overflowY: documentData && documentData.length > 0 ? 'scroll' : 'unset',
        }}
      >
        {documentData &&
          documentData.map((data, index) => {
            return (
              <Row
                key={index}
                onClick={() => {
                  navigate(`/document_show/${data.id}`);
                }}
              >
                <TypeDiv>
                  <DocumentType
                    hasName={true}
                    doc_type_id={data.document_type_id}
                    doc_type_name={data.document_type.name}
                  />
                </TypeDiv>

                <Date>{moment(data.created_at).format('YYYY-MM-DD')}</Date>

                <div>
                  <BtnDiv>
                    <div>
                      <Documento width='20px' />
                    </div>
                    <div
                      onClick={() => {
                        window.open(data.file_url);
                      }}
                    >
                      <Download width='20px' />
                    </div>
                  </BtnDiv>
                </div>
              </Row>
            );
          })}
      </div>
    </MainDiv>
  );
}

export default DocumentTable;
