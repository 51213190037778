
import React from "react";
import styled from "styled-components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const BackButtonWrapper = styled.div`
  display: flex;
  gap: 0.625rem;
  color: #b0c2ea;
  align-items: center;
`;
const BackButton = styled.a`
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 3.26531px 8.16326px rgba(37, 49, 151, 0.05);
  border-radius: 8px;
  color: #4566af;
  padding: 0.625rem;
  cursor: pointer;
`;
function SABackButton({onclick }) {
  const navigate = useNavigate()
  return (
    <BackButtonWrapper>
      <BackButton onClick={()=>{onclick || navigate(-1)}}>
        <ArrowBackIcon />
      </BackButton>
      <span>Voltar</span>
    </BackButtonWrapper>
  );
}

export default SABackButton;
