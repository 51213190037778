import React from "react";
import { useTranslation } from "react-i18next";
import circleCross from "../img/circleCross.svg";
import mobileversions from "../img/mobileversions.png";
import applestore from "../img/applestore.png";
import googlestore from "../img/googlestore.png";

// import { ReactComponent as BgWave } from "../img/blue_wave.svg";

function MobileApp() {
  const { t } = useTranslation();

  return (
    <section id="mobileapp">
      <div className="row section-header">
        <div
          className="column"
          style={{
            maxWidth: "100%",
            flexGrow: 1,
            textAlign: "center",
          }}
        >
          <img src={circleCross} alt="" />
          <h2 style={{ marginTop: "10px" }} className="row-title-center">
            {t("mobileapp.title")}
          </h2>
          <p>{t("mobileapp.txt")}</p>
        </div>
      </div>
      <div
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <img className="mobileapp-image" src={mobileversions} alt="" />
        <p style={{ fontSize: 20, marginTop: "40px" }}>{t("mobileapp.txt2")}</p>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "center",
            padding: 0,
            alignItems: "center",
          }}
        >
          <img className="mobileapp-stores-image" src={applestore} alt="" />
          <img className="mobileapp-stores-image" src={googlestore} alt="" />
        </div>
        <p style={{ fontSize: 20, marginTop: "20px" }}>{t("mobileapp.txt3")}</p>
      </div>
    </section>
  );
}

export default MobileApp;
