/** @format */

import React from "react";
import styled from "styled-components";

const Button = styled.button.attrs((props) => ({
  className: props.className,
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 3.125rem;
  max-height: 80px;
  min-height: 46px;
  min-width: 64px;
  font-size: 1.125rem;
  color: ${({ theme }) => theme.textcolor};
  box-sizing: border-box;
  padding: 0 1rem;
  outline: 0;
  border: 0;
  cursor: pointer;
  &.small {
    width: 130px;
  }
  &.medium {
    width: 200px;
  }
  &.large {
    width: 280px;
  }
  &.adaptive {
    width: auto;
  }
  &.fullwidth {
    width: 100%;
  }
  &.btn-primary {
    background-color: #4566af;
  }
  &.btn-secondary {
    background-color: #ffffff;
  }
  &.btn-alternative {
    background-color: #4566af;
  }
  &.btn-login {
    background-color: #57ceec;
  }
  &.centered {
    margin: 0 auto;
    align-self: center;
  }
  &.right {
    margin-left: auto;
    align-self: flex-end;
  }
  &.left {
    margin-right: auto;
    align-self: flex-start;
  }
`;
const STYLES = ["btn-primary", "btn-secondary", "btn-alternative", "btn-login"];
const SIZES = ["small", "medium", "large", "adaptive", "fullwidth"];
const ALIGNS = ["", "centered", "right", "left"];

function SA_Button({
  style,
  value,
  onclick,
  size,
  endIcon,
  type,
  textcolor,
  align,
  bgColor,
  disable,
}) {
  const CheckButtonStyle = style
    ? STYLES.includes(style[0])
      ? style
      : STYLES[0]
    : STYLES[0];
  const CheckButtonSize = SIZES.includes(size) ? size : SIZES[0];
  const CheckButtonAlign = ALIGNS.includes(align) ? align : ALIGNS[0];
  const theme = {
    textcolor: textcolor,
  };
  return (
    <Button
      disabled={disable}
      style={bgColor && { backgroundColor: bgColor }}
      theme={theme}
      onClick={onclick}
      className={`${CheckButtonStyle} ${CheckButtonSize} ${CheckButtonAlign}`}
      type={type}
    >
      <span>{value}</span>
      {endIcon ?? null}
    </Button>
  );
}

export default SA_Button;
