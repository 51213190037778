/** @format */

import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as Vacina } from "../../img/vac_cinza.svg";
import { ReactComponent as Teste } from "../../img/cert_teste_cinza.svg";
import { ReactComponent as Recuperacao } from "../../img/cert_recuperacao_cinza.svg";
import { ReactComponent as Atestado } from "../../img/atestado_cinz.svg";

const ContentAlertsDiv = styled.div`
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 4px 10px rgba(37, 49, 151, 0.05);
  min-width: 500px;
`;
const ContentBoxAlertsDiv = styled.div`
  overflow-y: auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const AlertDiv = styled.div`
  border-radius: 30px;
  border: 2px solid #ff9075;
  margin-bottom: 5px;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
`;
const Div = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 250px;
  max-width: 250px;
`;
const Name = styled.span`
  color: #ff9075;
`;

function DocumentAlerts(props) {
  const navigate = useNavigate();
  const information = props.info;

  return (
    <ContentAlertsDiv>
      <p>Alerta Documentos</p>

      <ContentBoxAlertsDiv>
        {information &&
          information.map((data, index) => {
            let doc_type_id = data.docTypeID;
            return (
              <AlertDiv
                key={index}
                style={props.user.role_id === 6 ? { cursor: "pointer" } : {}}
                onClick={() => {
                  if (props.user.role_id === 6) {
                    navigate(`/alerts/showInvalidDocument/${data.alertId}`, {
                      state: {
                        doc_reports: { document_id: data.docID },
                      },
                    });
                  }
                }}
              >
                <Div>
                  {doc_type_id === 1 ? (
                    <Vacina fill={"#ff9075"} width={"20px"} height="40px" />
                  ) : doc_type_id === 2 ? (
                    <Teste fill={"#ff9075"} width={"20px"} height="40px" />
                  ) : doc_type_id === 3 ? (
                    <Atestado fill={"#ff9075"} width={"20px"} height="40px" />
                  ) : doc_type_id === 4 ? (
                    <Recuperacao fill={"#ff9075"} width={"20px"} height="40px" />
                  ) : (
                    <></>
                  )}

                  <Name>
                    {doc_type_id === 1
                      ? "Certificado de Vacinação inválido"
                      : doc_type_id === 2
                      ? "Certificado de teste inválido"
                      : doc_type_id === 3
                      ? "Atestado médico inválido"
                      : "Certificado de Recuperação inválido"}
                  </Name>
                </Div>
              </AlertDiv>
            );
          })}
      </ContentBoxAlertsDiv>
    </ContentAlertsDiv>
  );
}
export default DocumentAlerts;
