import { useMediaQuery } from "react-responsive";
import SAIcon from "../dashboard/overall/Icon";

function AlertTypes(props) {
  let alert_type_id = props.data;
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1250px)" });

  if (alert_type_id === 1) {
    return <SAIcon icon='covid' color={'#FF4A4A'} size={isTabletOrMobile ? 30 : 80} />;
  } else if (alert_type_id === 2) {
    return <SAIcon icon='fever' color={props.color || '#FF9075'} size={isTabletOrMobile ? 30 : 80} />;
  } else if (alert_type_id === 3) {
    return <SAIcon icon='entry' color={props.color} size={isTabletOrMobile ? 30 : 80} />;
  } else if (alert_type_id === 4) {
    return <SAIcon icon='injure' color={'#FF9075'} size={isTabletOrMobile ? 30 : 80} />;
  }  else if (alert_type_id === 5) {
    return <SAIcon icon='injure' color={'#FF9075'} size={isTabletOrMobile ? 30 : 80} />;
  } else if (alert_type_id === 6) {
    return <SAIcon icon='documents' color={'#FF9075'} size={isTabletOrMobile ? 30 : 80} />;
  }
}

export default AlertTypes;
