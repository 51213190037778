/** @format */

import { useEffect, useState } from "react";
import DocumentTypes from "./DocumentTypes";
import { useNavigate } from "react-router-dom";
import DocumentTable from "./DocumentTable";
import axiosConfig from "../../providers/axiosConfig";
import SAPageTopBar from "../dashboard/overall/SA_Pagetopbar";
import styled from "styled-components";
import { ReactComponent as Mais } from "../../img/close.svg";
import Tutorial from "../Tutorial/tutorial";
import { CircularProgress } from "@mui/material";

const DocTypeAndAlerts = styled.div`
  display: flex;
  justify-content: center;
`;

function Documents(props) {
  const userInfo = props.userData;
  const studentId = localStorage.getItem("student");
  const navigate = useNavigate();

  const [documents, setDocuments] = useState([]);
  const [tutorial, setTutorial] = useState(false);
  const [docFilter, setDocFilter] = useState("");
  sessionStorage.setItem("route", "documents");

  useEffect(() => {
    if (!window.localStorage.getItem("auth")) {
      return navigate("/");
    } else {
      axiosConfig
        .get(`/users_documents/${userInfo.user.id}`, {
          params: {
            studentId: studentId,
          },
        })
        .then((res) => {
          setDocuments(res.data);
        });
    }
  }, [navigate, userInfo, studentId]);

  return (
    <>
      {userInfo && userInfo.user && userInfo.user.id && (
        <div style={{ maxWidth: "1700px", margin: "0 auto" }}>
          {userInfo.user.created_at === userInfo.user.updated_at ? (
            <div>
              <Tutorial
                userData={props.userData}
                setTutorial={setTutorial}
                continuos={true}
              />
            </div>
          ) : (
            <div>
              <Tutorial
                userData={props.userData}
                setTutorial={setTutorial}
                continuos={false}
                oneTime={true}
              />
            </div>
          )}

          <SAPageTopBar
            type={"documento"}
            backbutton={false}
            title={"Documentos"}
            path={`/document_create/${userInfo.user.id}`}
            button={
              userInfo.isParent && (
                <div
                  style={{
                    cursor: "pointer",
                    background: "#FFFFFF",
                    height: "60px",
                    width: "200px",
                    borderRadius: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    navigate(`/document_create/${userInfo.user.id}`);
                  }}
                >
                  <span style={{ color: "#6482F9", paddingRight: "5px" }}>
                    Adicionar Documento
                  </span>
                  <Mais width="20px" />
                </div>
              )
            }
          />

          {/* TIPO DE DOCUMENTOS + ALERTA DOCUMENTOS */}
          <DocTypeAndAlerts>
            {userInfo && (
              <DocumentTypes
                user={userInfo}
                setDocFilter={setDocFilter}
                docFilter={docFilter}
              />
            )}
            {/* DOCUMENT TYPES */}
          </DocTypeAndAlerts>

          {/* Table */}
          {!tutorial &&
            (userInfo && documents && documents.documents ? (
              <DocumentTable
                documentDatas={documents.documents.filter((elem) => {
                  if (docFilter.length === 0) return elem;
                  if (docFilter.includes(elem.document_type_id.toString()))
                    return elem;
                  return null;
                })}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress style={{ marginTop: "5%" }} size={80} />
              </div>
            ))}
          {tutorial && (
            <DocumentTable
              documentDatas={[
                {
                  document_type_id: 1,
                  document_type: { name: "Certificado de Vacinação" },
                  created_at: "2022-09-13T13:46:49.000000Z",
                  id: 1,
                },
              ]}
            />
          )}
        </div>
      )}
    </>
  );
}

export default Documents;
