import React from 'react';
import { useTranslation } from 'react-i18next';
import circleCross from '../img/circleCross.svg';
import iti from '../img/iti.png';
import arditi from '../img/arditi.png';
import uma from '../img/uma.png';
import { ReactComponent as BgWave} from '../img/blue_wave.svg';

function Partners() {
    const { t } = useTranslation();

    return (
        <section id='partners'>
            <div className='row section-header'>
                <div
                    className='column'
                    style={{ maxWidth: '100%', flexGrow: 1, textAlign: 'center' }}
                >
                    <img src={circleCross} alt=""/>
                    <h2 className='row-title-center'>{t('parceiros.title')}</h2>
                    <p>{t('parceiros.txt')}</p>
                </div>
            </div>
            <div className='row' style={{ textAlign: 'center' }}>
                <div className='column flex'>
                    <img className='parceiro-image' src={uma} alt="" />
                    <p>{t('parceiros.uma')}</p>
                </div>
                <div className='column flex'>
                    <img className='parceiro-image' src={arditi} alt=""/>
                    <p>{t('parceiros.arditi')}</p>
                </div>
                <div className='column flex'>
                    <img className='parceiro-image' src={iti} alt=""/>
                    <p>{t('parceiros.iti')}</p>
                </div>
                
            </div>
            <div className='bg-wave'>
                    <BgWave preserveAspectRatio='none' />
            </div>
        </section>
    );
}

export default Partners;
