/** @format */

import { useState, useEffect } from "react";
import AlertTable from "./AlertTable";
import { useNavigate } from "react-router-dom";
import axiosConfig from "../../providers/axiosConfig";
import SAPageTopBar from "../dashboard/overall/SA_Pagetopbar";
import styled from "styled-components";
import Tutorial from "../Tutorial/tutorial";

const HeaderDiv = styled.div`
  color: red;
  @media screen and (min-width: 800px) {
  }
`;
function Alerts(props) {
  const userInfo = props.userData;
  const studentId = localStorage.getItem("student");
  sessionStorage.setItem("route", "alertListPage");
  const [alerts, setAlerts] = useState([]);
  const [tutorial, setTutorial] = useState(false);

  const testData = [
    {
      id: 1,
      document_reports: null,
      alert_type_id: 1,
      description: "descrição",
      alert_type: { name: "Teste de Covid Positivo" },
      temperature: 41.3,
      created_at: "2022-09-13T13:46:49.000000Z",
    },
    {
      id: 2,
      document_reports: null,
      alert_type_id: 2,
      description: "descrição",
      alert_type: { name: "Temperatura Alta Registada" },
      temperature: 41.3,
      created_at: "2022-09-10T18:06:49.000000Z",
    },
    {
      id: 3,
      document_reports: null,
      alert_type_id: 4,
      description: "descrição",
      alert_type: { name: "Documento Inválido" },
      temperature: 41.3,
      created_at: "2022-09-9T09:46:49.000000Z",
    },
    {
      id: 4,
      document_reports: null,
      alert_type_id: 5,
      description: "descrição",
      alert_type: { name: "Acidente Escolar" },
      temperature: 41.3,
      created_at: "2022-08-28T08:30:49.000000Z",
    },
  ];

  const navigate = useNavigate();
  useEffect(() => {
    if (!window.localStorage.getItem("auth")) {
      return navigate("/");
    } else {
      axiosConfig
        .get(`/users_alerts/${userInfo.user.id}`, {
          params: {
            studentId: studentId,
          },
        })
        .then((res) => {
          setAlerts(res.data);
        });
    }
  }, [navigate, userInfo, studentId]);

  return (
    <>
      <HeaderDiv>
        {userInfo.user.created_at === userInfo.user.updated_at ? (
          <div>
            <Tutorial setTutorial={setTutorial} userData={props.userData} continuos={true} />
          </div>
        ) : (
          <div>
            <Tutorial
              userData={props.userData}
              setTutorial={setTutorial}
              continuos={false}
              oneTime={true}
            />
          </div>
        )}

        <SAPageTopBar type={"alerta"} backbutton={false} title={"Alertas"} />

        {/* FILTERS */}

        {/* <AlertBtnFilter /> */}

        {!tutorial && userInfo && alerts && <AlertTable alertData={alerts.alerts} />}

        {tutorial && <AlertTable alertData={testData} />}
      </HeaderDiv>
    </>
  );
}

export default Alerts;
